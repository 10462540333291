export default {
    "fr-FR": {
        "translation": {
            "navbar_login_label": "Connexion",
            "module_video_immersive_button_label": "Regarder le film",
            "navbar_search_placeholder": "Rechercher dans le catalogue",
            "cart_empty_text": "Votre panier est vide",
            "cart_footer_items_gocart_label": "Aller à mon panier",
            "cart_footer_items_mylist_label": "Ma liste",
            "cart_footer_items_myorders_label": "Mes commandes",
            "fields_city_label": "Ville",
            "fields_country_label": "Pays",
            "fields_email_label": "Email",
            "fields_firstname_label": "Prénom",
            "fields_lastname_label": "Nom",
            "fields_message_label": "Message",
            "fields_password_label": "Mot de passe",
            "fields_phone_label": "Téléphone",
            "fields_recipient_label": "Destinataire",
            "fields_subject_label": "Sujet",
            "fields_zipcode_label": "Code postal",
            "fields_address1_label": "Adresse",
            "fields_address2_label": "Complément d'adresse",
            "fields_city_placeholder": "Ex: Paris",
            "fields_country_placeholder": "Ex: France",
            "fields_email_placeholder": "Ex: moi@monemail.com",
            "fields_firstname_placeholder": "Ex: Marie",
            "fields_lastname_placeholder": "Ex: Dupont",
            "fields_message_placeholder": "Votre message ici",
            "fields_password_placeholder": "Votre mot de passe personnel",
            "fields_phone_placeholder": "Ex: 0102030405",
            "fields_recipient_placeholder": "Destinataire",
            "fields_subject_placeholder": "Votre sujet ici",
            "fields_zipcode_placeholder": "Ex: 75010",
            "locale_fr_fr_label": "France (Français)",
            "locale_it_it_label": "Italie (Italien)",
            "label_quantity_shortened": "Qté",
            "locale_fr_fr_product_language": "Français",
            "locale_de_de_product_language": "Allemand",
            "cart_go_checkout": "Aller au panier ({{nbItems}})",
            "cart_other_item": "Et 1 autre article...",
            "cart_other_item_plural": "Et {{count}} autres articles...",
            "constraints_password": "Le mot de passe doit avoir au moins 8 caractères",
            "constraints_email": "L'email doit être au format mon@email.com",
            "catalog_no_result_title": "Aucun résultat",
            "catalog_no_result_content": "Malheureusement, aucun résultat ne correspond à votre recherche.",
            "catalog_results_for": "résultat(s) pour",
            "catalog_menu_title": "Filtrer",
            "product_owned": "Déjà acheté",
            "panels_login_title": "Se connecter",
            "panels_login_description": "Nous sommes heureux de vous revoir !",
            "panels_forgotpassword_title": "Mot de passe oublié ?",
            "panels_forgotpassword_description": "Entrez votre mail pour réinitialiser votre mot de passe.",
            "forms_login_buttons_forgotpassword_label": "Mot de passe oublié ?",
            "forms_login_buttons_submit_label": "Me connecter",
            "forms_login_buttons_cancel_label": "Continuer en tant qu'invité",
            "forms_login_buttons_register_label": "M'inscrire",
            "forms_login_buttons_register_text": "Pas de compte ?",
            "buttons_register_label": "M'inscrire",
            "forms_register_buttons_submit_label": "M'inscrire",
            "forms_register_buttons_alreadyregistered_text": "Déjà inscrit ?",
            "forms_register_buttons_alreadyregistered_label": "Me connecter",
            "panels_register_title": "M’inscrire",
            "panels_register_description": "Bienvenue dans la communauté Lunii !",
            "catalog_reinitialize": "Réinitialiser",
            "catalog_finish": "Terminer",
            "forms_forgotpassword_buttons_submit_label": "Réinitialiser",
            "forms_forgotpassword_buttons_cancel_label": "Revenir à la connexion",
            "catalog_title": "Luniistore",
            "login_panel_title": "Se connecter",
            "login_panel_description": "Nous sommes heureux de vous revoir !",
            "checkout_cart_gift_balance": "Votre solde carte cadeaux",
            "checkout_cart_gift_will_spend": "Vous allez dépenser",
            "checkout_cart_gift_will_stay": "Il vous restera",
            "checkout_cart_header_nb_items": "ARTICLE(S)",
            "checkout_cart_header_no_discount": "Réductions : 0,00 €",
            "checkout_cart_header_with_discount": "Réduction : Vous économisez",
            "checkout_cart_header_ex_vat": "Montant hors taxe : <1>{{amount}}</1>",
            "checkout_cart_header_vat": "TVA : <1>{{amount}}</1>",
            "checkout_cart_header_title": "Votre commande",
            "checkout_cart_title": "Panier",
            "checkout_payment_title": "Livraison & Paiement",
            "checkout_login_title": "Connexion",
            "checkout_login_form_title": "J'ai déjà un compte Lunii",
            "checkout_shipping_and_purchase_title": "Livraison et paiement",
            "checkout_shipping_and_purchase_pay": "Payer ({{price}})",
            "checkout_cart_total_free_shipping": "Offerte",
            "checkout_cart_total_sub_total": "Sous-total",
            "checkout_cart_total_shipping_fee": "Livraison sous {{description}}",
            "checkout_cart_total_total_price": "Montant total",
            "checkout_cart_total_discount": "Réduction",
            "checkout_shipping_and_purchase_withdrawal": "J’accepte que mon droit de rétractation légal prenne fin dans 14 jours ou dès que je commence le téléchargement du premier titre de cette commande sur Ma Fabrique à Histoires ou FLAM, selon ce qui se produit en premier.",
            "checkout_shipping_and_purchase_cgv": "J’ai lu et j’accepte <1> les conditions générales de vente. </1>",
            "extract_card_info": "Extrait n°",
            "product_add_to_cart": "Ajouter au panier",
            "locale_nl_be_product_language": "Flamand",
            "locale_en_us_product_language": "US (Anglais)",
            "locale_en_gb_product_language": "UK (Anglais)",
            "locale_it_it_product_language": "Italien",
            "locale_es_es_product_language": "Espagnol",
            "fields_promocode_label": "Votre code de réduction",
            "button_label_next": "Suivant",
            "fields_promocode_placeholder": "Votre code de réduction",
            "product_language_title": "Langue",
            "product_duration_title": "Durée",
            "product_age_title": "Âge",
            "product_type_title": "Type de livre audio",
            "product_author": "Auteur",
            "product_teller": "Conteur",
            "locale_ru_ru_product_language": "Russe",
            "product_recommendations_title": "Découvrez aussi...",
            "product_recommendations_subtitle": "Si ce livre audio vous plaît, nous avons sélectionné des histoires similaires qui pourraient vous intéresser.",
            "product_reduction_content": "Économisez",
            "newsletter_button_label_register": "Je m'inscris",
            "promo_code_form_add_promo_code": "Ajouter un code de réduction",
            "fields_yourfirstname_label": "Votre prénom",
            "fields_newsletteroptin_label": "Votre adresse email",
            "credit_card_form_reassurance_title": "Nous utilisons le service de paiement sécurisé Stripe",
            "credit_card_form_reassurance_body": "Lorsque vous communiquez vos informations de paiement, vos données sont protégées par la technologie Secure Socket Layer (SSL), authentifiée par certificat numérique. <br />\n<1>En savoir plus</1>",
            "form_address_use_delivery_address": "Utiliser l’adresse de livraison",
            "newsletter_subtitle": "Je m'inscris à la newsletter pour être au courant des prochaines promotions, des nouveaux livres audio et des évènements de Lunii.",
            "newsletter_privacy_policy_text": "Nous nous engageons à ne jamais communiquer votre email à des tiers.",
            "newsletter_privacy_policy_link_label": "Consultez notre politique de confidentialité",
            "product_price_free": "Offert",
            "locale_fr_be_label": "Belgique (Français)",
            "locale_fr_ch_label": "Suisse (Français)",
            "locale_fr_us_label": "USA (Français)",
            "locale_es_us_label": "USA (Espagnol)",
            "locale_fr_mc_label": "Monaco (Français)",
            "locale_fr_lu_label": "Luxembourg (Français)",
            "locale_es_es_label": "Espagne (Espagnol)",
            "locale_de_de_label": "Allemagne (Allemand)",
            "locale_en_gb_label": "UK (Anglais)",
            "locale_en_us_label": "USA (Anglais)",
            "fields_giftcard_label": "N° de la carte cadeau",
            "checkout_shippingAddress_form_title": "Votre adresse de livraison",
            "checkout_paymentMethod_form_title": "Mode de paiement",
            "checkout_billingAddress_form_title": "Votre adresse de facturation",
            "checkout_creditCard_form_title": "Carte bancaire",
            "form_address_edit": "Modifier",
            "form_address_update_address": "Confirmer la sélection",
            "form_address_cancel_edit": "Annuler",
            "productbar_buy_button_label": "Acheter",
            "productbar_textline2": "Livraison en 3 à 5 jours",
            "country_fr_label": "France",
            "forms_forgotpassword_verify_inbox_text": "Si un compte Lunii existe déjà à cette adresse mail, vous allez y recevoir un mail avec des instructions pour réinitialiser votre mot de passe.\n\nPensez à vérifier le courrier indésirable de votre boîte mail !",
            "checkout_confirmation_next_steps": "Voici quelques conseils",
            "checkout_confirmation_shipping_title": "Livraison prévue d'ici 3 à 5 jours ouvrés",
            "checkout_confirmation_stories_title": "Découvrez notre catalogue d’histoires audio",
            "checkout_confirmation_stories_text": "Retrouvez des centaines d’histoires pour développer l’imaginaire, la culture et le vocabulaire des enfants à écouter dans Ma Fabrique à Histoires.",
            "checkout_confirmation_download_title": "Téléchargez le Luniistore pour transférer vos livres audio dans Ma Fabrique à Histoires",
            "checkout_confirmation_download_text": "Le Luniistore est une application ordinateur qui permet d’ajouter des livres audio dans Ma Fabrique à Histoires ou de les en retirer.",
            "checkout_confirmation_download_btn": "Télécharger le Luniistore",
            "checkout_confirmation_sync_title": "Retrouvez vos livres audio dans votre Bibliothèque",
            "checkout_confirmation_sync_text": "Les livres audio que vous venez d’acheter sont désormais accessibles dans votre Bibliothèque sur le Luniistore.",
            "checkout_confirmation_title": "Merci pour votre achat !",
            "checkout_confirmation_description": "Nous vous souhaitons de beaux moments d’écoute !",
            "checkout_cart_return": "Retour à l'accueil",
            "cart_payment_loading": "Paiement en cours",
            "checkout_confirmation_shipping_text": "Surveillez votre boîte mail pour être tenu informé de l’expédition de votre colis.",
            "forms_register_newsletter_label": "Je m’inscris à la newsletter",
            "forms_register_cgu_label": "J’ai pris connaissance et accepte les",
            "my_account_title": "Mon compte",
            "my_account_user_information_title": "Mes informations",
            "my_account_checkout_and_purchase_title": "Paiement et factures",
            "my_account_user_information_subtitle": "Prénom, nom, e-mail, mot de passe.",
            "my_account_checkout_and_purchase_subtitle": "Moyens de paiement, adresse de facturation, historique de vos achats, adresse de livraison.",
            "my_account_connected_as": "Connecté(e) en tant que",
            "my_account_logout": "Se déconnecter",
            "my_invoice_address_title": "Adresse de facturation",
            "my_delivery_address_title": "Adresse de livraison",
            "my_purchase_history_title": "Historique d’achats",
            "form_user_information_address_title": "Adresse e-mail",
            "form_user_information_address_note": "Contactez le support clientèle pour modifier l’adresse e-mail de votre compte Lunii.",
            "form_user_information_password_edit": "Modifier votre mot de passe",
            "checkout_shippingMethods_form_title": "Mode d'expédition",
            "forms_register_locale_selection_text": "Sélectionnez vos options de langue et de pays",
            "errors_login_bad_credentials": "Merci de vérifier votre email et votre mot de passe, vos identifiants semblent incorrects.",
            "errors_unauthorized": "Vous n'êtes pas autorisé à vous connecter actuellement avec ce compte, merci de réessayer et de contacter le service client si le problème persiste.",
            "errors_server_error": "Nous rencontrons actuellement un problème technique et vous demandons de bien vouloir réessayer ultérieurement. Si le problème persiste, merci de contacter le service client.",
            "form_user_information_update": "Enregistrer",
            "form_user_information_cancel_edit": "Annuler",
            "form_user_information_edit": "Modifier",
            "catalog_menu_types": "Types de livres audio",
            "catalog_menu_themes": "Thèmes",
            "catalog_menu_ages": "Âge",
            "catalog_menu_langs": "Langues étrangères",
            "fields_cardowner_label": "Titulaire de la carte",
            "fields_cardowner_placeholder": "Titulaire de la carte",
            "cartmodal_success_message": "Hop, c’est ajouté !",
            "cartmodal_go_cart_button_label": "Aller au panier",
            "cartmodal_buy_button_label": "Ajouter au panier",
            "cartmodal_continue_button_label": "Poursuivre ma navigation",
            "cartmodal_cancel_button_label": "Annuler",
            "cartmodal_linkedProducts_title": "Découvrez aussi...",
            "cartmodal_title": "Ajouter ce produit",
            "product_pages_number_title": "Pages",
            "product_format_title": "Format",
            "product_illustrator": "Illustrateur",
            "checkout_confirmation_catalog_btn": "Découvrir le catalogue",
            "checkout_confirmation_free_album_title": "Récupérez votre livre audio offert sur le Luniistore",
            "checkout_confirmation_free_album_text": "Créez votre compte sur le Luniistore pour télécharger votre livre audio offert puis transférez-le directement en connectant Ma Fabrique à Histoires.",
            "cart_empty_subtitle": "Quelle tristesse !",
            "panels_forgotpassword_success_title": "Vérifiez votre boîte mail.",
            "forms_register_country_selection_text": "Sélectionnez vos options de langue et de pays",
            "forms_register_data_label": "Je consens au traitement de mes données personnelles",
            "forms_register_verify_inbox_text": "Nous vous avons envoyé un mail de validation à l’adresse que vous avez renseignée. Vous devez d’abord valider la création de votre compte avant de poursuivre.",
            "forms_register_alert_inbox_text": "Si vous n’avez rien reçu, sachez que le mail peut mettre quelques minutes à arriver. Pensez à vérifier votre dossier spam.\nSi vous n’avez rien reçu après plusieurs minutes, essayez de vous inscrire en faisant attention à l’orthographe de votre mail.",
            "panels_register_success_title": "Vérifiez votre boîte mail.",
            "form_register_cgu_link": "conditions de service",
            "errors_register_error_email": "Une erreur est survenue pendant la création du compte. Si vous avez déjà créé un compte avec cette adresse, pensez à l’activer en cliquant sur le lien dans l’email d'activation.",
            "errors_forgot_password_error_email": "Une erreur est survenue pendant la réinitialisation du mot de passe. Si vous avez déjà créé un compte avec cette adresse, pensez à l’activer en cliquant sur le lien dans l’email de confirmation.",
            "form_address_search_address": "Valider mon adresse",
            "form_address_search_address_select": "Séléctionnez votre adresse dans la liste ci-dessous",
            "form_address_search_address_not_found": "Mon adresse ne figure pas dans la liste",
            "form_address_search_address_force": "Je certifie que je peux recevoir un colis à cette adresse",
            "country_de_label": "Allemagne",
            "country_be_label": "Belgique",
            "country_lu_label": "Luxembourg",
            "country_mc_label": "Monaco",
            "country_ch_label": "Suisse",
            "country_gb_label": "Royaume-Uni",
            "country_it_label": "Italie",
            "country_ca_label": "Canada",
            "country_us_label": "États-Unis",
            "locale_es_mx_product_language": "Espagnol (Mexique)",
            "locale_en_ca_label": "Canada (Anglais)",
            "locale_fr_ca_label": "Canada (Français)",
            "form_address_search_address_force_billing": "Je certifie que mon adresse est exacte",
            "form_address_search_address_extra_button": "Utiliser l'adresse de livraison",
            "button_label_validate": "Valider",
            "error_cart_discounts_common_error": "Ce code de réduction est invalide.",
            "button_label_cancel": "Annuler",
            "checkout_error_no_shipping_methods_available": "Aucune méthode de livraison n'a été trouvée. Si le blocage persiste, merci de contacter le service client.",
            "newsletter_subscribe_success": "Votre inscription à la newsletter a bien été prise en compte.",
            "newsletter_unsubscribe_success": "Vous êtes maintenant désinscrit de la newsletter.",
            "checkout_cart_excl_taxes": "hors taxes",
            "checkout_cart_total_discount_old_price": "Montant hors réduction",
            "error_cart_discounts_code_already_used": "Un code de réduction est déjà appliqué.",
            "checkout_cart_error_cart_infos_have_changed": "Les informations de votre panier ont changé, veuillez vérifier les nouvelles informations avant de retenter votre paiement.",
            "error_creating_payment_intent": "Une erreur est survenue avec votre carte. Le paiement n'a pas abouti.",
            "error_updating_cart_already_validated": "Votre panier est déjà validé.",
            "payment_intent_authentication_failure": "Votre méthode de paiement n'a pu être authentifiée. Le paiement n'a pas abouti.",
            "payment_validation_delivery_infos": "Vous devez remplir, valider et confirmer votre adresse de livraison.",
            "payment_validation_billing_infos": "Vous devez remplir, valider et confirmer votre adresse de facturation.",
            "payment_validation_payment_method": "Vous devez remplir les informations de paiement.",
            "payment_validation_terms": "Vous devez accepter les conditions générales de vente et les conditions de rétractation.",
            "product_discover": "Découvrir",
            "product_out_of_stock": "Cet article n'est plus disponible",
            "fields_newpassword_label": "Nouveau mot de passe",
            "fields_newpasswordconfirmation_label": "Confirmation du mot de passe",
            "register_special_info": null,
            "fields_newpassword_placeholder": "Votre nouveau mot de passe (8 caractères min.)",
            "fields_newpasswordconfirmation_placeholder": "Confirmez votre nouveau mot de passe",
            "forms_updatepassword_buttons_submit_label": "Valider",
            "catalog_search_textfield_placeholder": "Rechercher",
            "forms_register_data_infos": "Les données recueillies sont destinées aux services de Lunii et à ses prestataires pour le traitement de votre commande et pour nos relations commerciales. Conformément à la loi \"Informatique et Libertés n°78-17 du 6 Janvier 1978\" relative à l'informatique, aux fichiers et aux libertés, et au règlement européen 2016/679 du 27 avril 2016 relatif au données personnelles, vous disposez d'un droit d'accès, de modifications, de rectification, et de suppression des données vous concernant. Lunii s'engage à la confidentialité, et à la protection de vos données.",
            "forms_updatepassword_confirmation_not_match_error": "Les mots de passes saisis ne correspondent pas",
            "passwordupdatemodal_title": "Modifier mon mot de passe",
            "passwordupdatemodal_success_message": "Votre mot de passe a bien été mis à jour.",
            "catalog_no_result_refresh": "Découvrir d'autres livres audio",
            "passwordupdatemodal_text_resetonlogin": "Vous venez de vous connecter avec un mot de passe temporaire. Saisissez votre nouveau mot de passe, vous serez automatiquement connectés une fois que vous aurez validé le formulaire.",
            "passwordupdatemodal_close": "Fermer",
            "passwordupdatemodal_title_success": "Succès !",
            "errors_passwordupdate_user_not_validated": "Impossible de changer de mot de passe, votre compte n'est pas encore validé.",
            "errors_passwordupdate_password_does_not_match": "Votre mot de passe actuel est incorrect.",
            "errors_passwordupdate_new_equals_to_old_password": "Vous ne pouvez pas réutiliser le même mot de passe.",
            "checkout_paymentMethod_stripe": "Carte bancaire",
            "checkout_paymentMethod_paypal": "PayPal",
            "cartmodal_product_infos_fah_v1": "La version fabriquée en France est à ce jour uniquement disponible en français.",
            "checkout_paypal_infos": "En cliquant sur \"Payer\", vous serez redirigé vers Paypal afin de finaliser votre achat. Une fois la transaction validée, vous serez automatiquement redirigé vers le site de Lunii.",
            "error_card_declined": "Votre carte à été refusée, veuillez ré-essayer avec une autre méthode de paiement.",
            "error_creating_payment_paypal": "Une erreur est survenue lors de la création du paiement chez le prestataire de paiement sélectionné. Veullez réessayer avec un autre mode de paiement.",
            "card_number_in_name_field": "Assurez-vous que le champ \"Titulaire de la carte\" ne contient pas de numéro de carte bancaire.",
            "card_declined": "Votre carte à été refusée, veuillez utiliser une autre méthode de paiement.",
            "paypal": "PayPal",
            "catalog_menu_spotlight": "À la une",
            "catalog_menu_products": "Catalogue",
            "cart_error_some_products_removed": "Un ou plusieurs produits ont été retirés de votre panier parce qu'ils sont en rupture de stock ou déjà présents dans votre bibliothèque : <br/> {{description}}",
            "gift_card_type_lunii": "Carte cadeau Lunii de",
            "gift_card_expired_text": "Expire le",
            "gift_card_remove": "Supprimer",
            "library_menu_luniistore": "Livres audio",
            "library_menu_studio": "Histoires Studio",
            "library_title": "Ma bibliothèque",
            "library_empty_title": "Votre bibliothèque semble bien vide...",
            "library_empty_description": "Associer votre Fabrique à Histoires à votre compte et découvrez notre catalogue pour les 3-7 ans !",
            "my_account_giftcard_title": "Cartes cadeau",
            "my_account_giftcard_subtitle": "Associer une carte cadeau, liste de vos cartes cadeau.",
            "mygiftcards_no_giftcards": "Aucune carte cadeau n'est enregistrée pour le moment",
            "forms_addgiftcard_buttons_submit_label": "Ajouter",
            "forms_addgiftcard_buttons_cancel_label": "Annuler",
            "error_gift_card_cannot_add_gift_card_to_user": "Impossible d'ajouter la carte cadeau renseignée à votre compte",
            "error_gift_card_cannot_remove_card_from_user": "Impossible de supprimer la carte cadeau de votre compte",
            "error_gift_card_cannot_retrive_card_from_hipay": "Le code carte cadeau saisi n'est pas reconnu",
            "gift_card_checkout_select": "Sélectionner",
            "checkout_giftcard_title": "Cartes Cadeaux",
            "my_purchase_history_no_purchase": "Nous n'avons retrouvé aucun achat pour le moment.",
            "forms_addgiftcard_button_open_form": "Ajouter une carte cadeau",
            "gift_card_checkout_unselect": "Désélectionner",
            "checkout_cart_total_amount_giftcard": "Carte cadeau",
            "cta_item_in_cart": "Voir",
            "cta_item_to_buy": "Ajouter",
            "cta_item_owned": "Déjà acheté",
            "error_gift_card_form_card_redeemed_or_expired": "La carte cadeau ajoutée a déjà été utilisée ou la date d'expiration est dépassée",
            "purchase_status_success": "Succès",
            "purchase_status_pending": "Paiement non finalisé",
            "purchase_status_failure": "Echec",
            "purchase_status_created": "Créée",
            "purchase_status_cancelled": "Annulée",
            "purchase_status_refunded": "Remboursée",
            "purchase_status": "Statut",
            "checkout_cart_amount_giftcard": "Carte cadeau",
            "purchase_invoice_no_invoice": "Aucune facture",
            "checkout_payment_wait": "Paiement en cours. Veuillez patienter…",
            "purchase_invoice_download_invoice": "Télécharger la facture",
            "my_account_billing_and_invoices_title": "Informations de paiement",
            "my_account_billing_and_invoices_subtitle": "Adresses de facturation et de livraison, moyens de paiement.",
            "my_account_purchase_history_title": "Historique d’achats",
            "my_account_purchase_history_subtitle": "Historique des achats et factures.",
            "purchase_invoice_paper": "Facture papier (dans le colis)",
            "purchase_invoice_subtitle_packs": "Livres audio",
            "purchase_invoice_subtitle_hardware": "Produits",
            "push_sticky_close": "Fermer",
            "library_description": "Pour transférer vos livres audio sur votre Fabrique à Histoires, rendez-vous sur l'application ordinateur <1>Luniistore<1/>.",
            "form_address_search_no_address_found": "Aucun résultat ne correspond à votre recherche. Si vous souhaitez continuer avec l'adresse que vous avez saisi malgré tout, cliquez sur",
            "fields_phone_format_error": "Le numéro de téléphone saisi ne semble pas être valide.",
            "fields_phone_international_label": "Autre pays",
            "form_address_uneditable_country_tooltip": "La livraison n'est possible que dans le pays associé à la boutique dans laquelle vous vous trouvez. Pour changer de pays, veuillez retourner sur la page d'accueil afin de choisir le pays en bas de page.",
            "fields_state_label": "État / Province",
            "fields_state_placeholder": "État / Province",
            "my_subscription_discover_title": "Découvrez l’abonnement Lunii",
            "my_subscription_discover_text": "<1>6,90 € par mois pour le livre audio de votre choix, quel que soit le prix</1>\n<1>Résiliez à tout moment, vos livres audio vous appartiennent</1>\n<1>Jusqu’à 3 Fabriques à Histoires associées</1>",
            "my_subscription_discover_cta_text": "Découvrir",
            "my_subscription_credits_remaining": "Solde actuel",
            "my_subscription_sub_renew_planned": "Date de renouvellement",
            "my_subscription_sub_type": "Type d'abonnement",
            "my_subscription_sub_canceled": "Arrêt prévu le",
            "my_subscription_credits_valid_until": "Utilisez vos crédits jusqu'au",
            "my_subscription_sub_type_yearly_subscription": "Annuel",
            "form_address_delivery_address_phone_explanation": "Indiquez un numéro de téléphone afin que le livreur puisse vous contacter.",
            "checkout_cart_total_bundle_discount": "Réduction Bundle",
            "pack_assets_label": "Activités",
            "checkout_bundle_next_page_warning": "Vous bénéficiez d'une offre spéciale ? Les réductions éventuelles seront calculées sur la prochaine page.",
            "forms_addcreditcard_button_open_form": "Enregistrer un moyen de paiement",
            "button_label_save": "Enregistrer",
            "credit_card_expired": "Expirée",
            "credit_card_expire_date": "Expire le",
            "mycreditcards_no_creditcards": "Vous n’avez pas encore ajouté de moyen de paiement.",
            "stripe_card_post_error_card_already_exists": "Cette carte est déjà enregistrée.",
            "stripe_card_delete_error_deleting_card": "Un problème est survenu pendant la suppression de la carte bancaire.",
            "stripe_card_post_error_creating_card": "Un problème est survenu pendant l'enregistrement de la carte bancaire.",
            "stripe_cards_get_unable_to_retrieve_cards_for_user": "Un problème est survenu pendant la récupération des informations de carte bancaire.",
            "my_account_creditcard_title": "Modes de paiement",
            "forms_forgotpassword_buttons_resend_label": "Renvoyer l'e-mail de confirmation",
            "forms_resend_validation_verify_inbox_text": "Un e-mail de confirmation vous a été envoyé.",
            "error_resend_validation": "Une erreur est survenue lors de l'envoi de l'e-mail de confirmation. Veuillez réessayer. Si l'erreur persiste prenez contact avec le service client",
            "my_account_subscription_title": "Mon abonnement",
            "my_subscription_status_active": "Abonnement Actif",
            "my_subscription_status_cancelled": "Abonnement Annulé",
            "checkout_paymentMethod_paypal_content": "Au moment du paiement, vous serez redirigé vers Paypal afin de finaliser votre achat. Une fois la transaction validée, vous serez automatiquement redirigé vers le site de Lunii.",
            "credit_card_form_register": "Enregistrer pour plus tard",
            "my_subscription_action_retract": "Exercer mon droit de retrait",
            "my_subscription_action_cancel": "Annuler mon abonnement",
            "my_subscription_action_reactivate": "Réactiver mon abonnement",
            "my_subscription_action_browse_offers": "Consulter les offres d'abonnement",
            "my_subscription_cancel_modal_title": "Vous nous quittez ?",
            "my_subscription_cancel_modal_body": "L’annulation de votre abonnement prendra effet le <1>{{date1}}</1>. Pendant 1 mois, jusqu’au <1>{{date2}}</1>, vous pourrez continuer à utiliser vos crédits restants et réactiver à tout moment votre abonnement si vous le souhaitez.\n<1>Passé ce délai, votre abonnement s’annulera définitivement et vos crédits restants seront perdus.</1>",
            "my_subscription_cancel_modal_positive": "Compléter l'annulation",
            "my_subscription_cancel_modal_negative": "Revenir",
            "my_subscription_retract_modal_title": "Vous souhaitez exercer votre droit de rétractation ?",
            "my_subscription_retract_modal_body": "Vous avez contracté votre abonnement il y a moins de 14 jours et vous n’avez pas encore dépensé votre crédit. Vous pouvez décider d’annuler votre souscription pour obtenir un remboursement. \nCependant, vos crédits seront perdus.",
            "my_subscription_retract_modal_positive": "Me rétracter",
            "my_subscription_retract_modal_negative": "Revenir",
            "my_subscription_reactivate_modal_title": "Réactiver votre abonnement ?",
            "my_subscription_reactivate_modal_body": "Vous pouvez réactiver un abonnement pour lequel vous avez demandé une annulation. En confirmant la réactivation de votre abonnement, celui continuera normalement après la date du <1>{{date1}}</1>.",
            "my_subscription_reactivate_modal_positive": "Réactiver",
            "my_subscription_reactivate_modal_negative": "Annuler",
            "subscription_widget_wallet_credits": "crédit",
            "subscription_widget_wallet_credits_plural": "crédits",
            "subscription_widget_go_to_my_subscription": "Voir mon abonnement",
            "subscription_widget_next_planned_event_delete_subscription": "À utiliser avant le {{date}}",
            "product_open_subscription_modal": "Utiliser {{credit}} crédit",
            "subscriptionspendmodal_title": "Acheter pour {{credit}} crédit",
            "subscriptionspendmodal_balance_current": "Solde actuel: {{credit}} crédit(s)",
            "subscriptionspendmodal_balance_current_plural": "Solde actuel: {{credit}} crédits",
            "subscriptionspendmodal_balance_after_purchase": "Solde après achat: {{credit}} crédit(s)",
            "subscriptionspendmodal_balance_after_purchase_plural": "Solde après achat: {{credit}} crédits",
            "subscriptionspendmodal_confirm_usage": "En appuyant sur “Valider”, je confirme l’utilisation d’un crédit",
            "subscriptionspendmodal_disclamer": "Le livre audio sera directement ajouté à votre bibliothèque. J’accepte que mon droit de rétraction légal prenne fin dans 14 jours ou dès que je commence le téléchargement de ce titre sur Ma Fabrique à Histoires, selon ce qui se produit en premier.",
            "subscriptionspendmodal_cancel": "Annuler",
            "subscriptionspendmodal_confirm": "Valider",
            "subscriptionspendmodal_success_title": "Le livre audio a bien été ajouté à votre bibliothèque !",
            "subscriptionspendmodal_success_text": "Rendez-vous sur le Luniistore pour le transférer sur votre Fabrique à Histoires.",
            "subscriptionspendmodal_success_close": "Terminer",
            "subscription_advert_title": "Découvrez l'abonnement Lunii",
            "subscription_advert_text": "Pour seulement 6,90 €, téléchargez chaque mois le livre audio de votre choix pour Ma Fabrique à Histoires.",
            "subscription_advert_navigate": "Découvrir",
            "subscription_product_annual_subscription_incentive": "2 mois offerts",
            "subscription_product_annual_subscription_pricing": "69 € / an",
            "subscription_product_monthly_subscription_pricing": "6,90 € / mois",
            "subscription_product_selected": "Sélectionné",
            "subscription_product_select": "Sélectionner",
            "subscription_title": "Abonnement Lunii",
            "subscription_sub_title": "Choisissez votre offre",
            "subscription_paymentform_title": "Moyens de paiement",
            "subscription_paymentform_info": "Actuellement, il n’est possible de souscrire à l’abonnement Lunii qu’avec une carte bancaire.",
            "subscription_checkbox_terms": "J’ai lu et accepté <1>les conditions générales de vente.</1>",
            "subscription_current_selected": "Vous avez sélectionné",
            "subscription_current_selected_monthly_subscription": "l'abonnement mensuel (6,90€ / mois)",
            "subscription_current_selected_annual_subscription": "l'abonnement annuel (69€ / an)",
            "subscription_pay": "Payer et finaliser la souscription",
            "subscription_billingAddress_form_title": "Adresse de facturation",
            "subscription_confirm_body_p1": "<1>Cette icône représente un crédit, utilisable pour acheter un livre audio avec votre abonnement.</1>\nDépensez vos crédits depuis les pages des livres audio qui vous intéressent.",
            "subscription_confirm_body_p2": "<1>Retrouvez votre compteur de crédits directement dans votre barre de navigation.</1>\nCliquez dessus à tout moment pour accéder aux informations sur votre prochain renouvellement.",
            "subscription_confirm_gotolibrary": "Voir le catalogue de livres audio",
            "subscription_confirm_body_title": "Quelques conseils",
            "my_account_subscription_subtitle": "Gérer votre abonnement.",
            "my_subscription_credits": "crédit",
            "my_subscription_credits_plural": "crédits",
            "my_subscription_credits_remaining_plural": "Crédits restants",
            "checkout_paymentMethod_gift_card_full_cart": "Utiliser <1>{{price}}</1> des <1>{{balance}}</1> de votre solde carte cadeau",
            "checkout_paymentMethod_gift_card_exact_cart": "Utiliser la totalité des <1>{{balance}}</1> de votre solde carte cadeau",
            "subscription_already_owned_gohome_button_label": "Voir mon compte",
            "field_required_error": "Ce champ est obligatoire.",
            "error_subscription_server": "Une erreur est survenue lors du traitement de votre achat. Vous n'avez pas été débité. Veuillez réessayer ultérieurement.",
            "error_subscription_not_enough_credits": "Votre achat n'a pu aboutir par manque de crédits sur votre compte.",
            "error_subscription_unlisted": "Une erreur est survenue lors du traitement de votre demande.",
            "incorrect_cvc": "Le cryptogramme visuel est invalide.",
            "expired_card": "La carte de paiement est expirée",
            "processing_error": "Une erreur est survenue lors du paiement veuillez ré-essayer. Si l'erreur persiste veuillez ré-essayer ultérieurement",
            "seo_title_suffix_digital_album": "Histoires audio | Lunii",
            "incorrect_number": "Le numéro de carte est invalide.",
            "gift_card_balance_title": "Solde carte cadeau",
            "gift_card_balance_available_balance": "Solde disponible",
            "gift_card_balance_balance_infos": "Valable uniquement sur un panier contenant exclusivement des livres audio.",
            "gift_card_balance_card_page_link": "Acheter une e-carte cadeau",
            "gift_card_balance_warning_infos": "La totalité du montant de la carte cadeau sera créditée sur votre solde de carte, en accord avec les <devComponent/>",
            "gift_card_balance_terms": "mentions légales.",
            "gift_card_balance_form_toggle_button": "Ajouter une nouvelle carte cadeau",
            "gift_card_balance_form_add_button": "Ajouter",
            "gift_card_balance_form_cancel_button": "Annuler",
            "gift_card_balance_added_message": "Votre carte cadeau a bien été ajoutée.",
            "gift_card_balance_error_unable_to_retrieve_account_balance": "Une erreur est survenue lors de la récupération du solde carte cadeau. Si le blocage persiste, merci de contacter le service client.",
            "gift_card_balance_error_gift_card_is_expired": "La carte cadeau sélectionnée est expirée.",
            "gift_card_balance_error_gift_card_is_invalid": "La carte cadeau sélectionnée est invalide.",
            "checkout_paymentMethod_gift_card_not_allowed": "Pour utiliser votre solde carte cadeau de {{balance}}, votre commande ne doit contenir que des livres audio.",
            "checkout_paymentMethod_gift_card_left_to_pay": "Veuillez ajouter un mode de paiement pour régler les {{amount}} restants.",
            "checkout_payment_update": "Mise à jour du panier en cours",
            "checkout_cart_total_account_balance": "Solde carte cadeau Lunii",
            "gift_cards_visual_amount_proposal_1": "15",
            "gift_cards_visual_amount_proposal_2": "20",
            "gift_cards_visual_amount_proposal_3": "30",
            "gift_cards_visual_amount_proposal_4": "40",
            "fields_giftcardamount_custom_label": "Montant personnalisé (ex: 50 €)",
            "fields_giftcardreceivername_label": "Prénom du petit chanceux",
            "fields_giftcardreceiveremail_label": "Adresse e-mail du destinataire",
            "constraints_too_many_characters": "Vous avez dépassé le nombre de caractères autorisés.",
            "gift_cards_receiver_no_receiver": "Je reçois l'e-carte cadeau sur mon adresse e-mail pour une remise en main propre",
            "gift_cards_message_title": "Ajoutez un petit mot",
            "gift_cards_message_subtitle": "facultatif",
            "gift_cards_message_warning": "Attention, une fois l’e-carte commandée, ce message ne pourra plus être modifié.",
            "fields_giftcardmessage_label": "Message de 200 caractères maximum",
            "characters": "caractères",
            "gift_cards_sender_title": "De la part de :",
            "fields_giftcardsendername_label": "Votre nom",
            "gift_cards_date_title": "Programmez une date d'envoi",
            "gift_cards_amount_title": "Saisissez un montant",
            "gift_cards_visual_amount_infos": "Les prix de nos livres audio sont fixés en fonction de leur durée et de leur catégorie. Un livre coûte en moyenne 9,90€.",
            "gift_cards_visual_receiver_infos": "Le destinataire recevra par e-mail une carte imprimable, personnalisée avec l'univers choisi, à offrir en main propre.",
            "gift_cards_visual_title": "Sélectionnez un visuel",
            "gift_cards_visual_infos": "Plusieurs visuels sont proposés pour adapter l'univers aux envies de tous.",
            "gift_cards_summary_title": "Récapitulatif",
            "gift_cards_summary_receiver": "Un cadeau pour",
            "gift_cards_receiver_title": "Destinataire",
            "gift_cards_error_giftcardtheme": "Vous devez choisir un thème",
            "gift_cards_error_giftcarddate": "Vous devez choisir une date",
            "gift_cards_error_giftcardamount": "Vous devez choisir un montant",
            "gift_cards_error_giftcardreceivername": "Vous devez remplir le nom du destinataire",
            "gift_cards_error_giftcardreceiveremail": "Vous devez remplir l'adresse e-mail du destinataire",
            "gift_cards_error_giftcardsendername": "Vous devez remplir votre nom",
            "gift_cards_date_infos": "L’e-carte cadeau sera envoyée le jour sélectionné à son destinataire. Le règlement de la carte cadeau est effectué au moment de la validation de la commande.",
            "fields_date_label": "Sélectionnez une date",
            "date_field_error_giftcards_invaliddate": "Le format de la date n'est pas valide",
            "date_field_error_giftcards_disablepast": "Vous ne pouvez pas sélectionner une date antérieure à aujourd’hui",
            "date_field_error_giftcards_maxdate": "Vous ne pouvez choisir une date supérieure à 1 mois",
            "gift_cards_submit_infos": "Vous recevrez l'e-mail d'ici quelques minutes",
            "gift_cards_invalid_form": "Le formulaire comporte des erreurs",
            "gift_cards_gift_in_cart_name": "E-carte cadeau",
            "gift_cards_gift_in_cart_for": "Pour",
            "gift_cards_gift_in_cart_from": "De la part de",
            "gift_cards_gift_in_cart_message": "Message",
            "gift_cards_gift_in_cart_date": "Date d'envoi",
            "checkout_payment_get_cart": "Récupération du panier en cours",
            "my_account_giftcard_balance_title": "Solde carte cadeau",
            "my_account_giftcard_balance_subtitle": "Consulter le solde, ajouter une carte cadeau.",
            "gift_card_balance_availability": "Le solde de votre carte cadeau est valable 1 an à compter de la date d'activation de la dernière carte cadeau ajoutée dans le compte.",
            "error_validating_cart_error_on_validate": "Une erreur est apparue pendant la validation de votre panier. Si le blocage persiste, merci de contacter le service client.",
            "legacy_gift_card_modal_title": "Souhaitez-vous transférer le montant de vos cartes cadeaux en solde ?",
            "legacy_gift_card_modal_body": "Lunii a changé son système de gestion de cartes cadeaux à la faveur d'un solde unique (regroupant l'ensemble des montants de vos cartes cadeaux). Vous aviez d'anciennes cartes cadeaux enregistrées sur notre plateforme : souhaitez-vous les transférer vers ce solde virtuel ? Il pourra, à votre guise, être utilisé lors du paiement de livres audio.",
            "legacy_gift_card_validate_button": "Transférer le montant",
            "gift_card_merge_error_unable_to_merge": "Une erreur est survenue lors du transfert. Si le blocage persiste, merci de contacter le service client.",
            "subscription_checkbox_gift": "Mon abonnement sera associé au compte {{email}}. Mes crédits pourront donc seulement être utilisés sur ce compte.",
            "subscription_notify_music": "Les livres audio de la catégorie \"Ma Boîte à Musique\" ne sont pas compris dans l'offre d'Abonnement.",
            "my_fahs_title": "Mes Fabriques à Histoires",
            "my_fahs_bottom_note": "Vous pouvez associer jusqu’à 3 Fabriques à Histoires à votre compte.",
            "my_account_fahs_title": "Mes Fabriques à Histoires",
            "char_count": "caractères",
            "fields_fahname_label": "Nom de Ma Fabrique à Histoires",
            "fields_fahname_placeholder": "Ma Fabrique à Histoires",
            "fields_fahserial_label": "N° de série",
            "fields_fahserial_placeholder": "N° de série de 10 ou 14 chiffres",
            "fah_firmware": "Logiciel interne",
            "my_fahs_fah_modal_cancel": "Annuler",
            "my_fahs_fah_modal_confirm": "Sauvegarder les modifications",
            "my_fahs_no_fah": "Pour associer Ma Fabrique à Histoires à votre compte Lunii, téléchargez l'application <1>Luniistore</1> sur votre ordinateur, lancez-la et branchez votre Fabrique à Histoires.",
            "my_fahs_fah_modal": "Ma Fabrique à Histoires",
            "date_format": "JJ/MM/AAAA",
            "gift_card_balance_expiration_date": "Date d'expiration du solde : {{date}}",
            "error_fah_update_retrieve_fah": "Impossible de retrouver cette Fabrique; raffraichissez la page et recommencez.",
            "error_fah_update_name": "Impossible de mettre à jour le nom de cette Fabrique.",
            "error_fah_update_serial": "Imossible de mettre à jour le numéro de série de cette fabrique.",
            "constraints_fahserial": "Le nom est requis et doit mesurer entre 1 et 40 charactères.",
            "constraints_fahname": "Le numéro de série doit mesurer entre 10 et 14 charactères.",
            "country_nl_label": "Pays-Bas",
            "ecommerce_hardware_included_title": "Inclus dans l’offre",
            "audio_player_play": "Extrait",
            "audio_player_pause": "Pause",
            "error_validate_codealbum_unable_to_retrieve_code": "Le code est invalide",
            "error_validate_codealbum_max_usage_count_reached": "Cette offre est épuisée",
            "error_validate_codealbum_unable_to_retrieve_offer": "Erreur serveur",
            "error_validate_codealbum_code_not_active": "Le code est inactif.",
            "error_validate_codealbum_code_not_yet_active": "Le code n'est pas encore valide.",
            "error_validate_codealbum_code_expired": "Le code est expiré.",
            "error_validate_codealbum_no_available_products": "Vous possédez tous les produits que peut offrir ce code.",
            "error_validate_codealbum_code_already_used": "Vous avez déjà utilisé cette offre.",
            "error_activate_codealbum_unable_to_retrieve_code": "Le code est invalide.",
            "error_activate_codealbum_max_usage_count_reached": "Ce code a déjà été utilisé.",
            "error_activate_codealbum_unable_to_retrieve_offer": "Impossible de retrouver l'offre associée à ce code livre.",
            "error_activate_codealbum_code_not_active": "Le code actuellement inactif.",
            "error_activate_codealbum_code_not_yet_active": "Le code n'est pas encore valide.",
            "error_activate_codealbum_code_expired": "Le code est expiré.",
            "error_activate_codealbum_code_already_used": "Vous avez déjà utilisé cette offre.",
            "error_activate_codealbum_unable_to_retrieve_user_delivery_infos": "Vous n'avez pas saisi d'adresse de livraison dans votre compte.",
            "error_activate_codealbum_unable_to_retrieve_user_billing_infos": "Vous n'avez pas saisi d'adresse de facturation dans votre compte.",
            "error_activate_codealbum_unable_to_retrieve_user": "Le code est invalide.",
            "my_code_album_modal_title_success": "Ce livre audio a bien été ajouté à votre bibliothèque !",
            "my_code_album_modal_body_success": "Rendez-vous sur l'Application mobile pour Android ou sur le Luniistore pour ordinateur pour le transférer sur votre Fabrique à Histoires.",
            "my_code_album_modal_go_to_library": "Voir ma bibliothèque",
            "my_code_album_modal_validated_back": "Retour au compte",
            "my_code_album_modal_validate": "Utiliser mon code",
            "my_code_album_modal_title_oneinmany": "Choisissez un livre audio parmi la liste suivante",
            "my_code_album_modal_body_choice_oneinmany": "Une fois votre code utilisé, vous ne pourrez plus l'utiliser sur un autre compte ou modifier votre choix.",
            "my_code_album_body": "Vous avez obtenu un code vous permettant de déverrouiller l'accès à un livre audio ? Renseignez-le ici pour qu'il soit ajouté directement à votre bibliothèque.",
            "my_code_album_title": "Code livre",
            "my_code_album_modal_title_one": "Vous allez obtenir le(s) livre(s) audio suivant(s)",
            "my_code_album_modal_body_choice_one": "Une fois votre code utilisé, vous ne pourrez plus l'utiliser sur un autre compte.",
            "my_code_album_subtitle": "Entrez votre code pour déverrouiller gratuitement un livre audio.",
            "my_code_album_placeholder": "Code livre",
            "my_code_album_label": "Code livre",
            "checkout_cart_subscription_warning": "En tant qu’abonné, si vous souhaitez utiliser votre crédit, rendez-vous sur la page du livre audio de votre choix.",
            "subscription_widget_next_planned_event_increment_balance": "Prochain crédit dans {{date}} jours",
            "merge_account_warning_modal_title": "Vous n'avez pas de Fabrique à Histoires associée à votre compte.",
            "merge_account_warning_modal_body_text": "Avant de procéder à votre achat, assurez-vous que vous n'avez pas déjà créé un compte Lunii. Les livres audio achetés sur ce compte ne pourront pas être transférés vers un autre compte.",
            "merge_account_warning_modal_body_subtext": "Vous êtes connecté sur le compte",
            "merge_account_warning_modal_button_confirm": "Continuer vers le paiement",
            "merge_account_warning_modal_infos_text": "Vous souhaitez offrir un livre audio ? Vous pouvez offrir <1>une e-carte cadeau Lunii</1> !",
            "merge_account_warning_modal_logout_button": "Me connecter avec un autre compte",
            "wishlist_heading_title": "Ma liste de souhaits",
            "wishlist_empty": "Vous n'avez rien ajouté à votre liste de souhaits.",
            "wishlist_not_empty": "Retrouvez ici vos livres audio mis de côté.",
            "wishlist_heading_pretitle": "{{count}} livre audio",
            "wishlist_heading_pretitle_plural": "{{count}} livres audio",
            "ecommerce_hardware_lunii_-_2_fr_3+_name": "Ma Fabrique à Histoires dès 3 ans",
            "ecommerce_hardware_lunii_-_2_fr_3+_description": "Plus de 3 heures d'histoires audio adaptées aux enfants dès 3 ans",
            "ecommerce_hardware_lunii_-_2_fr_5+_name": "Ma Fabrique à Histoires dès 5 ans",
            "ecommerce_hardware_lunii_-_2_fr_5+_description": "Plus de 3 heures d'histoires audio adaptées aux enfants dès 5 ans",
            "ecommerce_hardware_lunii-2-fr-ocean_name": "Ma Fabrique à Histoires Océan",
            "ecommerce_hardware_lunii-2-fr-ocean_description": "Une édition limitée de Ma Fabrique à Histoires avec un design inédit",
            "subscription_product_monthly_subscription_incentive": "Recommandé pour vous",
            "my_subscription_scp_link": "Actualiser mon mode de paiement",
            "credit_card_default_name": "Carte bancaire",
            "mycreditcards_scp_link": "Gérer mes cartes bancaires",
            "subscription_title_select_step": "Sélectionnez votre abonnement Lunii",
            "subscription_product_monthly_subscription_title": "Mensuel",
            "subscription_product_monthly_subscription_pretitle": "6,90 € / mois",
            "subscription_product_monthly_subscription_body": "• 1 livre audio au choix chaque mois\n• 82,80 € / an (6,90 € tous les mois)\n• Sans engagement, résiliez à tout moment\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_annual_subscription_title": "Annuel (paiement en une fois)",
            "subscription_product_annual_subscription_pretitle": "69 € / an",
            "subscription_product_annual_subscription_body": "• 1 livre audio au choix chaque mois\n• 69 € / an (soit 5,75 € / mois)\n• Engagement d’un an renouvelable\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_monthly_subscription_pretitle_free_month": "Mensuel",
            "subscription_product_monthly_subscription_title_free_month": "1 mois offert",
            "subscription_product_monthly_subscription_subtitle_free_month": "puis 6,90 € / mois",
            "subscription_product_monthly_subscription_body_free_month": "• 1 livre audio au choix chaque mois\n• 82,80 € / an (6,90 € tous les mois)\n• Sans engagement, résiliez à tout moment\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_annual_subscription_pretitle_free_month": "Annuel",
            "subscription_product_annual_subscription_title_free_month": "69 € / an",
            "subscription_product_annual_subscription_subtitle_free_month": "soit 5,75 € / mois",
            "subscription_product_annual_subscription_body_free_month": "• 1 livre audio au choix chaque mois\n• 69 € / an (soit 5,75 € / mois)\n• Engagement d’un an renouvelable\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_title_billing_step": "Renseignez votre mode de paiement",
            "subscription_recap_title_monthly_subscription_free_month": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription_free_month": "1 mois offert puis 6,90 € / mois",
            "subscription_recap_startdate_caption_monthly_subscription_free_month": "Début du mois offert :",
            "subscription_recap_enddate_caption_monthly_subscription_free_month": "Début de la facturation :",
            "subscription_recap_action": "Modifier mon abonnement",
            "subscription_recap_title_monthly_subscription": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription": "6,90 € / mois",
            "subscription_recap_startdate_caption_monthly_subscription": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_monthly_subscription": "Début de la facturation :",
            "subscription_recap_title_annual_subscription": "Votre abonnement annuel",
            "subscription_recap_subtitle_annual_subscription": "69 € / an",
            "subscription_recap_startdate_caption_annual_subscription": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_annual_subscription": "Début de la facturation :",
            "today": "aujourd'hui",
            "subscription_confirm_title_free_month": "Vous êtes maintenant abonné et pouvez profiter de votre premier mois offert !",
            "subscription_confirm_title": "Vous êtes maintenant abonné !",
            "subscription_confirm_subtitle_free_month": "Merci d’avoir souscrit à l’abonnement Lunii. Vous recevrez votre prochain crédit le {{date}}.",
            "subscription_confirm_subtitle": "Merci d’avoir souscrit à l’abonnement Lunii. Vous recevrez votre prochain crédit le {{date}}.",
            "subscription_confirm_body_p3": "<0>Gérez votre abonnement depuis </0><1>votre compte</1><0>.</0>\nAnnuler mon abonnement, modifier votre mode de paiement ou simplement suivre l’état de votre abonnement.",
            "subscription_already_owned_modal_title": "Il semble qu’un abonnement soit déjà actif sur votre compte",
            "subscription_already_owned_modal_body": "Vous avez déjà un abonnement en cours sur votre compte (abonnement actif, abonnement cadeau ou abonnement en cours de résiliation). \nAttendez la fin de cet abonnement pour prolonger l’expérience en souscrivant à un nouvel abonnement !",
            "my_subscription_status_trialing": "Essai gratuit",
            "my_subscription_free_month_warning": "Vous profitez actuellement de votre mois offert, qui prendra fin le {{date}}.",
            "my_subscription_sub_type_monthly_subscription": "Abonnement mensuel",
            "my_subscription_sub_type_annual_subscription": "Abonnement annuel",
            "my_subscription_sub_type_yearly_subscription_price": "69 € / an",
            "my_subscription_sub_type_monthly_subscription_price": "6,90 € / mois",
            "subscription_warning_message_free_month_already_used": "Vous avez déjà bénéficié de votre mois offert. Choisissez une offre d'abonnement pour profiter chaque mois d’un nouvel album audio.",
            "subscription_select": "Suivant",
            "my_subscription_sub_trial": "Début de la facturation",
            "credit_card_used_in_subscription": "Utilisée pour l'abonnement",
            "credit_card_default_card": "Utilisée par défaut",
            "forms_addcreditcard_button_open_form_variant": "Nouveau mode de paiement",
            "locale_nl_nl_product_language": "Néerlandais",
            "locale_nl_nl_label": "Pays-Bas",
            "my_subscription_sub_type_annual_subscription_price": "69€ / an",
            "my_subscription_sub_type_mensual_subscription_price": "6,90€ / mois",
            "ecommerce_hardware_lunii-2-fr-pan_description": "Pandaroux débarque pour traquer les super-méchants !",
            "ecommerce_hardware_lunii-2-fr-mae_description": "Le savant Maestro emmène Suzanne et Gaston à la découverte du Moyen-Âge",
            "my_subscription_discover_activate_infos_heading": "Avec l’abonnement Lunii, jusqu'à -50% sur vos livres audio !",
            "my_subscription_discover_activate_infos_text": "<1>1 nouveau crédit chaque mois pour télécharger le livre audio de votre choix</1><1>Un accès complet au catalogue des Éditions Lunii</1><1>Des crédits cumulables s’ils ne sont pas utilisés</1><1>Des livres audio téléchargeables sur 3 Fabriques à Histoires</1>",
            "my_subscription_activate_infos_cta_text": "Activer mon abonnement cadeau",
            "my_subscription_activate_form_heading": "Activer votre abonnement",
            "my_subscription_activate_form_text": "On vous a offert un abonnement Lunii ? Inscrivez le code d’activation ci-dessous avant sa date d’expiration pour bénéficier de vos mois cadeau.",
            "fields_couponid_label": "ABO_XXXXXX",
            "my_subscription_activate_form_activate": "Activer",
            "my_subscription_activate_form_error_format_not_valid": "Le code n'est pas au bon format.",
            "my_subscription_activate_form_error_code_not_valid": "Le code est invalide.",
            "my_subscription_activate_form_error_code_already_activated": "Le code a déjà été activé.",
            "my_subscription_activate_form_error_code_expired": "Le code est périmé.",
            "my_subscription_activate_form_cancel": "Annuler",
            "my_subscription_activate_form_modal_heading": "Où trouver mon code d’activation ?",
            "my_subscription_activate_form_modal_button": "J'ai compris",
            "my_subscription_activate_form_modal_body_title": "Votre abonnement vous a été :",
            "my_subscription_activate_form_modal_body_one": "<1>Offert en main propre : </1>votre code a été inscrit au dos de l'étoile que votre proche vous a remis en main propre.",
            "my_subscription_activate_form_modal_body_two": "<1>Envoyé par e-mail : </1>retrouvez votre code dans le corps de l’e-mail que vous avez reçu de notre part.",
            "my_subscription_activate_success_modal_heading": "Bravo, votre abonnement cadeau est activé !",
            "my_subscription_activate_success_modal_body": "Recevez {{amount}} crédit par mois pendant <1>{{duration}} mois</1> et téléchargez le livre audio de votre choix parmi notre catalogue. Les crédits non utilisés se cumulent sur la durée de votre abonnement.",
            "my_subscription_activate_success_modal_button_subscription": "Voir mon abonnement",
            "my_subscription_activate_success_modal_button_catalogue": "Voir le catalogue",
            "my_subscription_activate_form_modal_warning": "Pour activer votre abonnement cadeau, veuillez d’abord renseigner votre adresse de facturation dans votre compte.",
            "my_subscription_activate_form_error_default": "Une erreur est apparue, veuillez réessayer ultérieurement ou contacter le service client.",
            "my_subscription_action_activate_coupon": "Activer mon abonnement cadeau",
            "my_subscription_status_termination": "Abonnement Annulé",
            "my_subscription_status_withdrawal": "Abonnement Actif",
            "my_subscription_status_free": "Abonnement Cadeau",
            "my_subscription_sub_termination": "Arrêt prévu le",
            "my_subscription_sub_free": "Durée de l’abonnement cadeau",
            "my_subscription_sub_withdrawal": "Date de renouvellement",
            "my_subscription_sub_active": "Date de renouvellement",
            "my_subscription_sub_trialing": "Début de la facturation",
            "my_subscription_sub_type_free": "Fin de l’abonnement",
            "my_subscription_sub_type_termination": "Utilisez vos crédits jusqu’au",
            "my_subscription_warning_trialing": "Vous profitez actuellement de votre mois offert, qui prendra fin le {{date}}.",
            "my_subscription_modal_activate_coupon_title": "Vous avez déjà un abonnement en cours",
            "my_subscription_modal_activate_coupon_body_title": "Bonne nouvelle ! Vous pouvez quand même activer votre abonnement cadeau.",
            "my_subscription_modal_activate_coupon_body_1": "<1>Mise en pause de votre abonnement en cours</1>\nUne fois votre code activé, votre abonnement en cours sera immédiatement mis en pause.",
            "my_subscription_modal_activate_coupon_body_2": "<1>Activation de votre abonnement cadeau</1>\nRecevez un crédit par mois pendant 12 mois et choisissez le livre audio de votre choix.",
            "my_subscription_modal_activate_coupon_body_3": "<1>Reprise de votre abonnement antérieur</1>\nVotre abonnement antérieur reprendra normalement dès la fin de vos mois cadeau.",
            "my_subscription_modal_activate_coupon_body_warning": "Vous n’avez pas utilisé tous vos crédits ? Pas de panique, vos crédits sont cumulables sur toute la durée de votre abonnement si vous ne les utilisez pas.",
            "my_subscription_activate_success_message": "Vos mois d’abonnement cadeau sont actifs.",
            "my_subscription_number_of_months": "{{numberOfMonths}} mois",
            "my_subscription_tiles_free_subscription_warning": "<1>Votre abonnement {{reference}} mis en pause !</1>\nL’abonnement {{reference}} auquel vous aviez antérieurement souscrit reprendra automatiquement à la fin de votre abonnement cadeau.",
            "monthly_subscription": "mensuel",
            "annual_subscription": "annuel",
            "subscriptiongift_select_explain": "<1>Comment souhaitez-vous offrir votre cadeau ?</1><2>Pour activer son abonnement cadeau, le destinataire recevra un code d’une durée de validité d’un an à utiliser sur notre site.</2>",
            "subscriptiongift_select_choice_choice_by_hand": "Offrir mon cadeau en main propre",
            "subscriptiongift_select_choice_choice_by_email": "Envoyer mon cadeau par e-mail",
            "subscriptiongift_select_choice_view_by_hand": "<ol><li>Recevez par e-mail le code d’activation et votre activité manuelle à imprimer.</li><li>À vous de jouer ! Fabriquez votre étoile en 3D en suivant les instructions (atelier de 20 min).</li><li>Offrez l’abonnement Lunii en main propre !</li></ol>",
            "subscriptiongift_select_choice_view_by_email": "<ol><li>À vous de jouer ! Personnalisez votre message surprise !</li><li>Nous envoyons votre cadeau directement par e-mail à son destinataire !</li><li>Par sécurité, recevez également le code d’activation sur votre e-mail Lunii.</li></ol>",
            "fields_subscriptiongift_reciever_name_label": "Prénom du destinataire",
            "fields_subscriptiongift_reciever_name_placeHolder": "Lilou",
            "fields_subscriptiongift_reciever_email_label": "Adresse e-mail du destinataire",
            "fields_subscriptiongift_reciever_email_placeHolder": "papa.de.lilou@mail.fr",
            "fields_subscriptiongift_message_label": "Message de 200 caractères maximum",
            "fields_subscriptiongift_message_placeHolder": "Votre message ici",
            "fields_subscriptiongift_sender_name_label": "Votre nom",
            "fields_subscriptiongift_sender_name_placeHolder": "Mamoune",
            "subscriptiongift_form_fieldset_title_reciever": "Destinataire",
            "subscriptiongift_form_fieldset_title_message": "Ajoutez un petit mot <1>(facultatif)</1>",
            "subscriptiongift_form_fieldset_title_sender": "De la part de",
            "subscriptiongift_form_fieldset_title_sendingdate": "Programmez une date d'envoi <br/><1>Le code d'activation sera envoyé à son destinataire à la date choisie ci-dessous.</1>",
            "subscriptiongift_navigate_next": "Étape suivante",
            "subscriptiongift_navigate_previous": "Précédent",
            "subscriptiongift_navigate_pay": "Payer",
            "go_back": "Retour",
            "subscripitongift_recap_by_email": "Envoi du cadeau au destinataire par e-mail",
            "subscripitongift_recap_by_hand": "Remise du cadeau en main propre",
            "subscriptiongift_metadatalist_senderName": "De la part de",
            "subscriptiongift_metadatalist_receiverEmail": "Adresse mail",
            "subscriptiongift_metadatalist_receiverName": "Destinataire",
            "subscriptiongift_metadatalist_message": "Votre petit mot",
            "subscriptiongift_metadatalist_sendingDate": "Date d'envoi",
            "singleflapaccordion_less": "Voir moins",
            "singleflapaccordion_more": "Voir plus",
            "subscriptiongift_checkbox_terms": "J’ai lu et accepté <1>les conditions générales de vente</1>.",
            "subscriptiongift_checkbox_franceonly": "J’ai bien compris que je ne peux pas offrir l’abonnement à un proche résidant hors France métropolitaine, Belgique, Suisse, Luxembourg ou Monaco.",
            "subscriptiongift_title_step_1": "Offrir un abonnement",
            "subscriptiongift_title_step_2": "Renseignez votre mode de paiement",
            "subscriptiongift_title_step_3": "Merci pour votre achat ! </br> <1>Nous souhaitons à vos proches de beaux moments d'écoute !</1>",
            "subscriptiongift_confirmation_content_by_hand": "<title>Vous y êtes presque !</title><list><el>Recevez par e-mail le code d’activation et votre activité manuelle à imprimer</br><subel>Vérifiez votre boîte mail ! Vous allez recevoir un e-mail contenant le code d’activation et le patron de l’étoile à confectionner en 3D !</subel></el><el>À vous de jouer ! Fabriquez votre étoile en 3D en suivant les instructions</br><subel>Lancez-vous dans un atelier créatif d’une vingtaine de minutes pour créer le plus beau des cadeaux !</subel></el><el>Offrez l’abonnement Lunii !</br><subel>Inscrivez le code au dos de votre étoile et offrez votre cadeau en main propre. Ce code devra être activé depuis l’espace compte Lunii.</subel></el></list>",
            "subscriptiongift_confirmation_content_by_email": "<title>Et maintenant ?</title><list><el>Nous envoyons votre cadeau directement par e-mail</br><subel>Le destinataire de votre cadeau recevra son code d’activation d’abonnement par e-mail, à la date que vous avez ultérieurement choisie. Ce code doit être activé depuis son compte Lunii.</subel></el><el>Par sécurité, vous recevrez également le code sur votre e-mail Lunii</br><subel>En cas de perte du code, pas de panique ! Le code d’activation vous est également envoyé sur votre e-mail Lunii.</subel></el></list>",
            "step": "Étape",
            "subscriptiongift_select_choice_view_form_title": "Personnalisez votre message",
            "subscriptiongift_select_choice_view_error": "Le formulaire comporte des erreurs.",
            "subscriptiongift_form_reciever_name_field_error": "Vous devez remplir le nom du destinataire.",
            "subscriptiongift_form_reciever_email_field_error": "Vous devez remplir l'adresse e-mail du destinataire.",
            "subscriptiongift_form_sender_name_field_error": "Vous devez indiquer le nom de l'expéditeur.",
            "subscriptiongift_form_has_errors": "Le formulaire comporte des erreurs.",
            "subscriptiongift_billing_address_title": "Votre adresse de facturation",
            "subscriptiongift_paymentform_title": "Mode de paiement",
            "subscription_advert_offer_title": "Avec l’abonnement Lunii, jusqu'à -50% sur vos livres audio !",
            "subscription_advert_offer_text": "Chaque mois, téléchargez et écoutez le livre audio interactif de votre choix sur Ma Fabrique à Histoires.",
            "my_subscription_modal_action_activate_coupon": "Activer mon abonnement",
            "subscriptiongift_form_message_defaultvalue": "En cadeau, je t’offre de belles histoires à choisir chaque mois pour que tu puisses rêver et t’émerveiller avec ta Fabrique à Histoires.",
            "footer_newsletter_title": "Vous souhaitez connaître l'actualité Lunii et bénéficier d'une réduction de -10% ?",
            "footer_newsletter_subtitle": "Inscrivez-vous à la newsletter pour recevoir toutes les dernières actualités de Lunii et une réduction de 10% à utiliser sur notre catalogue de livres audio !",
            "my_subscription_sub_type_monthly_pricing": "{{price}} / mois",
            "my_subscription_sub_type_annual_pricing": "{{price}} / an",
            "subscription_product_annual_subscription_2023_pretitle_free_month": "Annuel",
            "subscription_product_annual_subscription_2023_title_free_month": "{{price}} / an",
            "subscription_product_annual_subscription_2023_subtitle_free_month": "soit {{monthlyPrice}} / mois",
            "subscription_product_annual_subscription_2023_pretitle": "Annuel",
            "subscription_product_annual_subscription_2023_title": "{{price}} / an",
            "subscription_product_annual_subscription_2023_body": "• 1 livre audio au choix chaque mois\n• {{price}} / an (soit {{monthlyPrice}} / mois)\n• Engagement d’un an renouvelable\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_recap_title_annual_subscription_2023": "Votre abonnement annuel",
            "subscription_recap_subtitle_annual_subscription_2023": "{{price}} / an",
            "subscription_recap_startdate_caption_annual_subscription_2023": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_annual_subscription_2023": "Début de la facturation :",
            "subscription_product_monthly_subscription_2023_pretitle_free_month": "Mensuel",
            "subscription_product_monthly_subscription_2023_title_free_month": "1 mois offert",
            "subscription_product_monthly_subscription_2023_subtitle_free_month": "puis {{price}} / mois",
            "subscription_product_monthly_subscription_2023_body": "• 1 livre audio au choix chaque mois\n• {{annualPrice}} / an ({{price}} tous les mois)\n• Sans engagement, résiliez à tout moment\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_monthly_subscription_2023_pretitle": "Mensuel",
            "subscription_product_monthly_subscription_2023_title": "{{price}} / mois",
            "subscription_recap_title_monthly_subscription_2023": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription_2023": "{{price}} / mois",
            "subscription_recap_startdate_caption_monthly_subscription_2023": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_monthly_subscription_2023": "Début de la facturation :",
            "subscription_recap_title_monthly_subscription_2023_free_month": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription_2023_free_month": "1 mois offert puis {{price}} / mois",
            "subscription_recap_startdate_caption_monthly_subscription_2023_free_month": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_monthly_subscription_2023_free_month": "Début de la facturation :",
            "my_code_album_modal_body_account": "Connecté(e) en tant que <1>{{email}}</1>.",
            "my_subscription_sub_past_due": "Prochain prélèvement",
            "my_subscription_past_due": "Échec",
            "my_subscription_status_past_due": "Abonnement gelé",
            "my_subscription_tiles_past_due_subscription_warning": "<1>Votre dernier paiement a échoué et votre abonnement a été gelé. Pour le réactiver, actualisez votre mode de paiement.</1>\nSans action de votre part, votre abonnement sera automatiquement supprimé à date du {{date}}.",
            "my_subscription_tiles_unpaid_subscription_warning": "<1>Votre dernier paiement a échoué et votre abonnement a été gelé. Pour le réactiver, actualisez votre mode de paiement.</1>\nSans action de votre part, votre abonnement sera automatiquement supprimé à date du {{date}}.",
            "my_subscription_status_unpaid": "Abonnement gelé",
            "my_subscription_unpaid": "Échec",
            "my_subscription_sub_unpaid": "Prochain prélèvement",
            "my_subscription_activate_unable_modal_title": "Oups, vous ne pouvez pas activer votre abonnement cadeau !",
            "my_subscription_activate_unable_modal_body": "Pour activer votre abonnement cadeau, régularisez la situation de votre abonnement en cours.",
            "my_subscription_activate_unable_modal_button": "J’ai compris",
            "subscription_widget_warning_past_due": "Actualisez votre mode de paiement pour réactiver votre abonnement. <1>Vos crédits restent utilisables.</1>",
            "subscription_widget_warning_unpaid": "Actualisez votre mode de paiement pour réactiver votre abonnement. <1>Vos crédits restent utilisables.</1>",
            "my_library_error_no_product": "<1>Oups, cette fiche produit est indisponible</1> La fiche produit de ce livre audio n’est plus accessible car ce livre audio n’est plus disponible à la vente. Rassurez-vous, vous pouvez toujours le transférer sur votre appareil.",
            "my_library_error_no_product_confirm": "J'ai compris",
            "digital_album_find_audiobook_warning_title": "<1>Où retrouver votre livre audio ?</1>\nAprès achat, votre livre audio sera transféré dans votre bibliothèque. Elle est accessible depuis notre site web, l'application mobile Lunii ou le Luniistore.",
            "product_author_male": "Auteur",
            "product_author_female": "Autrice",
            "product_teller_male": "Conteur",
            "product_teller_female": "Conteuse",
            "product_translator_male": "Traducteur",
            "product_translator_female": "Traductrice",
            "ecommerce_hardware_lcase_01_ll_ver_description": "Couleur : vert iconique",
            "ecommerce_hardware_lcase_01_ll_ora_description": "Couleur : orange tonique",
            "ecommerce_hardware_lcase_01_ll_jau_description": "Couleur : jaune électrique",
            "library_empty_studio_title": "Enregistrez vos propres histoires à écouter sur Ma Fabrique à Histoires !",
            "library_empty_studio_description": "Commencez dès maintenant à enregistrer vos propres histoires dans l'onglet Studio de l’application mobile Lunii.",
            "library_empty_studio_cta": "Découvrir l’application",
            "fah_model": "Modèle",
            "fah_model_desc_v1": "Pour transférer vos livres audio vers Ma Fabrique à Histoires : \n• Téléchargez l’application pour ordinateur, connectez-vous et branchez Ma Fabrique à Histoires avec un câble USB adapté.\n• Ou téléchargez l’application mobile, connectez-vous et reliez Ma Fabrique à Histoires à votre smartphone avec un câble adapté (uniquement Android). <devComponent/>",
            "fah_model_desc_v2": "Pour transférer vos livres audio vers Ma Fabrique à Histoires : \n• Téléchargez l’application pour ordinateur, connectez-vous et branchez Ma Fabrique à Histoires avec un câble USB adapté.\n• Ou téléchargez l’application mobile, connectez-vous et reliez Ma Fabrique à Histoires à votre smartphone avec un câble adapté (uniquement Android). <devComponent/>",
            "fah_model_desc_v3": "Téléchargez l’application mobile et connectez-vous pour transférer vos livres audio vers Ma Fabrique à Histoires via Wi-Fi.",
            "product_buy": "Acheter",
            "library_subtitle": "Pour transférer vos livres audio, identifiez l’application à utiliser selon le modèle de votre produit. <devComponent/>",
            "library_subtitle_link": "Quelle application utiliser ?",
            "my_fahs_bottom_no_empty_bottom_link": "Comment associer Ma Fabrique à Histoires ?",
            "fah_model_version_v1": "LUNII-1",
            "fah_model_version_v2": "LUNII-2",
            "fah_model_version_v3": "LUNII-3",
            "fah_modal_support_link": "Quel câble utiliser ? (FAQ)",
            "my_fahs_no_fahs": "Pour associer Ma Fabrique à Histoires à votre compte, rendez-vous sur l'application pour ordinateur ou l’application mobile Lunii.",
            "my_fahs_no_fahs_link": "Comment associer mon appareil audio ?",
            "ecommerce_hardware_lunii_03_fr_std_description": "Pour les enfants à partir de 3 ans",
            "ecommerce_hardware_lunii_03_fr_bio_description": "Pour les enfants à partir de 6 ans",
            "device_target_tag_fah": "Pour la Fabrique",
            "device_target_tag_flam": "Pour FLAM",
            "device_target_tag_fah_flam": "Pour la Fabrique et FLAM",
            "my_account_devices_title": "Mes appareils audio",
            "my_account_devices_subtitle": "Gérer mes Fabriques à Histoires et FLAM associés, numéros de série, modèles...",
            "my_subscription_discover_infos_text": "<1>Obtenez chaque mois un nouveau crédit à échanger contre le livre audio de votre choix</1><1>Cumulez vos crédits si vous ne les utilisez pas tous immédiatement</1><1>Choisissez vos livres audio dans le catalogue 3-7 ans ou 7-11 ans</1><1>Transférez vos livres audio sur Ma Fabrique à Histoires ou sur le baladeur FLAM</1>",
            "my_subscription_discover_infos_subtitle": null,
            "my_devices_no_devices": "Pour associer Ma Fabrique à Histoires ou FLAM à votre compte, rendez-vous sur l'application pour ordinateur ou l’application mobile Lunii.",
            "my_account_subscription_infos": "Échangez vos crédits contre les livres audio de votre choix dans le catalogue 3-7 ans ou 7-11 ans.",
            "my_devices_bottom_note": "Vous pouvez associer jusqu’à 3 conteuses Ma Fabrique à Histoires et 3 baladeurs FLAM à votre compte.",
            "my_devices_bottom_no_empty_bottom_link": "Comment associer un appareil audio ?",
            "fields_devicename_label": "Nom de l'appareil",
            "fields_devicename_placeholder": "Mon appareil audio",
            "device_model_desc_v1": "Pour transférer vos livres audio vers Ma Fabrique à Histoires : \n• Téléchargez l’application pour ordinateur, connectez-vous et branchez Ma Fabrique à Histoires avec un câble USB adapté.\n• Ou téléchargez l’application mobile, connectez-vous et reliez Ma Fabrique à Histoires à votre smartphone avec un câble adapté (uniquement Android). <devComponent/>",
            "device_model_desc_v2": "Pour transférer vos livres audio vers Ma Fabrique à Histoires : \n• Téléchargez l’application pour ordinateur, connectez-vous et branchez Ma Fabrique à Histoires avec un câble USB adapté.\n• Ou téléchargez l’application mobile, connectez-vous et reliez Ma Fabrique à Histoires à votre smartphone avec un câble adapté (uniquement Android). <devComponent/>",
            "device_model_desc_v3": "Téléchargez l’application mobile et connectez-vous pour transférer vos livres audio vers Ma Fabrique à Histoires via Wi-Fi.",
            "device_model_desc_da7": "Téléchargez l’application mobile et connectez-vous pour transférer vos livres audio vers FLAM via Wi-Fi.",
            "device_firmware": "Logiciel interne",
            "device_model": "Modèle",
            "device_model_version_v1": "LUNII-1",
            "device_model_version_v2": "LUNII-2",
            "device_model_version_v3": "LUNII-3",
            "device_model_version_da7": "LUN7P-1",
            "my_device_v1_modal_title": "Ma Fabrique à Histoires",
            "my_device_v2_modal_title": "Ma Fabrique à Histoires",
            "my_device_v3_modal_title": "Ma Fabrique à Histoires",
            "my_device_da7_modal_title": "FLAM",
            "my_library_subtitle": "Pour écouter vos livres audio, transférez-les sur <component/> ou <component2/> grâce à notre application mobile ou pour ordinateur.",
            "flam_device_associate_link": "Comment associer mon baladeur FLAM ?",
            "fah_device_associate_link": "Comment associer Ma Fabrique à Histoires ?",
            "subscription_product_monthly_subscription_2023_body_new": "• {{price}} mensuel (soit 12 mois pour {{annualPrice}})\n<br>• 1 livre audio au choix chaque mois\n<br>• À choisir dans le catalogue 3-7 ans ou 7-11 ans\n<br>• Engagement d’un mois automatiquement renouvelable",
            "subscription_product_annual_subscription_2023_body_new": "Économisez 2 mois par rapport à l’offre mensuelle\n<br>• {{price}} / an (soit {{monthlyPrice}} / mois)\n<br>• 1 livre audio au choix chaque mois\n<br>• À choisir dans le catalogue 3-7 ans ou 7-11 ans\n<br>• Engagement d’un an automatiquement renouvelable",
            "subscription_product_monthly_subscription_2023_title_free_month_new": "1 mois offert*",
            "subscription_product_monthly_subscription_2023_bottom_note_free_month": "*Offre réservée aux nouveaux abonnés",
            "subscription_product_annual_subscription_2023_title_free_month_new": "{{price}} / an",
            "my_devices_no_flam_link": "Associer mon baladeur FLAM",
            "my_devices_no_fah_link": "Associer Ma Fabrique à Histoires",
            "my_device_fah": "Ma Fabrique à Histoires",
            "my_device_flam": "FLAM",
            "catalog_fah": "Livres audio 3-7 ans",
            "catalog_flam": "Livres audio 7-11 ans",
            "my_empty_fah_audiobooks_title": "Votre bibliothèque semble bien vide... pour le moment !",
            "my_empty_fah_studio_title": "Enregistrez vos propres histoires à écouter sur Ma Fabrique à Histoires !",
            "my_empty_flam_audiobooks_title": "Votre bibliothèque semble bien vide... pour le moment !",
            "my_empty_flam_studio_title": "Le Studio n’est pas disponible pour FLAM.",
            "my_empty_fah_audiobooks_description": "C’est ici que vous retrouverez tous les livres audio achetés pour Ma Fabrique à Histoires.",
            "my_empty_fah_studio_description": "Commencez dès maintenant à enregistrer vos propres histoires dans l'onglet Studio de l’application mobile Lunii.",
            "my_empty_flam_audiobooks_description": "C’est ici que vous retrouverez tous les livres audio achetés pour FLAM.",
            "my_empty_fah_audiobooks_action": "Découvrir le catalogue 3-7 ans",
            "my_empty_fah_studio_action": "Découvrir l’application",
            "my_empty_fah_wishlist_title": "Votre liste de souhaits semble bien vide... pour le moment !",
            "my_empty_flam_wishlist_title": "Votre liste de souhaits semble bien vide... pour le moment !",
            "my_empty_fah_wishlist_description": "C’est ici que vous retrouverez tous les livres audio mis de côté pour Ma Fabrique à Histoires.",
            "my_empty_flam_wishlist_description": "C’est ici que vous retrouverez tous les livres audio mis de côté pour FLAM.",
            "my_empty_fah_wishlist_action": "Découvrir le catalogue 3-7 ans",
            "checkout_confirmation_next_steps_subtitle1": "Retrouvez immédiatement vos livres audio achetés dans votre Bibliothèque",
            "checkout_confirmation_next_steps_text1": "Pour transférer vos histoires sur votre appareil audio, rendez-vous sur l’application Lunii pour ordinateur ou sur mobile.",
            "checkout_confirmation_next_steps_subtitle2": "Livraison prévue de Ma Fabrique à Histoires, FLAM ou de vos accessoires Lunii sous 3 à 5 jours ouvrés",
            "checkout_confirmation_next_steps_text2": "Surveillez votre boîte mail pour être tenu informé de l’expédition de votre colis.",
            "checkout_confirmation_next_steps_subtitle3": "Découvrez notre catalogue de livres audio pour les 3-7 ans et les 7-11 ans",
            "checkout_confirmation_next_steps_text3": "Retrouvez des centaines d’histoires à écouter sur Ma Fabrique à Histoires et FLAM pour développer l’imaginaire, la culture et le vocabulaire des enfants.",
            "merge_account_warning_modal_body_text_fah": "Ces livres audio sont à écouter sur <1>Ma Fabrique à Histoires</1>.",
            "merge_account_warning_modal_flam_title": "Vous n’avez pas de baladeur FLAM associé à votre compte.",
            "merge_account_warning_modal_body_text_flam": "Ces livres audio sont à écouter uniquement sur le baladeur <1>FLAM</1>.",
            "merge_account_warning_modal_fah_flam_title": "Vous n’avez pas de Fabrique à Histoires ou de baladeur FLAM associés à votre compte.",
            "merge_account_warning_modal_body_text_fah_flam": "Ces livres audio sont à écouter sur <1>Ma Fabrique à Histoires</1> ou sur le baladeur <1>FLAM</1>.",
            "my_empty_flam_wishlist_action": "Découvrir le catalogue 7-11 ans",
            "my_empty_flam_audiobooks_action": "Découvrir le catalogue 7-11 ans",
            "product_subscription_modal_disclaimer_fah": "Le livre audio sera directement ajouté à ma bibliothèque. J’accepte que mon droit de rétraction légal prenne fin dans 14 jours ou dès que je commence le téléchargement de ce titre sur Ma Fabrique à Histoires, selon ce qui se produit en premier.",
            "product_subscription_modal_disclaimer_flam": "Le livre audio sera directement ajouté à ma bibliothèque. J’accepte que mon droit de rétraction légal prenne fin dans 14 jours ou dès que je commence le téléchargement de ce titre sur FLAM, selon ce qui se produit en premier.",
            "product_subscription_modal_success_fah": "Rendez-vous sur l’application pour ordinateur ou l’application mobile pour transférer votre livre audio sur votre Fabrique à Histoires.",
            "product_subscription_modal_success_flam": "Rendez-vous sur l’application mobile pour transférer votre aventure audio sur FLAM.",
            "product_credits_modal_title": "Crédits",
            "button_label_close": "Fermer",
            "product_subscription_title": "Avec l’abonnement Lunii, choisissez chaque mois un nouveau livre audio à petit prix",
            "product_description_button_label": "Découvrir l'abonnement",
            "product_credits_modal_link": "Afficher les crédits",
            "product_stories_modal_link": "Découvrez les {{stories}} histoires",
            "checkout_cart_product_info_cal-avent-22": "Attention : le livre audio “Les lettres au Père Noël” est la même édition que l'année dernière",
            "product_subscription_modal_success_fah_flam": "Rendez-vous sur l’application pour ordinateur ou l’application mobile pour transférer votre livre audio sur votre Fabrique à Histoires ou sur l’application mobile pour transférer votre aventure audio sur FLAM.",
            "common_error_message": "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le service client.",
            "error_cart_discounts_common_error_gift_card": "Impossible d'utiliser un code de réduction, le panier contient une carte-cadeau.",
            "page_404_title": "Oups ! Cette page n’existe pas…",
            "page_404_text": "Le labyrinthe est si grand qu’il semblerait que vous soyez perdus... \nPas de panique, nos héros sont déjà en route pour vous aider !",
            "page_404_button": "Retourner à la page d’accueil",
            "promotion_cap_text": "Le montant de la réduction est plafonné à {{amount}}",
            "global_error_message": "Nous rencontrons actuellement une difficulté technique et vous prions de nous excuser. Si le blocage persiste, merci de contacter le service client (erreur : {{error}}, statut : {{status}}).",
            "global_support_name": "service client",
            "ecommerce_hardware_lunii_03_fr_jol_description": "Pour les enfants à partir de 3 ans",
            "gift_card_balance_expired_balance": "Solde expiré",
            "gift_card_balance_expired_warning": "Votre carte cadeau a expiré à date du : <1>{{date}}</1>. Vous ne pouvez donc plus utiliser votre solde. Pour créditer à nouveau votre compte, ajoutez une nouvelle e-carte cadeau.",
            "capped_discount": "*Réduction plafonnée à {{amount}}",
            "discount_cumul_text": "La réduction actuellement active sur votre panier sera remplacée par la nouvelle réduction que vous souhaitez appliquer.",
            "discount_cumul_cta": "Appliquer mon nouveau code",
            "discount_cumul_title": "Vous ne pouvez pas cumuler plusieurs réductions",
            "cart_amount_details": "Voir le détail",
            "cart_amount_hide_details": "Masquer le détail",
            "discount_edit": "Modifier le code de réduction",
            "global_discount": "Réduction totale",
            "error_cart_discounts_code_over_usage_count": "Ce code a déjà été utilisé.",
            "amount_percent_cart": "{{amount}} sur le panier",
            "amount_percent_product": "{{amount}} sur “{{productName}}”",
            "my_subscription_cancel_from_past_due_modal_body": "Votre abonnement est actuellement gelé. Rassurez-vous, vous pouvez toujours utiliser vos crédits.\nSi vous confirmez l’annulation, votre abonnement sera immédiatement annulé et vos crédits seront perdus. <1>Utilisez donc vos crédits restants avant de confirmer l’annulation.</1>",
            "stripe_card_error_http_default": "Les informations de paiement sont actuellement indisponibles, veuillez réessayer plus tard.",
            "gift_card_balance_error_http_default": "Les informations concernant le solde carte cadeau sont actuellement indisponibles, veuillez réessayer plus tard.",
            "error_subscription_no_billing_infos_fr": "Vous essayez actuellement d’utiliser votre crédit depuis notre site français. Indiquez une adresse de facturation en France dans votre compte ou rendez-vous sur le site de votre pays.",
            "subscription_gift_offer_months_number": "Vous offrez un abonnement de {{months}} mois",
            "checkout_cart_header_total_amount": "Montant total : {{amount}}",
            "checkout_cart_header_shipping_amount": "Livraison sous {{description}} : <1>{{amount}}</1>",
            "checkout_cart_total_reduction": "Réduction totale : <1>{{amount}}</1>",
            "checkout_cart_warning_hardware": null,
            "checkout_cart_header_total_without_reducton": "Montant hors réduction : <1>{{amount}}</1>",
            "checkout_cart_header_total_account_balance": "Solde carte cadeau : <1>{{amount}}</1>",
            "error_cart_discounts_products_not_on_cart": "Votre panier ne contient pas le(s) produit(s) concerné(s) par ce code de réduction.",
            "error_cart_discounts_mixed_cart": "Nous ne pouvons pas appliquer cette promotion si votre panier contient à la fois des livres audio et des produits Lunii.",
            "checkout_cart_removed_items_shipping_not_allowed": "Oups, la livraison de ce produit n'est pas disponible dans cette région. Les articles concernés ont été automatiquement retirés de votre panier :",
            "checkout_cart_removed_items_out_of_stock": "Oups ! Certains produits sont actuellement en rupture de stock. Les articles concernés ont été automatiquement retirés de votre panier :",
            "checkout_cart_removed_items_pack_already_in_user_library": "Certains livres audio sont déjà présents dans votre bibliothèque. Les articles concernés ont été automatiquement retirés de votre panier :",
            "checkout_cart_removed_items_invalid_promotion": "Le code promo que vous avez tenté d'appliquer est invalide. Veuillez réessayer ultérieurement ou contacter notre service client pour obtenir de l'aide.",
            "checkout_cart_removed_items_invalid_promotion_code": "Le code promo que vous avez tenté d'appliquer est invalide. Veuillez réessayer ultérieurement ou contacter notre service client pour obtenir de l'aide.",
            "checkout_cart_removed_items_invalid_product": "Oups, certains produits sont introuvables ! Si le problème persiste, veuillez contacter notre service client.",
            "checkout_cart_removed_items_exclusive_locale_mismatch": "Certains livres audio ne sont pas disponibles dans votre pays. Les articles concernés ont été automatiquement retirés de votre panier :",
            "checkout_cart_removed_items_promotion_locale_mismatch": "Oups, cette promotion est invalide dans votre pays. Vérifiez que vous êtes sur le bon site pour en bénéficier.",
            "checkout_cart_removed_items_mixed_array": "Certains articles de votre panier ne sont pas éligibles à la promotion en cours. Cette promotion a été automatiquement retirée et ne s’applique plus sur votre panier.",
            "locale_fr_ma_label": "Mayotte",
            "error_subscription_no_billing_infos_be": "Vous essayez actuellement d’utiliser votre crédit depuis notre site belge. Indiquez une adresse de facturation en Belgique dans votre compte ou rendez-vous sur le site de votre pays.",
            "error_subscription_no_billing_infos_ch": "Vous essayez actuellement d’utiliser votre crédit depuis notre site suisse. Indiquez une adresse de facturation en Suisse dans votre compte ou rendez-vous sur le site de votre pays.",
            "error_subscription_no_billing_infos_lu": "Vous essayez actuellement d’utiliser votre crédit depuis notre site luxembourgeois. Indiquez une adresse de facturation au Luxembourg dans votre compte ou rendez-vous sur le site de votre pays.",
            "error_subscription_no_billing_infos_mc": "Vous essayez actuellement d’utiliser votre crédit depuis notre site monégasque. Indiquez une adresse de facturation à Monaco dans votre compte ou rendez-vous sur le site de votre pays.",
            "error_validating_cart_amount_too_low": "Oups, la commande ne peut pas être finalisée pour le moment. Le montant est inférieur au minimum requis, qui est de 50 centimes, veuillez modifier votre commande pour la finaliser.",
            "error_subscription_removed_item_exclusive_locale_mismatch_fr": "Ce livre audio n'est pas disponible dans votre pays. Vous ne pouvez donc pas l'acheter avec un crédit.",
            "error_subscription_removed_item_exclusive_locale_mismatch_be": "Ce livre audio n'est pas disponible dans votre pays. Vous ne pouvez donc pas l'acheter avec un crédit.",
            "error_subscription_removed_item_exclusive_locale_mismatch_ch": "Ce livre audio n'est pas disponible dans votre pays. Vous ne pouvez donc pas l'acheter avec un crédit.",
            "error_subscription_removed_item_exclusive_locale_mismatch_lu": "Ce livre audio n'est pas disponible dans votre pays. Vous ne pouvez donc pas l'acheter avec un crédit.",
            "error_subscription_removed_item_exclusive_locale_mismatch_mc": "Ce livre audio n'est pas disponible dans votre pays. Vous ne pouvez donc pas l'acheter avec un crédit.",
            "ecommerce_hardware_lunii_03_fr_vio_description": "Pour les enfants à partir de 3 ans",
            "ecommerce_hardware_lunii_03_fr_ble_description": "Pour les enfants à partir de 3 ans",
            "device_target_product_taxonomy_tag_fah": "La Fabrique uniquement",
            "device_target_product_taxonomy_tag_flam": "FLAM uniquement",
            "device_target_product_taxonomy_tag_fah_flam": "Ma Fabrique et FLAM",
            "device_target_product_taxonomy_header": "À écouter sur",
            "product_subscription_modal_disclaimer_fah_flam": "Le livre audio sera directement ajouté à ma bibliothèque. J'accepte que mon droit de rétraction légal prenne fin dans 14 jours ou dès que je commence le téléchargement de ce titre sur Ma Fabrique à Histoires ou sur FLAM, selon ce qui se produit en premier.",
            "ecommerce_hardware_lunii_03_fr_pat_description": "Pour les enfants à partir de 3 ans",
            "my_library_name_replace_redirect": "Voir la page de présentation",
            "my_library_name_replace_title": "Rendez-vous sur l’application mobile pour gérer votre livre audio",
            "my_library_name_replace_body": "La personnalisation de votre livre audio et la gestion de ses versions personnalisées s’effectuent exclusivement sur l’application mobile. <br/> ⚠️ N’oubliez pas que vous ne pourrez pas ajouter votre livre à votre Fabrique à Histoires sans l’avoir personnalisé."
        }
    },
    "it-IT": {
        "translation": {
            "navbar_login_label": "Connessione",
            "module_video_immersive_button_label": "Guarda il film",
            "navbar_search_placeholder": "Cerca nel catalogo",
            "cart_empty_text": "Il tuo carrello è vuoto",
            "cart_footer_items_gocart_label": "Vai al carrello",
            "cart_footer_items_mylist_label": "La mia lista",
            "cart_footer_items_myorders_label": "I miei ordini",
            "fields_city_label": "Città",
            "fields_country_label": "Paese",
            "fields_email_label": "Email",
            "fields_firstname_label": "Nome",
            "fields_lastname_label": "Cognome",
            "fields_message_label": "Messaggio",
            "fields_password_label": "Password",
            "fields_phone_label": "Telefono",
            "fields_recipient_label": "Destinatario",
            "fields_subject_label": "Oggetto",
            "fields_zipcode_label": "CAP",
            "fields_address_label": "Indirizzo",
            "fields_additionaladdress_label": "Indicazioni aggiuntive indirizzo",
            "fields_city_placeholder": "Es: Milano",
            "fields_country_placeholder": "Es: Italia",
            "fields_email_placeholder": "Es: io@lamiaemail.com",
            "fields_firstname_placeholder": "Es: Maria",
            "fields_lastname_placeholder": "Es: Rossi",
            "fields_message_placeholder": "Il suo messaggio qui",
            "fields_password_placeholder": "La sua password personale",
            "fields_phone_placeholder": "Es: 0102030405",
            "fields_recipient_placeholder": "Destinatario",
            "fields_subject_placeholder": "L'oggetto qui",
            "fields_zipcode_placeholder": "Es: 20019",
            "locale_fr_fr_label": "Francia (Francese)",
            "locale_it_it_label": "Italia (Italiano)",
            "label_quantity_shortened": "Quantità",
            "locale_fr_fr_product_language": "Francese",
            "locale_de_de_product_language": "Tedesco",
            "cart_go_checkout": "Vai al carrello ({{nbItems}})",
            "cart_other_item": "Ed un altro articolo...",
            "cart_other_item_plural": "E altri {{count}} articoli...",
            "constraints_password": "La password deve contenere almeno 8 caratteri",
            "constraints_email": "L'email deve essere nel formato mia@email.com",
            "catalog_no_result_title": "Nessun risultato",
            "catalog_no_result_content": "Purtroppo alcun risultato corrisponde alla sua ricerca",
            "catalog_results_for": "Risultato.i per",
            "catalog_menu_title": "Filtrare",
            "product_owned": "Già acquistato",
            "panels_login_title": "Connessione",
            "panels_login_description": "Che piacere rivederti!",
            "panels_forgotpassword_title": "Hai dimenticato la password?",
            "panels_forgotpassword_description": "Inserisci la tua e-mail per reimpostare la password.",
            "forms_login_buttons_forgotpassword_label": "Password dimenticata?",
            "forms_login_buttons_submit_label": "Connettiti",
            "forms_login_buttons_cancel_label": "Continua come invitato",
            "forms_login_buttons_register_label": "Iscriviti",
            "forms_login_buttons_register_text": "Non hai ancora un account?",
            "buttons_register_label": "Iscriviti",
            "forms_register_buttons_submit_label": "Iscriviti",
            "forms_register_buttons_alreadyregistered_text": "Già iscritto?",
            "forms_register_buttons_alreadyregistered_label": "Connettiti",
            "panels_register_title": "Iscriviti",
            "panels_register_description": "Ti diamo il benvenuto nella comunità di Lunii!",
            "catalog_reinitialize": "Reinizializzare",
            "catalog_finish": "Terminare",
            "forms_forgotpassword_buttons_submit_label": "Reimposta la password",
            "forms_forgotpassword_buttons_cancel_label": "Torna al login",
            "catalog_title": "Luniistore",
            "login_panel_title": "Connessione",
            "login_panel_description": "Siamo felici di rivederla!",
            "checkout_cart_gift_balance": "Il suo saldo carta regalo",
            "checkout_cart_gift_will_spend": "Sta per spendere",
            "checkout_cart_gift_will_stay": "Le resteranno",
            "checkout_cart_header_nb_items": "ARTICOLO.I",
            "checkout_cart_header_no_discount": "Sconto: 0,00 €",
            "checkout_cart_header_with_discount": "Sconto: Sta risparmiando",
            "checkout_cart_header_ex_vat": "Prezzo tasse escluse: <1>{{amount}}</1>",
            "checkout_cart_header_vat": "IVA: <1>{{amount}}</1>",
            "checkout_cart_header_title": "Il suo ordine",
            "checkout_cart_title": "Carrello",
            "checkout_payment_title": "Spedizione e pagamento",
            "checkout_login_title": "Connessione",
            "checkout_login_form_title": "Ho già un account Lunii",
            "checkout_shipping_and_purchase_title": "Spedizione e pagamento",
            "checkout_shipping_and_purchase_pay": "Pagare ({{price}})",
            "checkout_cart_total_free_shipping": "Gratuita",
            "checkout_cart_total_sub_total": "Subtotale",
            "checkout_cart_total_shipping_fee": "Consegna entro 3-5 giorni lavorativi",
            "checkout_cart_total_total_price": "Montante totale",
            "checkout_cart_total_discount": "Sconto",
            "checkout_shipping_and_purchase_withdrawal": "Accetto che il mio diritto di ritrattazione legale prenda fine tra 14 giorni o dal momento in cui inizio a scaricare il primo titolo di quest'ordine su La Fabbrica delle Storie, a seconda di quello che si verifica prima.",
            "checkout_shipping_and_purchase_cgv": "Ho letto ed accetto <1> le condizioni generali di vendita. </1>",
            "extract_card_info": "Estratto n°",
            "product_add_to_cart": "Aggiungi al carrello",
            "locale_nl_be_product_language": "Fiammingo",
            "locale_en_us_product_language": "USA (Inglese)",
            "locale_en_gb_product_language": "UK (Inglese)",
            "locale_it_it_product_language": "Italiano",
            "locale_es_es_product_language": "Spagnolo",
            "fields_promocode_label": "Il suo codice promozionale",
            "button_label_next": "Successivo",
            "fields_promocode_placeholder": "Il suo codice promozionale",
            "product_language_title": "Lingua",
            "product_duration_title": "Durata",
            "product_age_title": "Età",
            "product_type_title": "Tipo di audiolibro",
            "product_author": "Autore",
            "product_teller": "Narratore",
            "locale_ru_ru_product_language": "Russo",
            "product_recommendations_title": "Scopri anche",
            "product_recommendations_subtitle": "Se le piace questo audiolibro, abbiamo selezionato delle storie simili che potrebbero interessarle.",
            "product_reduction_content": "Risparmia",
            "newsletter_button_label_register": "Iscriviti",
            "promo_code_form_add_promo_code": "Aggiungi un codice promozionale",
            "fields_yourfirstname_label": "Il suo nome",
            "fields_newsletteroptin_label": "Il suo indirizzo email",
            "credit_card_form_reassurance_title": "Utilizziamo il servizio di pagamento sicurizzato Stripe",
            "credit_card_form_reassurance_body": "Quando comunica le sue informazioni di pagamento, i suoi dati sono protetti dalla tecnologia Secure Socket Layer (SSL), autenticata dal certificato numerico. <br/>\n<1>Per saperne di più</1>",
            "form_address_use_delivery_address": "Usa l'indirizzo della consegna",
            "newsletter_subtitle": "Iscriviti alla newsletter per ricevere tutte le novità Lunii: promozioni, nuovi audiolibri, eventi e molto altro ancora!",
            "newsletter_privacy_policy_text": "Ci impegniamo a non comunicare il tuo indirizzo e-mail a terzi.",
            "newsletter_privacy_policy_link_label": "Scopri la nostra Informativa sulla Privacy",
            "product_price_free": "Gratuito",
            "locale_fr_be_label": "Belgio (Francese)",
            "locale_fr_ch_label": "Svizzera (Francese)",
            "locale_fr_us_label": "USA (Francese)",
            "locale_es_us_label": "USA (Spagnolo)",
            "locale_fr_mc_label": "Monaco (Francese)",
            "locale_fr_lu_label": "Lussemburgo (Francese)",
            "locale_es_es_label": "Spagna (Spagnolo)",
            "locale_de_de_label": "Germania (Tedesco)",
            "locale_en_gb_label": "Inghilterra (Inglese)",
            "locale_en_us_label": "USA (Inglese)",
            "fields_giftcard_label": "Carta regalo",
            "checkout_shippingAddress_form_title": "Il suo indirizzo di consegna",
            "checkout_paymentMethod_form_title": "Metodo di pagamento",
            "checkout_billingAddress_form_title": "Indirizzo di fatturazione",
            "checkout_creditCard_form_title": "Carta di credito",
            "form_address_edit": "Modifica",
            "form_address_update_address": "Conferma la selezione",
            "form_address_cancel_edit": "Annulla",
            "productbar_buy_button_label": "Acquistare",
            "productbar_textline2": "Consegna tra i 3 e i 5 giorni",
            "country_fr_label": "Francia",
            "forms_forgotpassword_verify_inbox_text": "Se esiste già un account Lunii con questo indirizzo email, riceverà una mail con le istruzioni per reinizializzare la password.\n\nRicorda di controllare la cartella della posta indesiderata nella tua casella mail !",
            "checkout_confirmation_next_steps": "Prossime tappe",
            "checkout_confirmation_shipping_title": "La consegna!",
            "checkout_confirmation_shipping_link": "Vedi il monitoraggio dell'ordine",
            "checkout_confirmation_stories_title": "Le tue storie sono già nella tua biblioteca. Ritrova tutti gli audiolibri che hai acquistato nella tua biblioteca.",
            "checkout_confirmation_stories_text": "Ritrova tutti gli audiolibri che hai acquistato nella sua biblioteca.",
            "checkout_confirmation_download_title": "Scarica il Luniistore",
            "checkout_confirmation_download_text": "Il Luniistore è un'applicazione per computer che permette di aggiungere o togliere degli audiolibri su La fabbrica delle Storie.",
            "checkout_confirmation_download_btn": "Scarica il Luniistore",
            "checkout_confirmation_sync_title": "Trasferisci le tue storie",
            "checkout_confirmation_sync_text": "Collega la Fabbrica delle Storie al tuo computer e trasfrisci le tue storie grazie al Luniistore.",
            "checkout_confirmation_title": "La ringraziamo per l'acquisto!",
            "checkout_confirmation_description": "Le auguriamo dei bei momenti d'ascolto!",
            "checkout_cart_return": "Indietro",
            "cart_payment_loading": "Pagamento in corso",
            "checkout_confirmation_shipping_text": "Il tuo ordine dovrebbe arrivare a casa tua tra le <1/> e le <2/>.",
            "forms_register_newsletter_label": "Iscriviti alla newsletter",
            "forms_register_cgu_label": "Ho preso conoscenza ed accetto le",
            "my_account_title": "Il mio account",
            "my_account_user_information_title": "Le mie informazioni",
            "my_account_checkout_and_purchase_title": "Pagamento e fatture",
            "my_account_user_information_subtitle": "Nome, cognome, e-mail, password.",
            "my_account_checkout_and_purchase_subtitle": "Metodo di pagamento, indirizzo di fatturazione, cronologia dei suoi acquisti, indirizzo di consegna.",
            "my_account_connected_as": "Connettimi in quanto",
            "my_account_logout": "Disconnetti",
            "my_invoice_address_title": "Fatturazione",
            "my_delivery_address_title": "Indirizzo di consegna",
            "my_purchase_history_title": "Cronologia acquisti",
            "form_user_information_address_title": "Indirizzo e-mail",
            "form_user_information_address_note": "Contatti il supporto clienti per modificare l'indirizzo email del suo account Lunii.",
            "form_user_information_password_edit": "Modifica la mia password",
            "checkout_shippingMethods_form_title": "Modalità di spedizione",
            "forms_register_country_selection_text": "Seleziona la lingua e il Paese",
            "country_it_label": "Italia",
            "errors_login_bad_credentials": "La preghiamo di verificare la sua mail e la sua password, les sue credenziali non sono corrette.",
            "errors_unauthorized": "Al momento non è autorizzato a connettersi con questo conto, la preghiamo di riprovare e di contattare il servizio clienti se il problema persiste.",
            "errors_server_error": "Stiamo riscontrando un problema tecnico e le chiediamo di riprovare ulteriormente. Se il problema presiste, la preghiamo di contattare il servizio clienti.",
            "form_user_information_update": "Registrati",
            "form_user_information_cancel_edit": "Annulla",
            "form_user_information_edit": "Modifica",
            "catalog_menu_types": "Tipi di audiolibri",
            "catalog_menu_themes": "Tematiche",
            "catalog_menu_ages": "Età",
            "catalog_menu_langs": "Lingue straniere",
            "fields_cardowner_label": "Titolare della carta",
            "fields_cardowner_placeholder": "Titolare della carta",
            "cartmodal_success_message": "Ecco, è stato aggiunto!",
            "cartmodal_go_cart_button_label": "Vai al carrello",
            "cartmodal_buy_button_label": "Aggiungi al carrello",
            "cartmodal_continue_button_label": "Continuare la navigazione",
            "cartmodal_cancel_button_label": "Annulla",
            "cartmodal_linkedProducts_title": "Scopri anche...",
            "cartmodal_title": "Aggiungi questo prodotto",
            "product_pages_number_title": "Pagine",
            "product_format_title": "Formato",
            "product_illustrator": "Illustratore",
            "checkout_confirmation_catalog_btn": "Scopri il catalogo",
            "checkout_confirmation_free_album_title": "Recupera l'audiolibro Sogni d'Oro offerto",
            "checkout_confirmation_free_album_text": "Le offriamo 6 storie dolci e poetiche con degli esercizi di rilassamento da scaricare sul nostro sito o sull'applicazione per computer Luniistore.",
            "cart_empty_subtitle": "OH NO :(",
            "form_register_cgu_link": "condizioni di servizio",
            "errors_register_error_email": "Si è verificato un errore durante la creazione dell'account. Se ha già creato un account con questo indirizzo, può attivarlo cliccando sul link nella mail di attivazione.",
            "errors_forgot_password_error_email": "Si è verificato un errore durante la reimpostazione della password. Se l'indirizzo fornito è già stato utilizzato per creare un account, si prega di attivarlo cliccando sul link presente nell'e-mail di conferma.",
            "form_address_search_address": "Convalida il mio indirizzo",
            "form_address_search_address_select": "Seleziona il tuo indirizzo nella lista seguente",
            "form_address_search_address_not_found": "Il mio indirizzo non compare nella lista",
            "form_address_search_address_force": "Dichiaro di poter ricevere un pacco a questo indirizzo",
            "country_de_label": "Germania",
            "country_be_label": "Belgio",
            "country_lu_label": "Lussemburgo",
            "country_mc_label": "Monaco",
            "country_ch_label": "Svizzero",
            "country_gb_label": "Regno Unito",
            "country_ca_label": "Canada",
            "country_us_label": "Stati Uniti",
            "locale_es_mx_product_language": "Spagnolo (Messico)",
            "locale_en_ca_label": "Canada (Francese)",
            "locale_fr_ca_label": "Canada",
            "forms_register_data_label": "Acconsento al trattamento dei miei dati personali.",
            "form_address_search_address_force_billing": "Certifico che il mio indirizzo è corretto.",
            "form_address_search_address_extra_button": "Utilizzare l'indirizzo di consegna",
            "button_label_validate": "Convalidare",
            "error_cart_discounts_common_error": "Codice non valido",
            "button_label_cancel": "Annulla",
            "checkout_error_no_shipping_methods_available": "Nessun metodo di spedizione è stato trovato. Si prega di contattare il servizio clienti.",
            "newsletter_subscribe_success": "La tua iscrizione alla newsletter è andata a buon fine.",
            "newsletter_unsubscribe_success": "Ora sei disabbonato dalla newsletter.",
            "checkout_cart_excl_taxes": "Tasse escluse",
            "checkout_cart_total_discount_old_price": "Importo escluso lo sconto",
            "error_cart_discounts_code_already_used": "Stai già usando un codice",
            "panels_register_success_title": "Controlla la tua casella di posta elettronica.",
            "forms_register_verify_inbox_text": "Ti abbiamo inviato un'e-mail di convalida all'indirizzo che hai inserito. Devi convalidare la creazione del tuo account prima di continuare.",
            "fields_address2_label": "Indirizzo aggiuntivo",
            "checkout_cart_error_cart_infos_have_changed": "Le informazioni nel tuo carrello sono cambiate, per favore controlla le nuove informazioni prima di riprovare il pagamento.",
            "fields_address1_label": "Indirizzo",
            "form_register_alert_inbox_text": "Se non hai ricevuto nulla, tieni presente che l'e-mail potrebbe impiegare qualche minuto per arrivare. Ricordati di controllare la cartella dello spam.\nSe non hai ricevuto nulla dopo pochi minuti, prova a registrarti, facendo attenzione all'ortografia della tua e-mail.",
            "forms_register_locale_selection_text": "Seleziona la tua lingua e le opzioni del paese",
            "panels_forgotpassword_success_title": "Controlla la tua casella di posta elettronica.",
            "error_creating_payment_intent": "Si è verificato un errore con la tua carta. Il pagamento non ha avuto luogo.",
            "error_updating_cart_already_validated": "Il tuo carrello è già convalidato.",
            "payment_intent_authentication_failure": "Il tuo metodo di pagamento non può essere autenticato. Il pagamento non ha avuto successo.",
            "payment_validation_delivery_infos": "Devi inserire il tuo indirizzo di spedizione.",
            "payment_validation_billing_infos": "Devi inserire il tuo indirizzo di fatturazione.",
            "payment_validation_payment_method": "È necessario compilare le informazioni per il pagamento.",
            "payment_validation_terms": "Devi accettare i termini e le condizioni di vendita e la politica di recesso.",
            "product_discover": "Scoprire",
            "product_out_of_stock": "Questo articolo non è più disponibile",
            "register_special_info": null,
            "forms_register_data_infos": "I dati raccolti sono destinati ai servizi di Lunii e dei suoi fornitori per il trattamento degli ordini degli utenti e per le relazioni commerciali. In conformità alla legge \"Informatique et Libertés n°78-17 del 6 gennaio 1978\" relativa all'informatica, ai file e alle libertà, e al regolamento europeo 2016/679 del 27 aprile 2016 relativo ai dati personali, il cliente dispone di un diritto di accesso, di modifica, di correzione e di soppressione dei dati che lo riguardano. Lunii si impegna alla riservatezza e alla protezione dei dati degli utenti.",
            "checkout_paymentMethod_stripe": "Carta di credito",
            "checkout_paymentMethod_paypal": "PayPal",
            "passwordupdatemodal_title": "Modifica la password",
            "passwordupdatemodal_title_success": "Password modificata!",
            "passwordupdatemodal_success_message": "La password è stata modoficata con successo.",
            "passwordupdatemodal_close": "Chiudi",
            "fields_newpassword_label": "Nuova password",
            "fields_newpassword_placeholder": "La tua nuova password (min.8 caratteri)",
            "fields_newpasswordconfirmation_label": "Conferma la password",
            "fields_newpasswordconfirmation_placeholder": "Conferma la tua nuova password",
            "forms_updatepassword_buttons_submit_label": "Conferma",
            "errors_passwordupdate_user_not_validated": "Impossibile cambiare la password, il tuo account non è ancora stato confermato.",
            "errors_passwordupdate_password_does_not_match": "La tua password attuale non è valida.",
            "errors_passwordupdate_new_equals_to_old_password": "Non è possibile riutilizzare la stessa password.",
            "passwordupdatemodal_text_resetonlogin": "Hai appena effettuato l'accesso con una password temporanea. Inserisci la tua nuova password, sarai connesso automaticamente dopo aver confermato il modulo.",
            "catalog_no_result_refresh": "Scopri gli audiolibri",
            "checkout_paypal_infos": "Cliccando su \"Paga\", sarai reindirizzato a Paypal per finalizzare il tuo acquisto. Una volta che la transazione è stata convalidata, sarai automaticamente reindirizzato al sito web di Lunii.",
            "error_creating_payment_paypal": "Si è verificato un errore durante la creazione del pagamento presso il fornitore di pagamenti selezionato. Si prega di riprovare con un altro metodo di pagamento.",
            "card_declined": "La sua carta è stata rifiutata, la preghiamo di utilizzare un altro metodo di pagamento.",
            "card_number_in_name_field": "Assicuratevi che il campo \"Cardholder\" non contenga un numero di carta di credito.",
            "catalog_search_textfield_placeholder": "Cerca",
            "paypal": "PayPal",
            "cart_error_some_products_removed": "Uno o più prodotti sono stati rimossi dal tuo carrello perché sono esauriti o già presenti nella tua libreria: <br/> {{description}}",
            "gift_card_type_lunii": "Carta regalo Lunii di",
            "gift_card_expired_text": "Scade il",
            "gift_card_remove": "Annulla",
            "my_account_giftcard_title": "Carte regalo",
            "my_account_giftcard_subtitle": "Associa una carta regalo, lista delle vostre carte regalo",
            "mygiftcards_no_giftcards": "Non è stata registrata nessuna carta regalo al momento",
            "forms_addgiftcard_button_open_form": "Aggiungi una carta regalo",
            "forms_addgiftcard_buttons_submit_label": "Aggiungi",
            "forms_addgiftcard_buttons_cancel_label": "Annulla",
            "error_gift_card_cannot_add_gift_card_to_user": "Impossibile aggiungere la carta regalo che hai registrato al tuo account",
            "error_gift_card_cannot_remove_card_from_user": "Impossibile eliminare la carta regalo dal tuo account",
            "error_gift_card_cannot_retrive_card_from_hipay": "Il codice della carta regalo inserito non è riconosciuto",
            "gift_card_checkout_select": "Seleziona",
            "gift_card_checkout_unselect": "Deseleziona",
            "checkout_giftcard_title": "Carte regalo",
            "my_purchase_history_no_purchase": "Al momento non troviamo nessu acquisto registrato",
            "purchase_status_success": "Successo",
            "purchase_status_pending": "Pagamento non andato a buon fine",
            "purchase_status_failure": "Tentativo fallito",
            "cta_item_in_cart": "Vedi",
            "cta_item_to_buy": "Aggiungi",
            "cta_item_owned": "Già acquistato",
            "purchase_status_created": "Creato",
            "purchase_status_cancelled": "Cancellato",
            "purchase_status_refunded": "Rimborsato",
            "purchase_status": "Stato",
            "checkout_cart_amount_giftcard": "Carta regalo",
            "purchase_invoice_download_invoice": "Scarica la fattura",
            "purchase_invoice_no_invoice": "Nessuna fattura",
            "purchase_invoice_paper": "Fattura cartacea (nel pacco)",
            "checkout_payment_wait": "Pagamento in corso. Si prega di attendere...",
            "my_account_billing_and_invoices_title": "Informazioni sul pagamento",
            "my_account_billing_and_invoices_subtitle": "Indirizzi di fatturazione e consegna",
            "my_account_purchase_history_title": "Cronologia acquisti",
            "my_account_purchase_history_subtitle": "Cronologia degli acquisti e delle fatture",
            "purchase_invoice_subtitle_packs": "Audiolibro",
            "purchase_invoice_subtitle_hardware": "Prodotti",
            "push_sticky_close": "Chiudi",
            "form_address_search_no_address_found": "Non è stato trovato nessun risultato per la tua ricerca. Se, nonostante questo, vuoi continuare con l'indirizzo, clicca su",
            "library_title": "La Mia Biblioteca",
            "library_menu_luniistore": "Audiolibri Luniistore",
            "library_menu_studio": "Audiolibri Studio",
            "library_empty_title": "La tua Biblioteca sembra un po' vuota...",
            "library_empty_description": "Se hai già una Fabbrica delle Storie, scarica il Luniistore per salvare gli audiolibri che possiedi già e comprarne di nuovi!",
            "library_description": "Per trasferire gli audiolibri sulla tua Fabbrica delle Storie, accedi all'app per computer Luniistore.",
            "form_address_uneditable_country_tooltip": "La consegna è prevista esclusivamente nella nazione dello store sul quale stai facendo acquisti. Per cambiare paese, torna alla Home page e scegli lo store della nazione corretta in fondo alla pagina.",
            "fields_phone_format_error": "Il numero di telefono inserito non è valido.",
            "fields_phone_international_label": "Altro",
            "form_address_delivery_address_phone_explanation": "Si prega di fornire un recapito telefonico per essere contattati dal corriere in caso di bisogno.",
            "pack_assets_label": "attività",
            "checkout_bundle_next_page_warning": "Disponi di un'offerta speciale? L'eventuale sconto sarà calcolato nella pagina seguente.",
            "forms_forgotpassword_buttons_resend_label": "Invia di nuovo la mail di conferma",
            "forms_resend_validation_verify_inbox_text": "TI abbiamo inviato la mail di conferma",
            "error_resend_validation": "Si è verificato un errore durante l'invio dell'e-mail di conferma. Si prega di riprovare. Se l'errore persiste, si prega di contattare il servizio clienti.",
            "my_account_subscription_title": "Il mio abbonamento",
            "my_subscription_status_active": "Abbonamento attivo",
            "my_subscription_status_cancelled": "Abbonamento annullato",
            "my_subscription_sub_type_annual_subscription": "Annuale",
            "my_subscription_sub_type_monthly_subscription": "Mensile",
            "forms_updatepassword_confirmation_not_match_error": "Le password inserite non corrispondono",
            "error_card_declined": "La carta di credito è stata rifiutata, si prega di riprovare con un'altra modalità di pagamento",
            "checkout_cart_total_amount_giftcard": "Gift card",
            "error_gift_card_form_card_redeemed_or_expired": "La gift card inserita è già stata utilizzata o è stata superata la data di scadenza",
            "fields_state_label": "Stato / Provincia",
            "fields_state_placeholder": "Stato / Provincia",
            "forms_addcreditcard_button_open_form": "Memorizzare modalità di pagamento",
            "button_label_save": "Memorizzare",
            "credit_card_expired": "Scaduta",
            "credit_card_expire_date": "Scade il",
            "mycreditcards_no_creditcards": "Non hai ancora aggiunto una modalità di pagamento",
            "stripe_card_post_error_card_already_exists": "La carta inserita è già registrata",
            "stripe_card_delete_error_deleting_card": "Si è verificato un problema durante l'eliminazione della carta di pagamento.",
            "stripe_card_post_error_creating_card": "Si è verificato un problema durante la registrazione della carta di pagamento.",
            "stripe_cards_get_unable_to_retrieve_cards_for_user": "Si è verificato un problema durante il recupero delle informazioni legate alla carta di pagamento.",
            "my_account_creditcard_title": "Modalità di pagamento",
            "checkout_paymentMethod_paypal_content": "Al momento del pagamento, verrai reindirizzato su Paypal per finalizzare l'acquisto. Una volta avvenuta la transazione, sarai automaticamente reindirizzato al sito di Lunii.",
            "credit_card_form_register": "Memorizza più tardi",
            "checkout_cart_total_bundle_discount": "Promozione esclusiva",
            "field_required_error": "obbligatorio",
            "gift_card_balance_title": "Saldo gift card",
            "gift_card_balance_available_balance": "Saldo disponibile",
            "gift_card_balance_balance_infos": "Valido per un carrello contenente esclusivamente audiolibri.",
            "gift_card_balance_card_page_link": "Acquista una e-gift card",
            "gift_card_balance_warning_infos": "Acconsento al fatto che l'intero importo della gift card sia accreditato sul mio saldo disponibile, conformemente con termini e <devComponent/>",
            "gift_card_balance_terms": "condizioni legali.",
            "gift_card_balance_form_toggle_button": "Aggiungi una nuova gift card",
            "gift_card_balance_form_add_button": "Aggiungi",
            "gift_card_balance_form_cancel_button": "Annulla",
            "gift_card_balance_added_message": "La tua gift card è stata correttamente associata.",
            "gift_card_balance_error_unable_to_retrieve_account_balance": "Si è verificato un errore durante il recupero del saldo delle gift card, si prega di contattare il servizio clienti.",
            "gift_card_balance_error_gift_card_is_expired": "La gift card selezionata è scaduta.",
            "gift_card_balance_error_gift_card_is_invalid": "La gift card selezionata non è valida.",
            "checkout_paymentMethod_gift_card_full_cart": "Utilizza <1>{{price}}</1> del saldo di <1>{{balance}}</1> della tua gift card.",
            "checkout_paymentMethod_gift_card_exact_cart": "Utilizza la totalità di <1>{{balance}}</1> presenti sulla tua gift card",
            "checkout_paymentMethod_gift_card_not_allowed": "Per utilizzare il saldo di {{balance}} della tua gift card, il tuo ordine deve contenere esclusivamente audiolibri.",
            "checkout_paymentMethod_gift_card_left_to_pay": "Aggiungi una modalità di pagamento per coprire la somma di {{amount}} di differenza.",
            "checkout_payment_update": "Aggiornamento del carrello in corso",
            "checkout_cart_total_account_balance": "Saldo gift card Lunii",
            "gift_cards_visual_amount_proposal_1": "15",
            "gift_cards_visual_amount_proposal_2": "20",
            "gift_cards_visual_amount_proposal_3": "30",
            "gift_cards_visual_amount_proposal_4": "40",
            "fields_giftcardamount_custom_label": "Importo personalizzato (es. € 55)",
            "fields_giftcardreceivername_label": "Nome del fortunato destinatario",
            "fields_giftcardreceiveremail_label": "Indirizzo e-mail del destinatario",
            "constraints_too_many_characters": "Hai superato il numero di caratteri consentiti.",
            "gift_cards_receiver_no_receiver": "Desidero ricevere la gift card elettronica sul mio indirizzo e-mail per poterla consegnare fisicamente al destinatario",
            "gift_cards_message_title": "Aggiungi una breve dedica",
            "gift_cards_message_subtitle": "facoltativo",
            "gift_cards_message_warning": "Attenzione, una volta ordinata la gift card elettronica, questo messaggio non potrà più essere modificato.",
            "fields_giftcardmessage_label": "Messaggio di massimo 200 caratteri",
            "characters": "caratteri",
            "gift_cards_sender_title": "Da:",
            "fields_giftcardsendername_label": "Il tuo nome",
            "gift_cards_date_title": "Programma una data d'invio",
            "gift_cards_amount_title": "Scegli un importo",
            "gift_cards_visual_amount_infos": "I prezzi dei nostri audiolibri variano in base alla durata e alla categoria. Un audiolibro costa in media € 9,90.",
            "gift_cards_visual_receiver_infos": "Il destinatario riceverà via mail una gift card personalizzata con l'universo scelto. La carta è stampabile e potrà essere consegnata di persona.",
            "gift_cards_visual_title": "Seleziona un'immagine",
            "gift_cards_visual_infos": "Diverse grafiche disponibili, per soddisfare i gusti di tutti!",
            "gift_cards_summary_title": "Riepilogo",
            "gift_cards_summary_receiver": "Regalo per",
            "gift_cards_receiver_title": "Destinatario",
            "gift_cards_error_giftcardtheme": "Devi selezionare un tema",
            "gift_cards_error_giftcarddate": "Devi selezionare una data",
            "gift_cards_error_giftcardamount": "Devi selezionare un importo",
            "gift_cards_error_giftcardreceivername": "Devi inserire il nome del destinatario",
            "gift_cards_error_giftcardreceiveremail": "Devi inserire l'e-mail del destinatario",
            "gift_cards_error_giftcardsendername": "Devi inserire il tuo nome",
            "gift_cards_date_infos": "La gift card elettronica sarà inviata al destinatario nel giorno da te  selezionato. Il pagamento della gift card avverrà al momento della conferma dell'ordine.",
            "fields_date_label": "Seleziona una data",
            "date_field_error_giftcards_invaliddate": "Il formato della data non è valido",
            "date_field_error_giftcards_disablepast": "La data inserita non può essere precedente a quella odierna",
            "date_field_error_giftcards_maxdate": "La data inserita non può superare il mese a partire da oggi",
            "gift_cards_submit_infos": "Riceverai una mail entro pochi minuti",
            "gift_cards_invalid_form": "Il modulo presenta degli errori",
            "gift_cards_gift_in_cart_name": "Gift card elettronica",
            "gift_cards_gift_in_cart_for": "Per",
            "gift_cards_gift_in_cart_from": "Da",
            "gift_cards_gift_in_cart_message": "Messaggio",
            "gift_cards_gift_in_cart_date": "Data d'invio",
            "checkout_payment_get_cart": "Recupero del carrello in corso",
            "my_account_giftcard_balance_title": "Saldo gift card",
            "gift_card_balance_availability": "Il saldo delle tue gift card ha validità di un anno a partire dalla data di attivazione dell'ultima gift card aggiunta al tuo saldo.",
            "error_validating_cart_error_on_validate": "Il codice sconto è già stato utilizzato sul vostro conto.",
            "my_account_giftcard_balance_subtitle": "Consulta il saldo, aggiungi una carta regalo",
            "legacy_gift_card_modal_title": "Desideri trasferire l'importo delle gift card sul tuo saldo disponibile?",
            "legacy_gift_card_modal_body": "Lunii ha modificato il sistema di gestione delle gift card inserendo un unico saldo (costituito dalla somma degli importi delle tue gift card). Avevi delle gift card già registrate sulla nostra piattaforma e vuoi trasferirle sul tuo saldo disponibile virtuale? Potrai decidere di utilizzarlo per pagare i tuoi nuovi audiolibri.",
            "legacy_gift_card_validate_button": "Trasferisci l'importo",
            "gift_card_merge_error_unable_to_merge": "Si è verificato un errore durante il trasferimento, si prega di contattare il servizio clienti.",
            "my_account_fahs_title": "Mie Fabbriche delle Storie",
            "my_fahs_bottom_note": "Puoi associare fino a 3 Fabbriche delle Storie al tuo account.",
            "my_fahs_fah_modal": "Mia Fabbrica delle Storie",
            "char_count": "caratteri",
            "fields_fahname_label": "Nome della Fabbrica delle Storie",
            "fields_fahname_placeholder": "Fabbrica delle Storie",
            "fields_fahserial_label": "N° di serie",
            "fields_fahserial_placeholder": "N° di serie di 10 o 14 cifre",
            "fah_firmware": "Software interno",
            "my_fahs_fah_modal_cancel": "Annullare",
            "my_fahs_fah_modal_confirm": "Salvare le modifiche",
            "my_fahs_no_fah": "Per collegare la Fabbrica delle Storie al tuo account Lunii, scarica l'applicazione <1>Luniistore</1> sul tuo computer, aprila e collega la tua Fabbrica delle Storie.",
            "date_format": "GG/MM/AAAA",
            "gift_card_balance_expiration_date": "Data di scadenza del saldo: {{date}}",
            "error_fah_update_retrieve_fah": "Impossibile trovare la Fabbrica indicata; si prega di aggiornare la pagina e riprovare.",
            "error_fah_update_name": "Impossibile aggiornare il nome della Fabbrica indicata.",
            "error_fah_update_serial": "Impossibile aggiornare il numero di serie della Fabbrica indicata.",
            "constraints_fahserial": "Il nome è obbligatorio e deve essere compreso tra 1 e 40 caratteri.",
            "constraints_fahname": "Il numero di serie deve essere compreso tra 10 e 14 caratteri.",
            "incorrect_cvc": "Il crittogramma visivo non è valido.",
            "expired_card": "La carta di credito è scaduta.",
            "processing_error": "Si è verificato un errore in fase di pagamento. Si prega di riprovare. Se l'errore persiste, si prega di riprovare più tardi.",
            "incorrect_number": "Il numero della carta non è valido.",
            "error_subscription_server": "Si è verificato un errore in fase di elaborazione dell'acquisto. Non è stato effettuato nessun addebito. Si prega di riprovare più tardi.",
            "error_subscription_not_enough_credits": "Il tuo acquisto non è andato a buon fine per mancanza di credito sul conto.",
            "error_subscription_unlisted": "Si è verificato un errore durante l'elaborazione della tua richiesta.",
            "seo_title_suffix_digital_album": "Storie audio | Lunii",
            "locale_en_nl_label": "Paesi Bassi (Inglese)",
            "country_nl_label": "Paesi Bassi",
            "ecommerce_hardware_included_title": "Incluso nell'offerta",
            "audio_player_play": "Estratto",
            "audio_player_pause": "Pausa",
            "error_validate_codealbum_unable_to_retrieve_code": "Il codice non è valido.",
            "error_validate_codealbum_max_usage_count_reached": "Questa offerta è scaduta",
            "error_validate_codealbum_unable_to_retrieve_offer": "Errore del server",
            "error_validate_codealbum_code_not_active": "Al momento il codice non risulta attivo.",
            "error_validate_codealbum_code_not_yet_active": "Il codice non è ancora valido.",
            "error_validate_codealbum_code_expired": "Il codice è scaduto.",
            "error_validate_codealbum_no_available_products": "Possiedi già tutti i prodotti ottenibili con questo codice.",
            "error_validate_codealbum_code_already_used": "Hai già utilizzato questa offerta.",
            "error_activate_codealbum_unable_to_retrieve_code": "Il codice non è valido.",
            "error_activate_codealbum_max_usage_count_reached": "Questo codice è già stato utilizzato.",
            "error_activate_codealbum_unable_to_retrieve_offer": "Non è stato possibile trovare l'offerta associata a questo codice libro.",
            "error_activate_codealbum_code_not_active": "Al momento il codice non risulta attivo.",
            "error_activate_codealbum_code_not_yet_active": "Il codice non è ancora valido.",
            "error_activate_codealbum_code_expired": "Il codice è scaduto.",
            "error_activate_codealbum_code_already_used": "Hai già utilizzato questa offerta.",
            "error_activate_codealbum_unable_to_retrieve_user_delivery_infos": "Nel tuo profilo non è stato inserito l'indirizzo di spedizione.",
            "error_activate_codealbum_unable_to_retrieve_user_billing_infos": "Nel tuo profilo non è stato inserito l'indirizzo di fatturazione.",
            "error_activate_codealbum_unable_to_retrieve_user": "Il codice non è valido.",
            "my_code_album_modal_title_success": "Questo audiolibro è stato aggiunto alla tua biblioteca!",
            "my_code_album_modal_body_success": "Per trasferlirlo sulla tua Fabbrica delle Storie, accedi all'app per cellulare Android o al Luniistore da computer.",
            "my_code_album_modal_go_to_library": "Vai in biblioteca",
            "my_code_album_modal_validated_back": "Ritorna al profilo",
            "my_code_album_modal_validate": "Utilizza il mio codice",
            "my_code_album_modal_title_oneinmany": "Scegli un audiolibro dal seguente elenco",
            "my_code_album_modal_body_choice_oneinmany": "Una volta che avrai usato il tuo codice, non potrai riutilizzarlo su un altro account o modificare la tua scelta.",
            "my_code_album_body": "Hai ricevuto un codice che permette di sbloccare l'accesso ad un audiolibro? Inseriscilo qui per aggiungere automaticamente l'audiolibro alla tua biblioteca.",
            "my_code_album_title": "Codice libro",
            "my_code_album_modal_title_one": "Riceverai il seguente audiolibro",
            "my_code_album_modal_body_choice_one": "Una volta che avrai usato il tuo codice, non potrai riutilizzarlo su un altro account.",
            "my_code_album_subtitle": "Inserisci il codice per sbloccare gratuitamente un audiolibro.",
            "my_code_album_placeholder": "Codice libro",
            "my_code_album_label": "Codice libro",
            "locale_en_nl_product_language": "Olandese",
            "merge_account_warning_modal_title": "Non sono presenti Fabbriche delle Storie associate al tuo account",
            "merge_account_warning_modal_body_text": "Prima di procedere all'acquisto, assicurati di non aver già creato un account Lunii. Non sarà possibile trasferire gli audiolibri acquistati su questo account ad un altro.",
            "merge_account_warning_modal_body_subtext": "Hai effettuato l'accesso nell'account:",
            "merge_account_warning_modal_button_confirm": "Vai al pagamento",
            "merge_account_warning_modal_infos_text": "Vorresti regalare un audiolibro? Scegli la nostra <1>gift card elettronica</1>!",
            "merge_account_warning_modal_logout_button": "Accedi ad un altro account",
            "wishlist_heading_title": "La mia wishlist",
            "wishlist_empty": "La tua wishlist è ancora vuota.",
            "wishlist_not_empty": "Ritrova qui tutti gli audiolibri che hai salvato.",
            "wishlist_heading_pretitle": "{{count}} audiolibro",
            "wishlist_heading_pretitle_plural": "{{count}} audiolibro",
            "ecommerce_hardware_lunii_-_2_it_3+_name": "La Fabbrica delle Storie dai 3 anni",
            "ecommerce_hardware_lunii_-_2_it_3+_description": "Più di 3 ore di storie audio adatte ai bambini dai 3 anni in su",
            "my_subscription_scp_link": "Aggiorna metodo di pagamento.",
            "credit_card_default_name": "Carta di credito",
            "credit_card_used_in_subscription": "Metodo di pagamento per l'abbonamento",
            "credit_card_default_card": "Metodo di pagamento predefinito",
            "forms_addcreditcard_button_open_form_variant": "Nuovo metodo di pagamento",
            "locale_nl_nl_product_language": "Olandese",
            "locale_nl_nl_label": "Paesi Bassi",
            "footer_newsletter_title": "Vuoi rimanere al passo con le novità e ricevere il 10% di sconto?",
            "footer_newsletter_subtitle": "Iscriviti alla newsletter per ricevere tutte le novità Lunii e uno sconto del 10% da utilizzare sul nostro catalogo di audiolibri!",
            "my_code_album_modal_body_account": "Connettimi in quanto <1>{{email}}</1>.",
            "my_library_error_no_product": "<1>Oops, questa pagina di prodotto non è disponibile</1> La scheda prodotto di questo audiolibro non è più accessibile perché l'audiolibro non è più disponibile per la vendita. Non preoccuparti, potrai trasferirlo sul tuo dispositivo in qualsiasi momento",
            "my_library_error_no_product_confirm": "Capisco",
            "product_author_male": "Autore",
            "product_author_female": "Autore",
            "product_teller_male": "Narratore",
            "product_teller_female": "Narratore",
            "product_translator_male": "Traduttore",
            "product_translator_female": "Traduttore",
            "product_buy": "Acquistare",
            "library_subtitle": "Per trasferire i tuoi audiolibri, scegli l'applicazione corretta a seconda del modello del tuo prodotto. <devComponent/>",
            "library_subtitle_link": "Quale applicazione devo utilizzare?",
            "library_empty_studio_title": "Registra le tue storie e ascoltale sulla Fabbrica delle Storie!",
            "library_empty_studio_description": "Comincia sin da ora a registrare le tue storie nella sezione Studio dell'applicazione per cellulare Lunii.",
            "fah_model_desc_v1": "Per trasferire i tuoi audiolibri alla Fabbrica delle Storie:\n• Scarica l'applicazione per computer, accedi e collega la Fabbrica delle Storie al PC con un cavo USB adatto.\n• Oppure scarica l'applicazione per cellulare, accedi e collega la Fabbrica delle Storie al tuo smartphone con un cavo adatto (solo Android). <devComponent/>",
            "fah_model_desc_v2": "Per trasferire i tuoi audiolibri alla Fabbrica delle Storie:\n• Scarica l'applicazione per computer, accedi e collega la Fabbrica delle Storie al PC con un cavo USB adatto.\n• Oppure scarica l'applicazione per cellulare, accedi e collega la Fabbrica delle Storie al tuo smartphone con un cavo adatto (solo Android). <devComponent/>",
            "fah_model_desc_v3": "Scarica l'applicazione per cellulare, accedi e trasferisci i tuoi audiolibri verso la Fabbrica delle Storie tramite Wi-Fi.",
            "fah_modal_support_link": "Quale cavo devo utilizzare ? (FAQ)",
            "library_empty_studio_cta": "Scopri l'applicazione",
            "my_fahs_no_fahs": "Per associare la Fabbrica delle Storie al tuo account, vai sull'applicazione Lunii per computer o su quella per cellulare.",
            "my_fahs_no_fahs_link": "Quale applicazione devo utilizzare?",
            "my_fahs_bottom_no_empty_bottom_link": "Come posso associare la mia Fabbrica delle Storie?",
            "fah_model": "Modello",
            "fah_model_version_v1": "LUNII-1",
            "fah_model_version_v2": "LUNII-2",
            "fah_model_version_v3": "LUNII-3",
            "device_target_tag_fah": "Per la Fabbrica",
            "device_target_tag_flam": "Per FLAM",
            "device_target_tag_fah_flam": "Per la Fabbrica e per FLAM",
            "my_account_devices_title": "I miei dispositivi audio",
            "my_account_devices_subtitle": "Gestire le Fabbriche delle Storie e i FLAM associati, i numeri di serie, e i diversi modelli...",
            "my_subscription_discover_infos_text": null,
            "my_subscription_discover_infos_subtitle": null,
            "my_devices_no_devices": "Per associare la Fabbrica delle Storie o FLAM al tuo account, vai sull'applicazione Lunii per computer o su quella per cellulare.",
            "my_account_subscription_infos": null,
            "my_devices_bottom_note": "Puoi associare al tuo account fino a 3 raccontastorie Fabbrica delle Storie e 3 lettori FLAM.",
            "my_devices_bottom_no_empty_bottom_link": "Come posso associare un dispositivo audio?",
            "fields_devicename_label": "Nome del dispositivo",
            "fields_devicename_placeholder": "Il mio dispositivo audio",
            "device_model_desc_v1": "Per trasferire i tuoi audiolibri alla Fabbrica delle Storie:\n• Scarica l'applicazione per computer, accedi e collega la Fabbrica delle Storie al PC con un cavo USB adatto.\n• Oppure scarica l'applicazione per cellulare, accedi e collega la Fabbrica delle Storie al tuo smartphone con un cavo adatto (solo Android). <devComponent/>",
            "device_model_desc_v2": "Per trasferire i tuoi audiolibri alla Fabbrica delle Storie:\n• Scarica l'applicazione per computer, accedi e collega la Fabbrica delle Storie al PC con un cavo USB adatto.\n• Oppure scarica l'applicazione per cellulare, accedi e collega la Fabbrica delle Storie al tuo smartphone con un cavo adatto (solo Android). <devComponent/>",
            "device_model_desc_v3": "Scarica l'applicazione per cellulare, accedi e trasferisci i tuoi audiolibri verso la Fabbrica delle Storie tramite Wi-Fi.",
            "device_model_desc_da7": "Scarica l'applicazione per cellulare e accedi per trasferire i tuoi audiolibri verso FLAM via Wi-Fi.",
            "device_firmware": "Software interno",
            "device_model": "Modello",
            "device_model_version_v1": "LUNII-1",
            "device_model_version_v2": "LUNII-2",
            "device_model_version_v3": "LUNII-3",
            "device_model_version_da7": "LUN7P-1",
            "my_device_v1_modal_title": "La Fabbrica delle Storie",
            "my_device_v2_modal_title": "La Fabbrica delle Storie",
            "my_device_v3_modal_title": "La Fabbrica delle Storie",
            "my_device_da7_modal_title": "FLAM",
            "my_devices_no_fah_link": "Come associare la mia Fabbrica delle Storie?",
            "my_devices_no_flam_link": "Come associare il mio lettore FLAM?",
            "my_device_fah": "La Fabbrica delle Storie",
            "my_device_flam": "FLAM",
            "catalog_fah": "Audiolibri 3-7 anni",
            "catalog_flam": "Audiolibri 7-11 anni",
            "my_empty_fah_audiobooks_title": "La tua Biblioteca sembra vuota...",
            "my_empty_fah_studio_title": "Registra le tue storie e ascoltale sulla Fabbrica delle Storie!",
            "my_empty_flam_audiobooks_title": "La tua Biblioteca sembra vuota...",
            "my_empty_flam_studio_title": "La modalità Studio non è disponibile per FLAM",
            "my_empty_fah_audiobooks_description": "É qui che troverai tutti gli audiolibri acquistati per la Fabbrica delle Storie.",
            "my_empty_fah_studio_description": "Comincia sin da ora a registrare le tue storie nella sezione Studio dell'applicazione per cellulare Lunii.",
            "my_empty_flam_audiobooks_description": "É qui che troverai tutti gli audiolibri aquistati per FLAM.",
            "my_empty_fah_audiobooks_action": "Scopri il catalogo 3-7 anni",
            "my_empty_fah_studio_action": "Scopri l'applicazione",
            "my_empty_fah_wishlist_title": "La tua wishlist sembra vuota... per il momento!",
            "my_empty_flam_wishlist_title": "La tua wishlist sembra vuota... per il momento!",
            "my_empty_fah_wishlist_description": null,
            "my_empty_flam_wishlist_description": null,
            "my_empty_fah_wishlist_action": "Scopri il catalogo 3-7 anni",
            "checkout_confirmation_next_steps_subtitle1": "Ritrova immediatamente nella tua Biblioteca gli audiolibri che hai acquistato",
            "checkout_confirmation_next_steps_text1": "Per trasferire le tue storie sul tuo dispositivo audio, vai sull'applicazione Lunii per computer o per cellulari.",
            "checkout_confirmation_next_steps_subtitle2": "Ricevi i tuoi dispositivi audio e gli accessori Lunii in 2 - 5 giorni lavorativi.",
            "checkout_confirmation_next_steps_text2": "Controlla la tua casella mail per rimanere aggiornatə sullo stato della tua spedizione.",
            "checkout_confirmation_next_steps_subtitle3": "Scopri il nostro catalogo di audiolibri per i bambini dai 3 ai 7 anni e dai 7 agli 11 anni.",
            "checkout_confirmation_next_steps_text3": "Ritrova centinaia di storie tutte da ascoltare sulla Fabbrica delle Storie e su FLAM per sviluppare l'immaginazione, la cultura e il vocabolario dei bambini.",
            "merge_account_warning_modal_body_text_fah": "Puoi ascoltare questi audiolibri esclusivamente sulla Fabbrica delle Storie",
            "merge_account_warning_modal_flam_title": "Il tuo account non è associato a nessun FLAM",
            "merge_account_warning_modal_body_text_flam": "Potrai ascoltare questi audiolibri esclusivamente dal lettore FLAM.",
            "merge_account_warning_modal_fah_flam_title": "Il tuo account non è associato a nessuna Fabbrica delle Storie e a nessun FLAM.",
            "merge_account_warning_modal_body_text_fah_flam": "Puoi ascoltare questi audiolibri sulla Fabbrica delle Storie o dal lettore FLAM",
            "my_empty_flam_wishlist_action": "Scopri il catalogo 7-11 anni",
            "my_empty_flam_audiobooks_action": "Scopri il catalogo 7-11 anni",
            "button_label_close": "Chiudi",
            "product_stories_modal_link": "Scopri le {{stories}} storie",
            "product_credits_modal_link": "Visualizza i crediti",
            "product_credits_modal_title": "Crediti",
            "common_error_message": "Si è verificato un errore. Si prega di riprovare. Se l'errore persiste, si prega di contattare il servizio clienti.",
            "page_404_title": "Ops ! Questa pagina non esiste...",
            "page_404_text": "Il labirinto è così grande che potrebbe sembrare che tu ti sia persə...\nNiente paura, i nostri eroi stanno accorrendo in tuo aiuto!",
            "page_404_button": "Ritorna alla home page",
            "promotion_cap_text": "Il valore dello sconto può raggiungere un importo massimo di {{amount}}",
            "global_error_message": "Ci scusiamo ma al momento stiamo riscontrando alcune difficoltà tecniche. Se il problema dovesse persistere, si prega di contattare il servizio clienti (errore: {{error}}, stato: {{status}}).",
            "global_support_name": "servizio clienti",
            "mycreditcards_scp_link": "Gestire le mie carte bancarie",
            "gift_card_balance_expired_balance": "Credito scaduto",
            "gift_card_balance_expired_warning": "La tua gift card è scaduta in data <1>{{date}}</1>. Per questo, non ti sarà più possibile utilizzare il tuo credito. Per aggiungere nuovo credito al tuo account, inserisci una nuova gift card.",
            "capped_discount": "Sconto massimo pari a {{amount}}",
            "discount_cumul_text": "La promozione attualmente attiva sul tuo carrello sarà sostituita da quella nuova che desideri applicare.",
            "discount_cumul_cta": "Applica il mio nuovo codice sconto",
            "global_discount": "Sconto totale",
            "error_cart_discounts_code_over_usage_count": "Questo codice è già stato utilizzato.",
            "amount_percent_cart": "{{amount}} sul carrello",
            "amount_percent_product": "{{amount}} su \"{{productName}}\"",
            "stripe_card_error_http_default": "Le informazioni sul pagamento non sono attualmente disponibili, riprovare più tardi.",
            "gift_card_balance_error_http_default": "Le informazioni sul saldo della carta regalo non sono attualmente disponibili, riprovare più tardi.",
            "discount_edit": "Modifica codice sconto",
            "discount_cumul_title": "Gli sconti non sono cumulabili",
            "cart_amount_details": "Vedi dettaglio",
            "cart_amount_hide_details": "Nascondi dettaglio",
            "checkout_cart_header_total_without_reducton": "Importo senza promozione: <1>{{amount}}</1>",
            "checkout_cart_total_reduction": "Sconto totale: <1>{{amount}}</1>",
            "checkout_cart_header_shipping_amount": "Consegna entro {{description}} lavorativi: <1>{{amount}}</1>",
            "checkout_cart_header_total_account_balance": "Saldo gift card: <1>{{amount}}</1>",
            "checkout_cart_header_total_amount": "Montante totale: {{amount}}",
            "error_cart_discounts_products_not_on_cart": "Il tuo carrello non contiene il/i prodotto/i su cui è applicabile questo codice sconto.",
            "error_cart_discounts_mixed_cart": "Non possiamo applicare questo sconto se il tuo carrello contiene sia audiolibri che prodotti Lunii.",
            "checkout_cart_removed_items_shipping_not_allowed": "Ops, la consegna del prodotto selezionato non è disponibile in questa regione. Gli articoli interessati sono stati automaticamente rimossi dal tuo carrello:",
            "checkout_cart_removed_items_out_of_stock": "Ops! Alcuni prodotti sono esauriti al momento. Gli articoli interessati sono stati automaticamente rimossi dal tuo carrello:",
            "checkout_cart_removed_items_pack_already_in_user_library": "Alcuni audiolibri sono già presenti nella tua biblioteca. Gli articoli interessati sono stati automaticamente rimossi dal tuo carrello:",
            "checkout_cart_removed_items_invalid_promotion": "Ops, questa promozione non è valida. Ti preghiamo di riprovare più tardi o di contattare il nostro servizio clienti per ricevere aiuto.",
            "checkout_cart_removed_items_invalid_promotion_code": "Ops, questa promozione non è valida. Ti preghiamo di riprovare più tardi o di contattare il nostro servizio clienti per ricevere aiuto.",
            "checkout_cart_removed_items_invalid_product": "Ops, alcuni prodotti sono introvabili! Se il problema persiste, ti preghiamo di contattare il nostro servizio clienti.",
            "checkout_cart_removed_items_exclusive_locale_mismatch": "Alcuni audiolibri non sono disponibili nel tuo paese. Gli articoli interessati sono stati automaticamente rimossi dal tuo carello:",
            "checkout_cart_removed_items_promotion_locale_mismatch": "Ops, questa promozione non è valida nel tuo paese. Verifica di essere sul sito giusto per poter usufruirne.",
            "checkout_cart_removed_items_mixed_array": "La promozione selezionata non è applicabile su alcuni articoli del tuo carrello. La promozione è stata rimossa e non verrà applicata sul tuo carrello:",
            "error_validating_cart_amount_too_low": "Ops, l'ordine non può essere finalizzato al momento. L'importo è inferiore al minimo richiesto, che è di 50 centesimi, si prega di modificare il proprio ordine per finalizzarlo.",
            "device_target_product_taxonomy_tag_fah": "Solo sulla Fabbrica",
            "device_target_product_taxonomy_tag_flam": "Solo su Flam",
            "device_target_product_taxonomy_tag_fah_flam": "Su Fabbrica & Flam",
            "device_target_product_taxonomy_header": "Da ascoltare sulla"
        }
    },
    "es-ES": {
        "translation": {
            "navbar_login_label": "Iniciar sesión",
            "module_video_immersive_button_label": "Ver la película",
            "navbar_search_placeholder": "Buscar en el catálogo",
            "cart_empty_text": "Tu cesta está vacía",
            "cart_footer_items_gocart_label": "Ir a cesta",
            "cart_footer_items_mylist_label": "Mi lista",
            "cart_footer_items_myorders_label": "Mis pedidos",
            "fields_city_label": "Ciudad o población",
            "fields_country_label": "País",
            "fields_email_label": "Correo electrónico",
            "fields_firstname_label": "Nombre",
            "fields_lastname_label": "Apellidos",
            "fields_message_label": "Mensaje",
            "fields_password_label": "Contraseña",
            "fields_phone_label": "Teléfono",
            "fields_recipient_label": "Destinatario",
            "fields_subject_label": "Sujeto",
            "fields_zipcode_label": "Código postal",
            "fields_address1_label": "Dirección",
            "fields_address2_label": "Información adicional",
            "fields_city_placeholder": "Ej.: Madrid",
            "fields_country_placeholder": "Ej.: España",
            "fields_email_placeholder": "Ej.: carmen@correoelectronico.com",
            "fields_firstname_placeholder": "Ej.: Martín",
            "fields_lastname_placeholder": "Escribe aquí tu apellido",
            "fields_message_placeholder": "Escribe aquí tu mensaje",
            "fields_password_placeholder": "Escribe aquí tu contraseña",
            "fields_phone_placeholder": "Ej: 012 34 56 78",
            "fields_recipient_placeholder": "Escribe aquí el asunto",
            "fields_subject_placeholder": "Ej.: 28001",
            "locale_fr_fr_label": "Francia (Francés)",
            "locale_it_it_label": "Italia (Italiano)",
            "label_quantity_shortened": "Cantidad",
            "locale_fr_fr_product_language": "Francés",
            "locale_de_de_product_language": "Alemán",
            "cart_go_checkout": "Ir a cesta ({{nbItems}})",
            "cart_other_item": "Y otro artículo…",
            "cart_other_item_plural": "Y otros {{count}} artículos…",
            "constraints_password": "La contraseña debe tener al menos 8 caracteres.",
            "constraints_email": "El correo electrónico debe tener el formato mi@email.com",
            "catalog_no_result_title": "Ningún resultado",
            "catalog_no_result_content": "Lo sentimos, ningún resultado corresponde a tu búsqueda.",
            "catalog_results_for": "resultado(s) para",
            "catalog_menu_title": "Filtrar",
            "product_owned": "Ya lo has comprado",
            "panels_login_title": "Iniciar sesión",
            "panels_login_description": "¡Estamos encantados de volver a verte!",
            "panels_forgotpassword_title": "¿Olvidaste tu contraseña?",
            "panels_forgotpassword_description": "Introduce tu correo electrónico para restablecer la contraseña.",
            "forms_login_buttons_forgotpassword_label": "¿Olvidaste tu contraseña?",
            "forms_login_buttons_submit_label": "Iniciar sesión",
            "forms_login_buttons_cancel_label": "Continuar como invitado",
            "forms_login_buttons_register_label": "Regístrate",
            "forms_login_buttons_register_text": "¿Aún no tienes cuenta?",
            "buttons_register_label": "Regístrate",
            "forms_register_buttons_submit_label": "Regístrate",
            "forms_register_buttons_alreadyregistered_text": "¿Ya estás registrado?",
            "forms_register_buttons_alreadyregistered_label": "Iniciar sesión",
            "panels_register_title": "Regístrate",
            "panels_register_description": "¡Bienvenido a la comunidad Stelii!",
            "catalog_reinitialize": "Reiniciar",
            "catalog_finish": "Terminar",
            "forms_forgotpassword_buttons_submit_label": "Reiniciar",
            "forms_forgotpassword_buttons_cancel_label": "Volver a conectarse",
            "catalog_title": "Aplicación Stelii",
            "login_panel_title": "Conectarse",
            "login_panel_description": "¡Estamos encantados de volver a verte!",
            "checkout_cart_gift_balance": "Tu saldo de la tarjeta regalo",
            "checkout_cart_gift_will_spend": "Vas a gastar",
            "checkout_cart_gift_will_stay": "Te quedarán",
            "checkout_cart_header_nb_items": "ARTÍCULO(S)",
            "checkout_cart_header_no_discount": "Descuento: 0,00 €",
            "checkout_cart_header_with_discount": "Descuento: Te ahorras",
            "checkout_cart_header_ex_vat": "Importe, impuestos excluidos : <1>{{amount}}</1>",
            "checkout_cart_header_vat": "IVA : <1>{{amount}}</1>",
            "checkout_cart_header_title": "Tu pedido",
            "checkout_cart_title": "Cesta",
            "checkout_payment_title": "Envío y pago",
            "checkout_login_title": "Acceso",
            "checkout_login_form_title": "Tengo ya una cuenta Stelii",
            "checkout_shipping_and_purchase_title": "Envío y pago",
            "checkout_shipping_and_purchase_pay": "Pagar ({{price}})",
            "checkout_cart_total_free_shipping": "Gratis",
            "checkout_cart_total_sub_total": "Subtotal",
            "checkout_cart_total_shipping_fee": "Entrega bajo {{description}} (excluyendo los pedidos anticipados)",
            "checkout_cart_total_total_price": "Importe total",
            "checkout_cart_total_discount": "Descuento",
            "checkout_shipping_and_purchase_withdrawal": "Acepto que mi derecho legal de retractación llega a su fín en 14 días o tan pronto como empiece a descargar el primer título de este pedido en Mi Fábrica de Historias, según lo que ocurra el primero.",
            "checkout_shipping_and_purchase_cgv": "He leído y acepto <1>las condiciones generales de venta. </1>",
            "extract_card_info": "Extracto n°",
            "product_add_to_cart": "Añadir a la cesta",
            "locale_nl_be_product_language": "Flamenco",
            "locale_en_us_product_language": "US (Inglés)",
            "locale_en_gb_product_language": "UK (Inglés)",
            "locale_it_it_product_language": "Italiano",
            "locale_es_es_product_language": "Español",
            "fields_promocode_label": "Tu código promocional",
            "button_label_next": "Siguiente",
            "fields_promocode_placeholder": "Tu código promocional",
            "product_language_title": "Idioma",
            "product_duration_title": "Duración",
            "product_age_title": "Edad",
            "product_type_title": "Tipo de audiolibros",
            "product_author": "Autor / Autora",
            "product_teller": "Narrador / Narradora",
            "locale_ru_ru_product_language": "Ruso",
            "product_recommendations_title": "Descubrir también…",
            "product_recommendations_subtitle": "Si este audiolibro te gusta, hemos seleccionado otras historias que podrían interesarte.",
            "product_reduction_content": "Ahorra",
            "newsletter_button_label_register": "Regístrate",
            "promo_code_form_add_promo_code": "Añadir un código promocional",
            "fields_yourfirstname_label": "Tu nombre",
            "fields_newsletteroptin_label": "Tu correo electrónico",
            "credit_card_form_reassurance_title": "Utilizamos el servicio de pago seguro Stripe.",
            "credit_card_form_reassurance_body": "Cuando proporciona su información de pago, sus datos están protegidos por la tecnología Secure Socket Layer (SSL), autenticada por certificado digital. <br />\n<1> Más información </1>",
            "form_address_use_delivery_address": "Utilizar la dirección de envío",
            "newsletter_subtitle": "Me suscribo a la newsletter para estar al corriente de los descuentos, de las novedades y de los eventos de Stelii.",
            "newsletter_privacy_policy_text": "Nos comprometemos a no comunicar nunca tu correo electrónico a terceros.",
            "newsletter_privacy_policy_link_label": "Consulta nuestra política de privacidad.",
            "product_price_free": "Gratis",
            "locale_fr_be_label": "Bélgica (Francés)",
            "locale_fr_ch_label": "Suiza (Francés)",
            "locale_fr_us_label": "Estados Unidos (Francés)",
            "locale_es_us_label": "Estados Unidos (Español)",
            "locale_fr_mc_label": "Mónaco (Francés)",
            "locale_fr_lu_label": "Luxemburgo (Francés)",
            "locale_es_es_label": "España (Español)",
            "locale_de_de_label": "Alemania (Alemán)",
            "locale_en_gb_label": "UK (Inglés)",
            "locale_en_us_label": "USA (Inglés)",
            "fields_giftcard_label": "Tarjeta regalo",
            "checkout_shippingAddress_form_title": "Tu dirección de entrega",
            "checkout_paymentMethod_form_title": "Forma de pago",
            "checkout_billingAddress_form_title": "Tu dirección de facturación",
            "checkout_creditCard_form_title": "Tarjeta bancaria",
            "form_address_edit": "Modificar",
            "form_address_update_address": "Confirmar selección",
            "form_address_cancel_edit": "Anular",
            "productbar_buy_button_label": "Comprar",
            "productbar_textline2": "Entrega en 3 a 5 días",
            "country_fr_label": "Francia",
            "forms_forgotpassword_verify_inbox_text": "Si ya existe una cuenta de Stelii con esta dirección de correo electrónico, recibirás un correo electrónico con las instrucciones para restablecer tu contraseña.",
            "checkout_confirmation_next_steps": "Próximas etapas",
            "checkout_confirmation_shipping_title": "Entrega prevista en 3 a 5 días laborables",
            "checkout_confirmation_shipping_link": "Ver seguimiento de envío",
            "checkout_confirmation_stories_title": "Vuestras historias ya están en vuestra biblioteca",
            "checkout_confirmation_stories_text": "Encuentra todos los audiolibros que has comprado en vuestra biblioteca.",
            "checkout_confirmation_download_title": "Descarga la aplicación Stelii",
            "checkout_confirmation_download_text": "La aplicación Stelii es una aplicación de ordenador que permite añadir o retirar audiolibros de tu Fábrica de Historias.",
            "checkout_confirmation_download_btn": "Descargar la aplicación Stelii",
            "checkout_confirmation_sync_title": "Añade vuestras historias",
            "checkout_confirmation_sync_text": "Conecta la Fábrica a tu ordenador y añade vuestras historias gracias a la aplicación Stelii.",
            "checkout_confirmation_title": "¡Gracias por tu compra!",
            "checkout_confirmation_description": "¡Os deseamos felices momentos de escucha!",
            "checkout_cart_return": "Volver",
            "cart_payment_loading": "Procesando pago",
            "checkout_confirmation_shipping_text": "Recibirás tu pedido en 3-5 días hábiles.",
            "forms_register_newsletter_label": "Me suscribo a la newsletter",
            "forms_register_cgu_label": "He leído y acepto las condiciones del servicio",
            "my_account_title": "Mi cuenta",
            "my_account_user_information_title": "Mis informaciones",
            "my_account_checkout_and_purchase_title": "Pago y facturas",
            "my_account_user_information_subtitle": "Nombre, apellidos, correo electrónico, contraseña.",
            "my_account_checkout_and_purchase_subtitle": "Formas de pago, dirección de facturación, historial de compras, dirección de envío.",
            "my_account_connected_as": "Has iniciado sesión como",
            "my_account_logout": "Cerrar sesión",
            "my_invoice_address_title": "Facturación",
            "my_delivery_address_title": "Dirección de envío",
            "my_purchase_history_title": "Historial de compras",
            "form_user_information_address_title": "Dirección",
            "form_user_information_address_note": "Ponte en contacto con el servicio al cliente para modificar el correo electrónico de tu cuenta Stelii.",
            "form_user_information_password_edit": "Modificar contraseña",
            "checkout_shippingMethods_form_title": "Forma envío",
            "forms_register_country_selection_text": "Selecciona las opciones de idioma y país",
            "errors_login_bad_credentials": "Tus credenciales son incorrectas. Por favor, verifica tu correo electrónico y contraseña.",
            "errors_unauthorized": "En este momento no puedes conectarte con esta cuenta. Inténtalo de nuevo más tarde y, si el problema persiste, ponte en contacto con el servicio de atención al cliente.",
            "errors_server_error": "En este momento estamos experimentando un problema técnico. Inténtalo de nuevo más tarde y, si el problema persiste, ponte en contacto con el servicio de atención al cliente.",
            "form_user_information_update": "Grabar",
            "form_user_information_cancel_edit": "Cancelar",
            "form_user_information_edit": "Editar",
            "catalog_menu_types": "Tipo de audiolibros",
            "catalog_menu_themes": "Temas",
            "catalog_menu_ages": "Edad",
            "catalog_menu_langs": "Idiomas extranjeros",
            "fields_cardowner_label": "Titular de la tarjeta",
            "fields_cardowner_placeholder": "Titular de la tarjeta",
            "cartmodal_success_message": "¡Listo! ¡Añadido!",
            "cartmodal_go_cart_button_label": "Ir a la cesta",
            "cartmodal_buy_button_label": "Añadir a la cesta",
            "cartmodal_continue_button_label": "Seguir navegando",
            "cartmodal_title": "Añadir este producto",
            "product_pages_number_title": "Páginas",
            "product_format_title": "Formato",
            "product_illustrator": "Ilustrador",
            "checkout_confirmation_catalog_btn": "Descubrir el catálogo",
            "checkout_confirmation_free_album_title": "Recupera el audiolibro de regalo Dulce noche",
            "checkout_confirmation_free_album_text": "Te regalamos 6 cuentos entrañables y poéticos con ejercicios de relajación que puedes descargarte desde nuestra web o la aplicación para ordenador Stelii.",
            "cart_empty_subtitle": "¡Qué pena!",
            "errors_register_error_email": "Se ha producido un error al crear tu cuenta. Si ya has creado una cuenta con esta dirección, puedes activarla haciendo clic en el enlace que encontrarás en el correo electrónico de activación.",
            "errors_forgot_password_error_email": "Se ha producido un error durante el restablecimiento de la contraseña. Si ya has creado una cuenta con esta dirección, actívala haciendo clic en el enlace que encontrarás en el correo electrónico de confirmación",
            "payment_intent_authentication_failure": "Su método de pago no ha podido ser autentificado. El pago no ha tenido éxito.",
            "payment_validation_delivery_infos": "Debe rellenar su dirección de entrega.",
            "payment_validation_billing_infos": "Debe rellenar su dirección de facturación.",
            "payment_validation_payment_method": "Debe rellenar los datos de pago.",
            "payment_validation_terms": "Debe aceptar las condiciones generales de venta y las condiciones de desistimiento.",
            "passwordupdatemodal_title": "Modificar mi contraseña",
            "passwordupdatemodal_title_success": "¡Contraseña modificada!",
            "passwordupdatemodal_success_message": "La contraseña se ha modificado correctamente.",
            "passwordupdatemodal_close": "Cerrar",
            "fields_newpassword_label": "Nueva contraseña",
            "fields_newpassword_placeholder": "Tu nueva contraseña (mínimo 8 caracteres)",
            "fields_newpasswordconfirmation_label": "Confirmación de la contraseña",
            "fields_newpasswordconfirmation_placeholder": "Confirma tu nueva contraseña",
            "forms_updatepassword_buttons_submit_label": "Confirmar",
            "errors_passwordupdate_user_not_validated": "No es posible cambiar la contraseña, tu cuenta no ha sido validada.",
            "errors_passwordupdate_password_does_not_match": "Tu contraseña actual es incorrecta",
            "errors_passwordupdate_new_equals_to_old_password": "No puedes reutilizar la misma contraseña",
            "passwordupdatemodal_text_resetonlogin": "Acaba de iniciar la sesión con una contraseña temporal. Introduzca su nueva contraseña, se conectará automáticamente una vez que haya validado el formulario.",
            "catalog_no_result_refresh": "Descubre los audiolibros",
            "checkout_paypal_infos": "Al hacer clic en \"Pagar\", será redirigido a Paypal para finalizar su compra. Una vez validada la transacción, se le redirigirá automáticamente al sitio web de Stelii.",
            "error_creating_payment_paypal": "Se ha producido un error durante la creación del pago en el proveedor de pagos seleccionado. Por favor, inténtelo de nuevo con otro método de pago.",
            "card_declined": "Su tarjeta ha sido rechazada, por favor utilice otro método de pago.",
            "card_number_in_name_field": "Asegúrese de que el campo \"Titular de la tarjeta\" no contiene un número de tarjeta de crédito.",
            "catalog_search_textfield_placeholder": "Buscar",
            "paypal": "PayPal",
            "gift_card_type_lunii": "Tarjeta regalo Stelii de",
            "gift_card_expired_text": "Caduca el",
            "gift_card_remove": "Eliminar",
            "my_account_giftcard_title": "Tarjetas regalo",
            "my_account_giftcard_subtitle": "Asocia una tarjeta regalo, lista de tus tarjetas regalo",
            "mygiftcards_no_giftcards": "Por el momento, no hay ninguna tarjeta regalo registrada.",
            "forms_addgiftcard_button_open_form": "Añadir una tarjeta regalo",
            "forms_addgiftcard_buttons_submit_label": "Añadir",
            "forms_addgiftcard_buttons_cancel_label": "Cancelar",
            "error_gift_card_cannot_add_gift_card_to_user": "No se puede añadir esta tarjeta regalo a tu cuenta",
            "error_gift_card_cannot_remove_card_from_user": "No se puede eliminar esta tarjeta regalo de tu cuenta",
            "error_gift_card_cannot_retrive_card_from_hipay": "No se reconoce el código de la tarjeta regalo introducido",
            "gift_card_checkout_select": "Seleccionar",
            "gift_card_checkout_unselect": "Deseleccionar",
            "checkout_giftcard_title": "Tarjetas regalo",
            "my_purchase_history_no_purchase": "Por el momento, no hemos podido encontrar ninguna compra",
            "purchase_status_success": "Completada",
            "purchase_status_pending": "Pago no completado",
            "purchase_status_failure": "Fallida",
            "cta_item_in_cart": "Ver",
            "cta_item_to_buy": "Añadir",
            "cta_item_owned": "Ya comprado",
            "purchase_status_created": "Creado",
            "purchase_status_cancelled": "Cancelado",
            "purchase_status_refunded": "Reembolsado",
            "purchase_status": "Estado",
            "checkout_cart_amount_giftcard": "Tarjeta regalo",
            "purchase_invoice_download_invoice": "Descargar factura",
            "purchase_invoice_no_invoice": "No hay ninguna factura",
            "purchase_invoice_paper": "Factura en papel (en el paquete)",
            "checkout_payment_wait": "Pago en curso. Por favor, espera unos minutos…",
            "my_account_billing_and_invoices_title": "Datos de pago",
            "my_account_billing_and_invoices_subtitle": "Direcciones de facturación y envío",
            "my_account_purchase_history_title": "Historial de compras",
            "my_account_purchase_history_subtitle": "Historial de compras y facturas",
            "purchase_invoice_subtitle_packs": "Audiolibros",
            "purchase_invoice_subtitle_hardware": "Productos",
            "form_address_search_no_address_found": "No hemos encontrado ningún resultado para tu búsqueda. Si a pesar de eso deseas continuar con la dirección introducida, haz clic en",
            "library_title": "Mi biblioteca",
            "library_menu_luniistore": "Audiolibros Stelii",
            "library_menu_studio": "Audiolibros Mi Estudio",
            "library_empty_title": "Tu biblioteca esta un tanto vacía…",
            "library_empty_description": "Si ya tienes una Fabrica de Historias, ¡descárgate la aplicación Stelii para guardar los audiolibros que ya tienes y comprar otros nuevos!",
            "library_description": "Para transferir tus audiolibros a tu Fábrica de Historias, usa la aplicación Stelii.",
            "form_address_uneditable_country_tooltip": "La entrega solo es posible en el país de la tienda en la que te encuentras. Para cambiar el país, vuelve a la página de inicio para elegir la tienda correcta en la parte inferior de la página.",
            "fields_phone_format_error": "El número introducido no es válido.",
            "fields_phone_international_label": "Otro país",
            "form_address_delivery_address_phone_explanation": "Indica un número de teléfono para que el repartidor pueda ponerse en contacto contigo.",
            "forms_forgotpassword_buttons_resend_label": "Reenviar el correo electrónico de confirmación",
            "forms_resend_validation_verify_inbox_text": "Te hemos enviado un correo electrónico de confirmación.",
            "error_resend_validation": "Se ha producido un error durante el envío del correo electrónico de confirmación. Inténtalo de nuevo. Si el error persiste, ponte en contacto con el servicio de atención al cliente.",
            "my_account_subscription_title": "Mi suscripción",
            "my_subscription_status_active": "Suscripción activa",
            "my_subscription_status_cancelled": "Suscripción cancelada",
            "my_subscription_sub_type_annual_subscription": "Anual",
            "my_subscription_sub_type_monthly_subscription": "Mensual",
            "forms_updatepassword_confirmation_not_match_error": "Las contraseñas introducidas no coinciden",
            "error_card_declined": "Tu tarjeta ha sido rechazada, inténtalo de nuevo con otro método de pago.",
            "checkout_cart_total_amount_giftcard": "Tarjeta regalo",
            "error_gift_card_form_card_redeemed_or_expired": "La tarjeta regalo introducida ya ha sido utilizada o ya ha caducado.",
            "fields_state_label": "Estado / Provincia",
            "fields_state_placeholder": "Estado / Provincia",
            "forms_addcreditcard_button_open_form": "Añadir un método de pago",
            "button_label_save": "Guardar",
            "credit_card_expired": "Caducada",
            "credit_card_expire_date": "Caduca el",
            "mycreditcards_no_creditcards": "Todavía no has añadido un método de pago.",
            "stripe_card_post_error_card_already_exists": "Esta tarjeta ya está registrada.",
            "stripe_card_delete_error_deleting_card": "Se ha producido un error durante la eliminación de la tarjeta de crédito.",
            "stripe_card_post_error_creating_card": "Se ha producido un error durante el registro de la tarjeta de crédito.",
            "stripe_cards_get_unable_to_retrieve_cards_for_user": "Se ha producido un error durante la recuperación de los datos de la tarjeta de crédito.",
            "my_account_creditcard_title": "Métodos de pago",
            "checkout_paymentMethod_paypal_content": "En el momento del pago, se te redirigirá a Paypal para completar tu compra. Una vez validada la transacción, volverás automáticamente a la página web de Stelii.",
            "credit_card_form_register": "Guardar para después",
            "field_required_error": "obligatorio",
            "gift_card_balance_title": "Saldo tarjeta regalo",
            "gift_card_balance_available_balance": "Saldo disponible",
            "gift_card_balance_balance_infos": "Válido únicamente en una cesta que contenga solo audiolibros.",
            "gift_card_balance_card_page_link": "Compre una tarjeta de regalo electrónica",
            "gift_card_balance_warning_infos": "Acepto que el saldo de mi tarjeta se acredite con el importe total de mi tarjeta regalo, de acuerdo con el <devComponent/>",
            "gift_card_balance_terms": "aviso legal.",
            "gift_card_balance_form_toggle_button": "Añadir una nueva tarjeta regalo",
            "gift_card_balance_form_add_button": "Añadir",
            "gift_card_balance_form_cancel_button": "Cancelar",
            "gift_card_balance_added_message": "Tu tarjeta regalo se ha añadido correctamente.",
            "gift_card_balance_error_unable_to_retrieve_account_balance": "Se ha producido un error durante la recuperación del saldo de la tarjeta regalo. Ponte en contacto con el servicio de atención al cliente.",
            "gift_card_balance_error_gift_card_is_expired": "La tarjeta regalo seleccionada ha caducado.",
            "gift_card_balance_error_gift_card_is_invalid": "La tarjeta regalo seleccionada no es válida",
            "checkout_paymentMethod_gift_card_full_cart": "Utilizar <1>{{price}}</1> de los <1>{{balance}}</1> de saldo de tu tarjeta regalo.",
            "checkout_paymentMethod_gift_card_exact_cart": "Utilizar la totalidad de <1>{{balance}}</1> de tu tarjeta regalo",
            "checkout_paymentMethod_gift_card_not_allowed": "Para poder utilizar el saldo de {{balance}} de tu tarjeta regalo, tu pedido solo debe contener audiolibros audio.",
            "checkout_paymentMethod_gift_card_left_to_pay": "Añade un método de pago para pagar los {{amount}} restantes.",
            "checkout_payment_update": "Actualización de la cesta en curso",
            "checkout_cart_total_account_balance": "Saldo tarjeta regalo Stelii",
            "gift_cards_visual_amount_proposal_1": "15",
            "gift_cards_visual_amount_proposal_2": "20",
            "gift_cards_visual_amount_proposal_3": "30",
            "gift_cards_visual_amount_proposal_4": "40",
            "fields_giftcardamount_custom_label": "Importe personalizado (p. ej. 55 €)",
            "fields_giftcardreceivername_label": "Nombre del afortunado/a",
            "fields_giftcardreceiveremail_label": "Dirección de correo electrónico del destinatario",
            "constraints_too_many_characters": "Has superado el número de caracteres permitidos.",
            "gift_cards_receiver_no_receiver": "Quiero recibir la tarjeta de regalo en mi dirección de correo electrónica para poder ofrecerla en persona.",
            "gift_cards_message_title": "Añadir un mensaje",
            "gift_cards_message_subtitle": "opcional",
            "gift_cards_message_warning": "Atención: una vez que hayas pedido la tarjeta regalo, este mensaje no podrá ser modificado.",
            "fields_giftcardmessage_label": "Mensaje de 200 caracteres máximo",
            "characters": "caracteres",
            "gift_cards_sender_title": "De:",
            "fields_giftcardsendername_label": "Tu nombre",
            "gift_cards_date_title": "Programa una fecha de envío",
            "gift_cards_amount_title": "Selecciona un saldo",
            "gift_cards_visual_amount_infos": "El precio de nuestros audiolibros depende de su duración y categoría. Un audiolibros cuesta en promedio 9,90 €.",
            "gift_cards_visual_receiver_infos": "El destinatario recibirá un correo electrónico con una tarjeta imprimible, personalizada con el tema elegido, para entregar en persona.",
            "gift_cards_visual_title": "Selecciona una imagen",
            "gift_cards_visual_infos": "Elige el tema que prefieras para tu tarjeta regalo.",
            "gift_cards_summary_title": "Resumen",
            "gift_cards_summary_receiver": "Regalo para",
            "gift_cards_receiver_title": "Destinatario",
            "gift_cards_error_giftcardtheme": "Debes elegir un tema",
            "gift_cards_error_giftcarddate": "Debes elegir una fecha",
            "gift_cards_error_giftcardamount": "Debes elegir un importe",
            "gift_cards_error_giftcardreceivername": "Debes introducir el nombre del destinatario",
            "gift_cards_error_giftcardreceiveremail": "Debes introducir la dirección de correo electrónico del destinatario",
            "gift_cards_error_giftcardsendername": "Debes introducir tu nombre",
            "gift_cards_date_infos": "La tarjeta regalo electrónica se enviará al destinatario el día seleccionado. El pago de la tarjeta regalo se efectuará al confirmar el pedido.",
            "fields_date_label": "Selecciona una fecha",
            "date_field_error_giftcards_invaliddate": "El formato de la fecha no es válido",
            "date_field_error_giftcards_disablepast": "No puedes seleccionar una fecha anterior a la de hoy",
            "date_field_error_giftcards_maxdate": "No puedes seleccionar una fecha que supere el mes a partir de hoy",
            "gift_cards_submit_infos": "Recibirás el correo electrónico dentro de unos minutos",
            "gift_cards_invalid_form": "El formulario contiene errores",
            "gift_cards_gift_in_cart_name": "Tarjeta regalo electrónica",
            "gift_cards_gift_in_cart_for": "Para",
            "gift_cards_gift_in_cart_from": "De",
            "gift_cards_gift_in_cart_message": "Mensaje",
            "gift_cards_gift_in_cart_date": "Fecha de envío",
            "checkout_payment_get_cart": "Recuperación de la cesta en curso",
            "my_account_giftcard_balance_title": "Saldo tarjeta regalo",
            "my_account_giftcard_balance_subtitle": "Consultar mi saldo, añadir tarjeta regalo",
            "gift_card_balance_availability": "El saldo de tu tarjeta regalo es válido durante 1 año a partir de la fecha de activación de la última tarjeta regalo añadida a la cuenta.",
            "error_validating_cart_error_on_validate": "Se ha producido un error durante la validación de la cesta. Ponte en contacto con el servicio de atención al cliente.",
            "legacy_gift_card_modal_title": "¿Deseas transferir el importe de tus tarjetas regalo a tu saldo actual?",
            "legacy_gift_card_modal_body": "Stelii ha cambiado su sistema de gestión de tarjetas regalo que ahora tendrá un saldo único (que será la suma de los importes de todas tus tarjetas regalo). Tenías tarjetas regalo antiguas registradas en nuestra plataforma: ¿deseas transferirlas a este saldo virtual? Podrás usarlo para pagar tus audiolibros.",
            "legacy_gift_card_validate_button": "Transferir el importe",
            "gift_card_merge_error_unable_to_merge": "Se ha producido un error durante la transferencia, póngase en contacto con el servicio de atención al cliente.",
            "my_account_fahs_title": "Mi Fábrica de Historias",
            "my_fahs_bottom_note": "Puedes asociar hasta 3 Fábricas de Historias a tu cuenta.",
            "my_fahs_fah_modal": "Mi Fábrica de Historias",
            "char_count": "caracteres",
            "fields_fahname_label": "Nombre de Mi Fábrica de Historias",
            "fields_fahname_placeholder": "Mi Fábrica de Historias",
            "fields_fahserial_label": "N.° de serie",
            "fields_fahserial_placeholder": "N.° de serie de 10 o 14 cifras",
            "fah_firmware": "Software interno",
            "my_fahs_fah_modal_cancel": "Cancelar",
            "my_fahs_fah_modal_confirm": "Guardar cambios",
            "my_fahs_no_fah": "Para vincular Mi Fábrica de Historias a tu cuenta Stelii, descarga la aplicación Stelii en tu ordenador, ejecútala y conecta tu Fábrica de Historias.",
            "date_format": "DD/MM/AAAA",
            "gift_card_balance_expiration_date": "Fecha de vencimiento del saldo: {{date}}",
            "error_fah_update_retrieve_fah": "No ha sido posible encontrar esta Fábrica. Actualiza la página y vuelve a intentarlo.",
            "error_fah_update_name": "No ha sido posible actualizar el nombre de esta Fábrica.",
            "error_fah_update_serial": "No ha sido posible actualizar el número de serie de esta Fábrica.",
            "constraints_fahserial": "El nombre es obligatorio y debe tener entre 1 y 40 caracteres.",
            "constraints_fahname": "El número de serie debe tener entre 10 y 40 caracteres.",
            "incorrect_cvc": "El criptograma visual no es válido.",
            "expired_card": "La tarjeta de crédito ha caducado.",
            "processing_error": "Se ha producido un error al procesar el pago. Inténtalo de nuevo y si el error persiste, vuelve a intentarlo más tarde.",
            "incorrect_number": "El número de la tarjeta no es válido.",
            "error_subscription_server": "Se ha producido un error al procesar tu compra. No se ha realizado ningún cargo. Inténtalo de nuevo más tarde.",
            "error_subscription_not_enough_credits": "Tu compra no se ha podido completar debido a una falta de fondos en tu cuenta.",
            "error_subscription_unlisted": "Se ha producido un error al procesar tu pedido.",
            "seo_title_suffix_digital_album": "Audiocuentos | Stelii",
            "locale_en_nl_label": "Países Bajos (inglés)",
            "country_nl_label": "Países Bajos (inglés)",
            "ecommerce_hardware_included_title": "Incluido en la oferta",
            "audio_player_play": "Fragmento",
            "audio_player_pause": "Pausa",
            "form_address_search_address": "Validar mi dirección",
            "error_validate_codealbum_unable_to_retrieve_code": "El código no es válido",
            "error_validate_codealbum_max_usage_count_reached": "Esta oferta ha caducado",
            "error_validate_codealbum_unable_to_retrieve_offer": "Error del servidor",
            "error_validate_codealbum_code_not_active": "Este código actualmente no está activo.",
            "error_validate_codealbum_code_not_yet_active": "El código todavía no es válido.",
            "error_validate_codealbum_code_expired": "El código ha caducado.",
            "error_validate_codealbum_no_available_products": "Tienes todos los productos que se pueden conseguir con este código.",
            "error_validate_codealbum_code_already_used": "Ya has utilizado esta oferta.",
            "error_activate_codealbum_unable_to_retrieve_code": "El código no es válido.",
            "error_activate_codealbum_max_usage_count_reached": "Este código ya se ha utilizado.",
            "error_activate_codealbum_unable_to_retrieve_offer": "No ha sido posible encontrar la oferta asociada a este código de libro.",
            "error_activate_codealbum_code_not_active": "Este código actualmente no está activo.",
            "error_activate_codealbum_code_not_yet_active": "El código todavía no es válido.",
            "error_activate_codealbum_code_expired": "El código ha caducado.",
            "error_activate_codealbum_code_already_used": "Ya has utilizado esta oferta.",
            "error_activate_codealbum_unable_to_retrieve_user_delivery_infos": "No has indicado una dirección de envío en tu cuenta.",
            "error_activate_codealbum_unable_to_retrieve_user_billing_infos": "No has indicado una dirección de facturación en tu cuenta.",
            "error_activate_codealbum_unable_to_retrieve_user": "El código no es válido.",
            "my_code_album_modal_title_success": "¡Este audiolibro se ha añadido correctamente a tu biblioteca!",
            "my_code_album_modal_body_success": "Para transferirlo a tu Fábrica de Historias, usa la aplicación móvil para Android o para ordenadores.",
            "my_code_album_modal_go_to_library": "Ir a la aplicación Stelii",
            "my_code_album_modal_validated_back": "Volver a la cuenta",
            "my_code_album_modal_validate": "Usar mi código",
            "my_code_album_modal_title_oneinmany": "Elige un audiolibro de la siguiente lista",
            "my_code_album_modal_body_choice_oneinmany": "Una vez que utilices el código, no podrás volver a usarlo en otra cuenta ni modificar tu elección.",
            "my_code_album_body": "¿Tienes un código que te permite desbloquear un audiolibro? Introdúcelo aquí para añadirlo directamente a tu biblioteca.",
            "my_code_album_title": "Códigos de libro",
            "my_code_album_modal_title_one": "Recibirás el siguiente audiolibro",
            "my_code_album_modal_body_choice_one": "Una vez que utilices el código, no podrás volver a usarlo en otra cuenta.",
            "my_code_album_subtitle": "Introduce tu código para desbloquear un audiolibro gratuito.",
            "my_code_album_placeholder": "Código de libro",
            "my_code_album_label": "Código de libro",
            "register_special_info": null,
            "button_label_validate": "Confirmar",
            "checkout_paymentMethod_stripe": "Tarjeta de crédito",
            "fields_zipcode_placeholder": "P. ej. 08006",
            "form_address_search_address_not_found": "Mi dirección no figura en la lista",
            "form_address_search_address_select": "Selecciona tu dirección de la siguiente lista",
            "form_address_search_address_force": "Certifico que puedo recibir un paquete en esta dirección",
            "form_address_search_address_extra_button": "Usar la dirección de envío",
            "locale_es_mx_product_language": "Español (latino)",
            "forms_register_data_infos": "Los datos recopilados están destinados a los servicios de Stelii y a sus proveedores con el fin de procesar su pedido y desarrollar nuestras relaciones comerciales. De acuerdo con la ley francesa «Informática y Libertades n.° 78-17 del 6 de enero de 1978» relativa a la informática, los ficheros y las libertades, y el Reglamento Europeo 2016/679 del 27 de abril de 2016 relativo a los datos personales, tienes derecho a acceder, modificar, rectificar y eliminar tus datos. Stelii se compromete en la confidencialidad y la protección de tus datos.",
            "form_register_cgu_link": "condiciones de uso",
            "country_es_label": "España",
            "button_label_cancel": "Cancelar",
            "checkout_bundle_next_page_warning": "¿Dispones de una oferta especial? Los descuentos se calcularán en la siguiente página.",
            "locale_en_nl_product_language": "Holandés",
            "checkout_paymentMethod_paypal": "Paypal",
            "cart_error_some_products_removed": "Uno o más productos han sido eliminados de su cesta porque están agotados o ya están en su biblioteca : <br/> {{description}}",
            "merge_account_warning_modal_title": "No tienes ninguna Fábrica de Historias asociada a tu cuenta",
            "merge_account_warning_modal_body_text": "Antes de proceder con la compra, asegúrate de no haber creado ya una cuenta Stelii. Los audiolibros adquiridos en esta cuenta no podrán ser transferidos a otra cuenta.",
            "merge_account_warning_modal_body_subtext": "Has iniciado sesión en la cuenta:",
            "merge_account_warning_modal_button_confirm": "Continuar con el pago",
            "merge_account_warning_modal_infos_text": "¿Quieres regalar un audiolibro? ¡Puedes regalar una <1>tarjeta regalo electrónica Stelii</1>!",
            "merge_account_warning_modal_logout_button": "Iniciar sesión con otra cuenta",
            "wishlist_heading_title": "Mi lista de favoritos",
            "wishlist_empty": "Todavía no has añadido nada a tu lista de favoritos.",
            "wishlist_not_empty": "Aquí encontrarás los audiolibros que has guardado.",
            "wishlist_heading_pretitle": "{{count}} audiolibro",
            "wishlist_heading_pretitle_plural": "{{count}} audiolibros",
            "ecommerce_hardware_stelii_-_2_es_3+_name": "Mi Fábrica de Historias a partir de 3 años",
            "ecommerce_hardware_stelii_-_2_es_3+_description": "Más de 3 horas de audiocuentos adaptados para niños a partir de 3 años",
            "my_subscription_scp_link": "Actualizar método de pago.",
            "credit_card_default_name": "Tarjeta bancaria",
            "credit_card_used_in_subscription": "Forma de pago de la suscripción",
            "credit_card_default_card": "Método de pago por defecto",
            "forms_addcreditcard_button_open_form_variant": "Nuevo método de pago",
            "locale_nl_nl_product_language": "Holandés",
            "locale_nl_nl_label": "Holanda",
            "footer_newsletter_title": "¡No te pierdas nuestras noticias!",
            "footer_newsletter_subtitle": "Me suscribo a la newsletter para estar al corriente de los descuentos, de las novedades y de los eventos de Stelii.",
            "my_code_album_modal_body_account": "Has iniciado sesión como:<1>{{email}}</1>.",
            "my_library_error_no_product": "<1>¡Ups, esta pagina de producto no está disponible !</1>La ficha de producto de este audiolibro ya no es accesible porque este audiolibro ya no está disponible para la venta. No te preocupes, siempre podrás transferirlo a tu dispositivo.",
            "my_library_error_no_product_confirm": "Comprendo",
            "product_author_male": "Autor",
            "product_author_female": "Autora",
            "product_teller_male": "Narrador",
            "product_teller_female": "Narradora",
            "product_translator_male": "Traductor",
            "product_translator_female": "Traductora",
            "product_buy": "Comprar",
            "library_subtitle": "Para transferir tus audiolibros, escoge la aplicación más adecuada para el modelo de tu producto. <devComponent/>",
            "library_subtitle_link": "¿Qué aplicación debo usar?",
            "library_empty_studio_title": "¡Graba tus propios cuentos y escúchalos en Mi Fábrica de Historias!",
            "library_empty_studio_description": "Empieza a grabar ya tus propias historias en la sección Mi estudio de la aplicación móvil Stelii.",
            "fah_model_version_v1": "LUNII-1",
            "fah_model_version_v2": "LUNII-2",
            "fah_model_version_v3": "LUNII-3",
            "fah_modal_support_link": "¿Qué cable debo usar?",
            "library_empty_studio_cta": "Descubrir la aplicación",
            "my_fahs_no_fahs": "Puedes asociar Mi Fábrica de Historias a tu cuenta desde la aplicación para ordenadores o la aplicación móvil Stelii.",
            "my_fahs_no_fahs_link": "¿Qué aplicación debo usar?",
            "my_fahs_bottom_no_empty_bottom_link": "¿Cómo puedo asociar Mi Fábrica de Historias?",
            "fah_model": "Modelo",
            "fah_model_desc_v1": "Para transferir tus audiolibros a Mi Fábrica de Historias:\n• Descarga la aplicación para ordenadores, inicia sesión y conecta Mi Fábrica de Historias al ordenador mediante un cable USB adaptado.\n• \"O descarga la aplicación móvil, inicia sesión y vincula Mi Fábrica de Historias a tu smartphone\n con un cable adaptado (únicamente para dispositivos Android). <devComponent/>",
            "fah_model_desc_v2": "Para transferir tus audiolibros a Mi Fábrica de Historias:\n• Descarga la aplicación para ordenadores, inicia sesión y conecta Mi Fábrica de Historias al ordenador mediante un cable USB adaptado.\n• \"O descarga la aplicación móvil, inicia sesión y vincula Mi Fábrica de Historias a tu smartphone\n con un cable adaptado (únicamente para dispositivos Android). <devComponent/>",
            "fah_model_desc_v3": null,
            "checkout_confirmation_next_steps_text1": "Para transferir tus cuentos a tu dispositivo de audio, usa la aplicación Stelii para ordenadores o para móviles.",
            "checkout_confirmation_next_steps_subtitle2": "Recibe tus dispositivos de audio y accesorios Stelii en un plazo de 2 a 5 días laborables",
            "page_404_title": "¡Vaya! Esta página ya no existe...",
            "page_404_text": "El laberinto es tan grande que parece que te has perdido... \nNo pasa nada, ¡nuestros personajes están de camino para rescatarte!",
            "page_404_button": "Volver a la página de Inicio",
            "promotion_cap_text": "El importe del descuento se limita a {{amount}}",
            "capped_discount": "Descuento máximo de :",
            "discount_cumul_text": "Si tienes un descuento activo en tu cesta, este será reemplazado por el nuevo descuento que deseas aplicar.",
            "discount_cumul_cta": "Aplicar nuevo código",
            "discount_cumul_title": "Los descuentos no son acumulables",
            "global_discount": "Descuento total",
            "amount_percent_cart": "{{amount}} en la cesta",
            "amount_percent_product": "{{amount}} en “{{productName}}”",
            "cartmodal_cancel_button_label": "Anular",
            "cartmodal_linkedProducts_title": "Descubra también ...",
            "panels_forgotpassword_success_title": "Consulte su buzón electónico",
            "forms_register_data_label": "Doy mi consentimiento para el tratamiento de mis datos personales",
            "forms_register_verify_inbox_text": "Le hemos enviado un correo electrónico de validación a la dirección que proporcionó. Primero debe validar la creación de su cuenta antes de continuar.",
            "forms_register_alert_inbox_text": "Si no ha recibido nada, tenga en cuenta que el correo electrónico puede tardar unos minutos en llegar. Recuerde revisar su carpeta de correo basura.\nSi no ha recibido nada después unos minutos, intente registrarse, prestando atención a la ortografía de su dirección de correo.",
            "panels_register_success_title": "Consulte su correo electrónico",
            "country_de_label": "Bélgica",
            "country_lu_label": "Luxemburgo",
            "country_mc_label": "Mónaco",
            "country_ch_label": "Suiza",
            "country_gb_label": "Reino Unido",
            "country_it_label": "Italia",
            "country_ca_label": "Canadá",
            "country_us_label": "Estados Unidos",
            "locale_en_ca_label": "Canadá (Inglés)",
            "locale_fr_ca_label": "Canadá (Francés)",
            "checkout_cart_error_cart_infos_have_changed": "La información de su cesta ha cambiado, verifique las nuevas informaciones antes de reintentar su pago.",
            "checkout_cart_excl_taxes": "libres de impuestos",
            "checkout_cart_total_discount_old_price": "Precio sin reducción",
            "checkout_error_no_shipping_methods_available": "No se encontró ningún método de entrega. Comuníquese con el servicio de atención al cliente.",
            "error_cart_discounts_code_already_used": "Ya está usando un código.",
            "error_cart_discounts_common_error": "Código inválido",
            "form_address_search_address_force_billing": "Certifico que mi dirección es correcta.",
            "newsletter_subscribe_success": "Hemos tomado en cuenta su suscripción a la newsletter.",
            "newsletter_unsubscribe_success": "Ahora hemos cancelado su suscripción a la newsletter.",
            "error_creating_payment_intent": "Apareció un error con su tarjeta. El pago no se realizó correctamente.",
            "error_updating_cart_already_validated": "Ya ha validado su cesta.",
            "product_discover": "Descubrir",
            "product_out_of_stock": "Este artículo ya no está disponible",
            "push_sticky_close": "Cerrar",
            "pack_assets_label": "Ocupaciones",
            "device_target_tag_fah": "Para Mi Fábrica de Historias",
            "device_target_tag_flam": "Para FLAM",
            "device_target_tag_fah_flam": "Para Mi Fábrica de Historias y FLAM",
            "my_account_devices_title": "Mis dispositivos audio",
            "my_account_devices_subtitle": "Gestionar mis Fábricas de Historias y los FLAM asociados, números de serie, modelos...",
            "my_subscription_discover_infos_text": null,
            "my_subscription_discover_infos_subtitle": null,
            "my_devices_no_devices": "Para asociar Mi Fábrica de Historias o FLAM a tu cuenta, entra en la aplicación para ordenadores o la aplicación móvil Stelii.",
            "my_account_subscription_infos": null,
            "my_devices_bottom_note": "Puedes asociar hasta 3 cuentacuentos Mi Fábrica de Historias y 3 reproductores portátiles FLAM a tu cuenta.",
            "checkout_cart_global_message": null,
            "checkout_cart_warning_hardware": null,
            "my_devices_bottom_no_empty_bottom_link": "¿Cómo puedo asociar mi dispositivo de audio?",
            "fields_devicename_label": "Nombre del dispositivo",
            "fields_devicename_placeholder": "Mi dispositivo de audio",
            "device_model_desc_v1": "Para transferir tus audiolibros a Mi Fábrica de Historias:\n• Descarga la aplicación para ordenadores, inicia sesión y conecta Mi Fábrica de Historias al ordenador mediante un cable USB adaptado.\n• \"O descarga la aplicación móvil, inicia sesión y vincula Mi Fábrica de Historias a tu smartphone\n con un cable adaptado (únicamente para dispositivos Android). <devComponent/>",
            "device_model_desc_v2": "Para transferir tus audiolibros a Mi Fábrica de Historias:\n• Descarga la aplicación para ordenadores, inicia sesión y conecta Mi Fábrica de Historias al ordenador mediante un cable USB adaptado.\n• \"O descarga la aplicación móvil, inicia sesión y vincula Mi Fábrica de Historias a tu smartphone\n con un cable adaptado (únicamente para dispositivos Android). <devComponent/>",
            "device_model_desc_v3": "Descarga la aplicación móvil e inicia sesión para transferir tus audiolibros a Mi Fábrica de Historias a través de Wi-Fi.",
            "device_model_desc_da7": "Descarga la aplicación móvil e inicia sesión para transferir tus audiolibros a tu FLAM a través de Wi-Fi.",
            "device_firmware": "Software interno",
            "device_model": "Modelo",
            "device_model_version_v1": "LUNII-1",
            "device_model_version_v2": "LUNII-2",
            "device_model_version_v3": "LUNII-3",
            "device_model_version_da7": "LUN7P-1",
            "my_device_v1_modal_title": "Mi Fábrica de Historias",
            "my_device_v2_modal_title": "Mi Fábrica de Historias",
            "my_device_v3_modal_title": "Mi Fábrica de Historias",
            "my_device_da7_modal_title": "FLAM",
            "my_devices_no_fah_link": "¿Cómo puedo asociar Mi Fábrica de Historias?",
            "my_devices_no_flam_link": "¿Cómo puedo asociar mi reproductor portátil FLAM?",
            "my_device_fah": "Mi Fábrica de Historias",
            "my_device_flam": "FLAM",
            "catalog_fah": "Audiolibros 3-7 años",
            "catalog_flam": "Audiolibros 7-11 años",
            "my_empty_fah_audiobooks_title": "Parece que tu biblioteca está vacía…",
            "my_empty_fah_studio_title": "¡Graba tus propios cuentos y escúchalos en Mi Fábrica de Historias!",
            "my_empty_flam_audiobooks_title": "Parece que tu biblioteca está vacía…",
            "my_empty_flam_studio_title": "Mi Estudio no está disponible en FLAM.",
            "my_empty_fah_audiobooks_description": "Aquí encontrarás todos los audiolibros adquiridos para Mi Fábrica de Historias.",
            "my_empty_fah_studio_description": "Empieza a grabar ya tus propias historias en la sección Mi estudio de la aplicación móvil Stelii.",
            "my_empty_flam_audiobooks_description": "Aquí encontrarás todos los audiolibros adquiridos para FLAM.",
            "my_empty_fah_audiobooks_action": "Descubrir el catálogo 3-7 años",
            "my_empty_fah_studio_action": "Descubrir la aplicación",
            "my_empty_fah_wishlist_title": null,
            "my_empty_flam_wishlist_title": null,
            "my_empty_fah_wishlist_description": null,
            "my_empty_flam_wishlist_description": null,
            "my_empty_fah_wishlist_action": "Descubrir el catálogo 3-7 años",
            "checkout_confirmation_next_steps_subtitle1": "Accede a tus audiolibros adquiridos de inmediato desde tu biblioteca",
            "checkout_confirmation_next_steps_text2": "Revisa tu correo electrónico para estar al tanto del estado de tu envío.",
            "checkout_confirmation_next_steps_subtitle3": "Descubre nuestro catálogo de audiolibros para niños de 3 a 7 años y de 7 a 11 años",
            "checkout_confirmation_next_steps_text3": "Encontrarás cientos de audiocuentos para escuchar en Mi Fábrica de Historias y FLAM para desarrollar la imaginación, la cultura y el vocabulario de los niños.",
            "merge_account_warning_modal_body_text_fah": "Estos audiolibros son para escuchar en Mi Fábrica de Historias.",
            "merge_account_warning_modal_flam_title": "No tienes ningún reproductor portátil FLAM asociado a tu cuenta.",
            "merge_account_warning_modal_body_text_flam": "Estos audiolibros son solo para escuchar en el reproductor portátil FLAM.",
            "merge_account_warning_modal_fah_flam_title": "No tienes ninguna Fábrica de Historias o reproductor portátil FLAM asociados a tu cuenta.",
            "merge_account_warning_modal_body_text_fah_flam": "Estos audiolibros son para escuchar en Mi Fábrica de Historias o en el reproductor portátil FLAM.",
            "my_empty_flam_wishlist_action": "Descubrir el catálogo 7-11 años",
            "my_empty_flam_audiobooks_action": "Descubrir el catálogo 7-11 años",
            "button_label_close": "Cerrar",
            "product_stories_modal_link": "Descubra las {{stories}} historias",
            "product_credits_modal_link": "Mostrar créditos",
            "product_credits_modal_title": "Créditos",
            "error_validating_cart_discount_already_used": "El código de descuento ya ha sido utilizado en su cuenta.",
            "common_error_message": "Se ha producido un error. Inténtalo de nuevo. Si el error persiste, ponte en contacto con el servicio de atención al cliente.",
            "global_error_message": "Lo sentimos, en estos momentos estamos experimentando dificultades técnicas. Si el problema persiste, ponte en contacto con el Servicio de Atención al Cliente (error: {{error}}, estado: {{status}}).",
            "global_support_name": "Servicio de Atención al Cliente",
            "mycreditcards_scp_link": "Gestionar mis tarjetas bancarias",
            "gift_card_balance_expired_balance": "El saldo ha caducado",
            "gift_card_balance_expired_warning": "Tu tarjeta regalo caducó el <1>{{date}}</1>. Por este motivo, ya no puedes utilizar tu saldo. Para agregar crédito a tu cuenta, añade una nueva tarjeta regalo.",
            "error_cart_discounts_code_over_usage_count": "Este código ya se ha utilizado.",
            "stripe_card_error_http_default": "La información de pago no está disponible, inténtelo más tarde.",
            "gift_card_balance_error_http_default": "La información sobre el saldo de la tarjeta regalo no está disponible, inténtelo más tarde.",
            "discount_edit": "Modificar el código de descuento",
            "cart_amount_details": "Ver detalles",
            "cart_amount_hide_details": "Ocultar detalles",
            "checkout_cart_header_total_without_reducton": "Importe sin descuento : <1>{{amount}}</1>",
            "checkout_cart_total_reduction": "Descuento total : <1>{{amount}}</1>",
            "checkout_cart_header_shipping_amount": "Entrega bajo {{description}} (excluyendo los pedidos anticipados) : <1>{{amount}}</1>",
            "checkout_cart_header_total_account_balance": "Saldo tarjeta regalo : <1>{{amount}}</1>",
            "checkout_cart_header_total_amount": "Importe total : {{amount}}",
            "error_cart_discounts_products_not_on_cart": "Tu cesta no contiene el/los producto/s elegible/s para este código de descuento.",
            "error_cart_discounts_mixed_cart": "No podemos aplicar esta promoción si tu cesta contiene tanto audiolibros como productos Stelii.",
            "checkout_cart_removed_items_shipping_not_allowed": "Lo sentimos, el envío de este producto no está disponible en esta región. Los artículos afectados se eliminaron automáticamente de tu cesta:",
            "checkout_cart_removed_items_out_of_stock": "¡Vaya! Algunos productos están actualmente agotados. Los artículos afectados se eliminaron automáticamente de tu cesta:",
            "checkout_cart_removed_items_pack_already_in_user_library": "Algunos audiolibros ya se encuentran en tu biblioteca. Los artículos afectados se eliminaron automáticamente de tu cesta:",
            "checkout_cart_removed_items_invalid_promotion": "Lo sentimos, esta promoción no es válida. Vuelve a intentarlo más tarde o ponte en contacto con nuestro servicio de atención al cliente para obtener ayuda.",
            "checkout_cart_removed_items_invalid_promotion_code": "Lo sentimos, esta promoción no es válida. Vuelve a intentarlo más tarde o ponte en contacto con nuestro servicio de atención al cliente para obtener ayuda.",
            "checkout_cart_removed_items_invalid_product": "Lo sentimos, no hemos podido encontrar algunos productos. Si el problema persiste, no dudes en ponerte en contacto con nuestro servicio de atención al cliente.",
            "checkout_cart_removed_items_exclusive_locale_mismatch": "Algunos audiolibros no están disponibles en tu país. Los artículos afectados se eliminaron automáticamente de tu cesta:",
            "checkout_cart_removed_items_promotion_locale_mismatch": "Lo sentimos, esta promoción no es válida en tu país. Comprueba que estás en la web correcta para poder beneficiarte de la misma.",
            "checkout_cart_removed_items_mixed_array": "Algunos artículos de tu cesta no son elegibles para la promoción en curso. La promoción se ha eliminado automáticamente y ya no se aplica a tu cesta.",
            "forms_register_locale_selection_text": "Seleccione sus opciones de idioma y país",
            "country_be_label": "Bélgica",
            "error_validating_cart_amount_too_low": "Oops, el pedido no se puede finalizar en este momento. El monto es inferior al mínimo requerido, que es de 50 centavos, por favor modifique su pedido para finalizarlo.",
            "device_target_product_taxonomy_tag_fah": "Sólo la Fábrica",
            "device_target_product_taxonomy_tag_flam": "Sólo FLAM",
            "device_target_product_taxonomy_tag_fah_flam": "La Fábrica y FLAM",
            "device_target_product_taxonomy_header": "Escuchar en"
        }
    },
    "en-US": {
        "translation": {
            "navbar_login_label": "Login",
            "module_video_immersive_button_label": "Watch the video",
            "navbar_search_placeholder": "Search in the catalogue",
            "cart_empty_text": "Your cart is empty",
            "cart_footer_items_gocart_label": "Go to cart",
            "cart_footer_items_mylist_label": "My list",
            "cart_footer_items_myorders_label": "My orders",
            "fields_city_label": "City",
            "fields_country_label": "Country",
            "fields_email_label": "Email",
            "fields_firstname_label": "First name",
            "fields_lastname_label": "Last name",
            "fields_message_label": "Message",
            "fields_password_label": "Password",
            "fields_phone_label": "Phone",
            "fields_recipient_label": "Recipient",
            "fields_subject_label": "Subject",
            "fields_zipcode_label": "Zip code",
            "fields_address_label": "Address",
            "fields_additionaladdress_label": "Additional info",
            "fields_city_placeholder": null,
            "fields_country_placeholder": null,
            "fields_email_placeholder": "Ex: me@myemail.com",
            "fields_firstname_placeholder": "Ex: John",
            "fields_lastname_placeholder": "Ex: Doe",
            "fields_message_placeholder": "Your message here",
            "fields_password_placeholder": "Your password",
            "fields_phone_placeholder": null,
            "fields_recipient_placeholder": "Recipient",
            "fields_subject_placeholder": "Your subject here",
            "fields_zipcode_placeholder": null,
            "locale_fr_fr_label": "France (French)",
            "locale_it_it_label": "Italy (Italian)",
            "label_quantity_shortened": "Qty",
            "locale_fr_fr_product_language": "French",
            "locale_de_de_product_language": "German",
            "cart_go_checkout": "Go to cart ({{nbItems}})",
            "cart_other_item": "1 other item...",
            "cart_other_item_plural": "{{count}} other items...",
            "constraints_password": "Password must be at least 8 characters long",
            "constraints_email": "Email must be in the format my@email.com",
            "catalog_no_result_title": "No result",
            "catalog_no_result_content": "Unfortunately, no results were found",
            "catalog_results_for": "Result(s) for",
            "catalog_menu_title": "Filter",
            "product_owned": "Already bought",
            "panels_login_title": "Login",
            "panels_login_description": "We are happy to see you again!",
            "panels_forgotpassword_title": "Forgot your password?",
            "panels_forgotpassword_description": "Enter your email address to reset your password",
            "forms_login_buttons_forgotpassword_label": "Forgot your password?",
            "forms_login_buttons_submit_label": "Login",
            "forms_login_buttons_cancel_label": "Login as guest",
            "forms_login_buttons_register_label": "Register",
            "forms_login_buttons_register_text": "No account?",
            "buttons_register_label": "Register",
            "forms_register_buttons_submit_label": "Register",
            "forms_register_buttons_alreadyregistered_text": "Already registered?",
            "forms_register_buttons_alreadyregistered_label": "Login",
            "panels_register_title": "Register",
            "panels_register_description": "Welcome to the community Lunii!",
            "catalog_reinitialize": "Reset",
            "catalog_finish": "Finish",
            "forms_forgotpassword_buttons_submit_label": "Reset",
            "forms_forgotpassword_buttons_cancel_label": "Go back to login",
            "catalog_title": "Luniistore",
            "login_panel_title": "Login",
            "login_panel_description": "We are happy to see you again!",
            "checkout_cart_gift_balance": "Your gift card balance",
            "checkout_cart_gift_will_spend": "Spend",
            "checkout_cart_gift_will_stay": "Remaining balance",
            "checkout_cart_header_nb_items": "ITEM(S)",
            "checkout_cart_header_no_discount": "Reductions : 0,00",
            "checkout_cart_header_with_discount": "Reduction: You are saving",
            "checkout_cart_header_ex_vat": "Pre-tax amount: <1>{{amount}}</1>",
            "checkout_cart_header_vat": "VAT: <1>{{amount}}</1>",
            "checkout_cart_header_title": "Your order",
            "checkout_cart_title": "Cart",
            "checkout_payment_title": "Payment and delivery",
            "checkout_login_title": "Login",
            "checkout_login_form_title": "I already have a Lunii account",
            "checkout_shipping_and_purchase_title": "Delivery and payment",
            "checkout_shipping_and_purchase_pay": "Pay ({{price}})",
            "checkout_cart_total_free_shipping": "Free",
            "checkout_cart_total_sub_total": "Sub-total",
            "checkout_cart_total_shipping_fee": "Shipment: {{description}} (excluding preorders)",
            "checkout_cart_total_total_price": "Total amount",
            "checkout_cart_total_discount": "Reduction",
            "checkout_shipping_and_purchase_withdrawal": "I agree that my statutory right of withdrawal will end in 14 days or as soon as I start downloading the first audiobook on My Fabulous Storyteller, whichever occurs first.",
            "checkout_shipping_and_purchase_cgv": "I read and accept the <1> general term and conditions </1>",
            "extract_card_info": "Extract n°",
            "product_add_to_cart": "Add to cart",
            "locale_nl_be_product_language": "Flemish",
            "locale_en_us_product_language": "US (English)",
            "locale_en_gb_product_language": "UK (English)",
            "locale_it_it_product_language": "Italian",
            "locale_es_es_product_language": "Spanish",
            "fields_promocode_label": "Your discount code",
            "button_label_next": "Next",
            "fields_promocode_placeholder": "Your discount code",
            "product_language_title": "Language",
            "product_duration_title": "Duration",
            "product_age_title": "Age",
            "product_type_title": "Audiobook type",
            "product_author": "Author",
            "product_teller": "Narrator",
            "locale_ru_ru_product_language": "Russian",
            "product_recommendations_title": "Also discover...",
            "product_recommendations_subtitle": "If you like this audiobook, we have selected similar stories that might interest you.",
            "product_reduction_content": "Save",
            "newsletter_button_label_register": "Register",
            "promo_code_form_add_promo_code": "Add a discount code",
            "fields_yourfirstname_label": "Your first name",
            "fields_newsletteroptin_label": "Your email address",
            "credit_card_form_reassurance_title": "We use Stripe secure payment service",
            "credit_card_form_reassurance_body": "When you provide your payment information, your data is protected by the Secure Socket Layer technology (SSL), authentificated by our digital certificate <br /> \n<1>Find out more</1>",
            "form_address_use_delivery_address": "Use delivery address",
            "newsletter_subtitle": "Special offers, new audiobooks, suggestions... We promise to never share your email with third parties.",
            "newsletter_privacy_policy_text": "We commit not to share your data with any third parties",
            "newsletter_privacy_policy_link_label": "Read our privacy policy",
            "product_price_free": "Free",
            "locale_fr_be_label": "Belgium (French)",
            "locale_fr_ch_label": "Switzerland (French)",
            "locale_fr_us_label": "USA (French)",
            "locale_es_us_label": "USA (Spanish)",
            "locale_fr_mc_label": "Monaco (French)",
            "locale_fr_lu_label": "Luxembourg (French)",
            "locale_es_es_label": "Spain (Spanish)",
            "locale_de_de_label": "Germany (German)",
            "locale_en_gb_label": "United Kingdom (English)",
            "locale_en_us_label": "USA (English)",
            "fields_giftcard_label": "Gift card",
            "checkout_shippingAddress_form_title": "Your shipping address",
            "checkout_paymentMethod_form_title": "Payment method",
            "checkout_billingAddress_form_title": "Your billing address",
            "checkout_creditCard_form_title": "Bank card",
            "form_address_edit": "Edit",
            "form_address_update_address": "Confirm selection",
            "form_address_cancel_edit": "Cancel",
            "productbar_buy_button_label": "Buy",
            "productbar_textline2": "Delivery within 5 to 7 days",
            "country_fr_label": "France",
            "forms_forgotpassword_verify_inbox_text": "If a Lunii account is already connected to this email address, you will receive an email with instructions to reset your password.\n\nRemember to check your junk mail folder !",
            "checkout_confirmation_next_steps": "Next steps",
            "checkout_confirmation_shipping_title": "Delivery!",
            "checkout_confirmation_shipping_link": "Track my order",
            "checkout_confirmation_stories_title": "Your stories are already in your library",
            "checkout_confirmation_stories_text": "Find all of your audiobooks in your library",
            "checkout_confirmation_download_title": "Download the Luniistore",
            "checkout_confirmation_download_text": "The Luniistore is a computer app that allows to add or remove audiobooks on My Fabulous Storyteller.",
            "checkout_confirmation_download_btn": "Download the Luniistore",
            "checkout_confirmation_sync_title": "Transfer your stories",
            "checkout_confirmation_sync_text": "Connect My Fabulous Storyteller to your computer and transfer your stories thanks to the Luniistore.",
            "checkout_confirmation_title": "Thank you for your purchase!",
            "checkout_confirmation_description": "We wish you great listening experiences!",
            "checkout_cart_return": "Return",
            "cart_payment_loading": "Payment in process",
            "checkout_confirmation_shipping_text": "Your order will arrive to your doorstep within 5 to 7 days",
            "forms_register_newsletter_label": "Sign up to our newsletter",
            "forms_register_cgu_label": "I have read and accepted the terms",
            "my_account_title": "My account",
            "my_account_user_information_title": "My information",
            "my_account_checkout_and_purchase_title": "Payment and invoices",
            "my_account_user_information_subtitle": "First name, last name, email, password",
            "my_account_checkout_and_purchase_subtitle": "Payment method, billing address, purchase history, shipping address",
            "my_account_connected_as": "Connected as",
            "my_account_logout": "Logout",
            "my_invoice_address_title": "Billing",
            "my_delivery_address_title": "Shipping address",
            "my_purchase_history_title": "Purchase history",
            "form_user_information_address_title": "Email address",
            "form_user_information_address_note": "Contact our customer care service to edit your email address for your Lunii account .",
            "form_user_information_password_edit": "Edit your password",
            "checkout_shippingMethods_form_title": "Shipping method",
            "forms_register_country_selection_text": "Select your language preference and your country",
            "country_gb_label": "United Kingdom",
            "errors_login_bad_credentials": "Please check your email and your password, your login details are incorrect.",
            "errors_unauthorized": "You are not authorized to login with this account, please try again or contact customer service if the problem remains.",
            "errors_server_error": "We are currently experiencing technical issues, please try again later. If the problem remains, please contact customer service.",
            "form_user_information_update": "Save",
            "form_user_information_cancel_edit": "Cancel",
            "form_user_information_edit": "Edit",
            "catalog_menu_types": "Type of audiobooks",
            "catalog_menu_themes": "Themes",
            "catalog_menu_ages": "Age",
            "catalog_menu_langs": "Languages",
            "fields_cardowner_label": "Card holder",
            "fields_cardowner_placeholder": "Card holder",
            "cartmodal_success_message": "That's added!",
            "cartmodal_go_cart_button_label": "Go to cart",
            "cartmodal_buy_button_label": "Add to cart",
            "cartmodal_continue_button_label": "Carry on browsing",
            "cartmodal_cancel_button_label": "Cancel",
            "cartmodal_linkedProducts_title": "Also discover...",
            "cartmodal_title": "Add this product",
            "product_pages_number_title": "Pages",
            "product_format_title": "Format",
            "product_illustrator": "Illustrator",
            "checkout_confirmation_catalog_btn": "Discover our catalogue",
            "checkout_confirmation_free_album_title": "Get the free Good Night Stories",
            "checkout_confirmation_free_album_text": "We offer for free 6 poetical and gentle tales with relaxation exercises to download from our website or from our computer app the Luniistore.",
            "cart_empty_subtitle": "What a shame!",
            "form_register_cgu_link": "terms of service",
            "errors_register_error_email": "An error occurred while creating the account. If you have already created an account using this address, please click on the link in the activation email.",
            "errors_forgot_password_error_email": "An error occurred during the password reset. If you have already created an account using this email address, please activate it by clicking on the link in the confirmation email.",
            "form_address_search_address": "Validate my address",
            "form_address_search_address_select": "Select your address from the list below",
            "form_address_search_address_not_found": "My address is not in the list",
            "form_address_search_address_force": "I certify that I can receive my order at this address",
            "country_de_label": "Germany",
            "country_be_label": "Belgium",
            "country_lu_label": "Luxembourg",
            "country_mc_label": "Monaco",
            "country_ch_label": "Swiss",
            "country_it_label": "Italy",
            "country_ca_label": "Canada",
            "country_us_label": "United States",
            "locale_es_mx_product_language": "Spanish (LATAM)",
            "locale_en_ca_label": "Canada (English)",
            "locale_fr_ca_label": "Canada (French)",
            "form_address_search_address_force_billing": "I confirm that my address is correct.",
            "form_address_search_address_extra_button": "Use delivery address",
            "button_label_validate": "Confirm",
            "error_cart_discounts_common_error": "Invalid code",
            "button_label_cancel": "Cancel",
            "checkout_error_no_shipping_methods_available": "No delivery method was found. Please contact customer service.",
            "newsletter_subscribe_success": "Your subscription to the newsletter has been taken into account.",
            "newsletter_unsubscribe_success": "You are now unsubscribed from the newsletter.",
            "checkout_cart_excl_taxes": "without taxes",
            "checkout_cart_total_discount_old_price": "Amount excluding discount",
            "error_cart_discounts_code_already_used": "You have already used a code",
            "panels_register_success_title": "Check your mailbox.",
            "forms_register_verify_inbox_text": "We have sent you a confirmation email to the address you provided. You must first confirm the creation of your account before continuing.",
            "forms_register_alert_inbox_text": "Bear in mind that it may take a few minutes for the email to arrive. Please check your spam folder. \nIf you haven't received anything within the next few minutes, please double check the spelling of your email.",
            "fields_address2_label": "Additional address",
            "checkout_cart_error_cart_infos_have_changed": "Your cart has changed, please take another look to it before trying to pay again.",
            "fields_address1_label": "Address",
            "forms_register_data_label": "I agree to share my data with Lunii",
            "forms_register_locale_selection_text": "Select your country and language options",
            "panels_forgotpassword_success_title": "Check your mailbox.",
            "error_creating_payment_intent": "There was an issue with your card, the payment didn't go through.",
            "error_updating_cart_already_validated": "Your cart has already been validated.",
            "payment_intent_authentication_failure": "Your payment method could not be authentified. The payment did not go through.",
            "payment_validation_delivery_infos": "You must fill in your delivery informations.",
            "payment_validation_billing_infos": "You must fill in your billing informations.",
            "payment_validation_payment_method": "You must fill in the payment informations.",
            "payment_validation_terms": "You must accept the terms of service and the withdrawal agreement.",
            "product_discover": "Discover",
            "product_out_of_stock": "This item is no longer available",
            "register_special_info": null,
            "forms_register_data_infos": "The data collected is intended for the services of Lunii and its service providers to process your order and our sales relationships. In compliance with the french law \"Technology freedom n°78-17 of January 6th 1978\" related to information technology, data files and freedom and in accordance with the European regulation 2016/679 of April 27th 2016 related to personal data, you have the right to access, modify, rectify and delete any data concerning your profile. Lunii is committed to protect your confidentiality and data.",
            "checkout_paymentMethod_stripe": "Bank card",
            "checkout_paymentMethod_paypal": "PayPal",
            "passwordupdatemodal_title": "Change my password",
            "passwordupdatemodal_title_success": "Password changed!",
            "passwordupdatemodal_success_message": "Password has successfully been changed",
            "passwordupdatemodal_close": "Close",
            "fields_newpassword_label": "New password",
            "fields_newpassword_placeholder": "Your new password (8 characters min)",
            "fields_newpasswordconfirmation_label": "Password confirmation",
            "fields_newpasswordconfirmation_placeholder": "Confirm your new password",
            "forms_updatepassword_buttons_submit_label": "Submit",
            "errors_passwordupdate_user_not_validated": "Cannot change your password, your account is not confirmed",
            "errors_passwordupdate_password_does_not_match": "Your current password is incorrect",
            "errors_passwordupdate_new_equals_to_old_password": "You cannot use the same password as before",
            "passwordupdatemodal_text_resetonlogin": "You have just logged in with a temporary password. Enter your new password, you will be automatically connected once you have validated the form.",
            "catalog_no_result_refresh": "Discover the audiobooks",
            "checkout_paypal_infos": "By clicking on \"Pay\", you will be redirected to Paypal to finalize your purchase. Once the transaction is validated, you will be automatically redirected to the Lunii website.",
            "error_creating_payment_paypal": "An error occurred during the creation of the payment at the selected payment provider. Please try again with another payment method.",
            "card_declined": "Your card has been declined, please use a different payment method.",
            "card_number_in_name_field": "Make sure that the \"Cardholder\" field does not contain a credit card number.",
            "catalog_search_textfield_placeholder": "Search",
            "paypal": "PayPal",
            "cart_error_some_products_removed": "One or more products have been removed from your cart because they are out of stock or already in your library: <br/> {{description}}",
            "gift_card_type_lunii": "Lunii gift card for",
            "gift_card_expired_text": "Expires on",
            "gift_card_remove": "Remove",
            "my_account_giftcard_title": "Gift cards",
            "my_account_giftcard_subtitle": "Apply a gift card, List of your gift cards.",
            "mygiftcards_no_giftcards": "You currently have no gift cards on your account.",
            "forms_addgiftcard_button_open_form": "Add a gift card",
            "forms_addgiftcard_buttons_submit_label": "Add",
            "forms_addgiftcard_buttons_cancel_label": "Cancel",
            "error_gift_card_cannot_add_gift_card_to_user": "This gift card cannot be added to your account",
            "error_gift_card_cannot_remove_card_from_user": "This gift card cannot be removed from your account",
            "error_gift_card_cannot_retrive_card_from_hipay": "This gift card code is not recognised",
            "gift_card_checkout_select": "Select",
            "gift_card_checkout_unselect": "Remove",
            "checkout_giftcard_title": "Gift cards",
            "my_purchase_history_no_purchase": "We cannot find any orders for you at the moment",
            "purchase_status_success": "Payment successful",
            "purchase_status_pending": "Payment not completed",
            "purchase_status_failure": "Payment unsuccessful",
            "cta_item_in_cart": "View",
            "cta_item_to_buy": "Add",
            "cta_item_owned": "Already purchased",
            "purchase_status_created": "Created",
            "purchase_status_cancelled": "Cancelled",
            "purchase_status_refunded": "Refunded",
            "purchase_status": "Status",
            "checkout_cart_amount_giftcard": "Gift Card",
            "purchase_invoice_download_invoice": "Download invoice",
            "purchase_invoice_no_invoice": "No invoice",
            "purchase_invoice_paper": "Paper invoice (inside the package)",
            "checkout_payment_wait": "Payment in progress... Please wait",
            "my_account_billing_and_invoices_title": "Payment information",
            "my_account_billing_and_invoices_subtitle": "Biling and delivery address",
            "my_account_purchase_history_title": "Purchase history",
            "my_account_purchase_history_subtitle": "Purchase history and invoices",
            "purchase_invoice_subtitle_packs": "Audiobooks",
            "purchase_invoice_subtitle_hardware": "Products",
            "push_sticky_close": "Close",
            "form_address_search_no_address_found": "There are no results matching your search. If you want to continue with the address you entered anyway, click on",
            "library_title": "My library",
            "library_menu_luniistore": "Luniistore audiobooks",
            "library_menu_studio": "Studio audiobooks",
            "library_empty_title": "Your library is looking a bit empty...",
            "library_empty_description": "If you already have My Fabulous Storyteller, download the Luniistore to save the audiobooks you already have, and buy new ones!",
            "library_description": "To transfer your audiobooks to your My Fabulous Storyteller, head to the Luniistore desktop app.",
            "form_address_uneditable_country_tooltip": "Delivery is only possible in the country associated with the store you are in. To change the country, please return to the homepage and select the right store at the bottom of the page.",
            "fields_phone_format_error": "This phone number is not valid.",
            "fields_phone_international_label": "Other country",
            "form_address_delivery_address_phone_explanation": "Please provide a telephone number so that the delivery person can contact you.",
            "pack_assets_label": "Activities",
            "checkout_bundle_next_page_warning": "Do you have a special offer? The discount will be calculated on the next page.",
            "forms_forgotpassword_buttons_resend_label": "Re-send the confirmation email",
            "forms_resend_validation_verify_inbox_text": "A confirmation email has been sent to you.",
            "error_resend_validation": "An error occurred while sending the confirmation email. Please try again. If the error persists, please contact customer service.",
            "my_account_subscription_title": "My subscription",
            "my_subscription_status_active": "Active subscription",
            "my_subscription_status_cancelled": "Cancelled subscription",
            "my_subscription_sub_type_annual_subscription": "Yearly",
            "my_subscription_sub_type_monthly_subscription": "Monthly",
            "forms_updatepassword_confirmation_not_match_error": "The passwords you entered do not match",
            "error_card_declined": "Your card has been declined, please try again with another payment method.",
            "checkout_cart_total_amount_giftcard": "Gift card",
            "error_gift_card_form_card_redeemed_or_expired": "The gift card added has already been used or the expiry date has passed",
            "fields_state_label": "State / Province",
            "fields_state_placeholder": "State / Province",
            "forms_addcreditcard_button_open_form": "Add a payment method",
            "button_label_save": "Save",
            "credit_card_expired": "Expired",
            "credit_card_expire_date": "Expires on",
            "mycreditcards_no_creditcards": "You have not added a payment method",
            "stripe_card_post_error_card_already_exists": "This card is already saved.",
            "stripe_card_delete_error_deleting_card": "A problem occurred while deleting the credit card.",
            "stripe_card_post_error_creating_card": "A problem occurred while saving the credit card.",
            "stripe_cards_get_unable_to_retrieve_cards_for_user": "A problem occurred while retrieving the credit card details.",
            "my_account_creditcard_title": "Payment methods",
            "checkout_paymentMethod_paypal_content": "At the time of payment, you will be redirected to Paypal to complete your purchase. Once the transaction is validated, you will be automatically redirected to the Lunii website.",
            "field_required_error": "required",
            "gift_card_balance_title": "Gift card balance",
            "gift_card_balance_available_balance": "Available balance",
            "gift_card_balance_balance_infos": "Valid only on a basket containing exclusively audiobooks.",
            "gift_card_balance_card_page_link": "Buy an e-gift card",
            "gift_card_balance_warning_infos": "I agree to have my card balance credited to the full amount of my gift card, in accordance with the <devComponent/>",
            "gift_card_balance_terms": "legal notice.",
            "gift_card_balance_form_toggle_button": "Add a gift card.",
            "gift_card_balance_form_add_button": "Add",
            "gift_card_balance_form_cancel_button": "Cancel",
            "gift_card_balance_added_message": "Your gift card has already been added.",
            "gift_card_balance_error_unable_to_retrieve_account_balance": "An error has occurred while retrieving the gift card balance, please contact customer service.",
            "gift_card_balance_error_gift_card_is_expired": "The selected gift card has expired.",
            "gift_card_balance_error_gift_card_is_invalid": "The selected gift card is invalid.",
            "checkout_paymentMethod_gift_card_full_cart": "Use <1>{{price}}</1> of your <1>{{balance}}</1> gift card balance.",
            "checkout_paymentMethod_gift_card_exact_cart": "Use all <1>{{balance}}</1> of your gift card credit",
            "checkout_paymentMethod_gift_card_not_allowed": "To use your {{balance}} gift card balance, your order must contain only audiobooks.",
            "checkout_paymentMethod_gift_card_left_to_pay": "Please add a payment method to pay the remaining {{amount}}.",
            "checkout_payment_update": "Update of my cart in progress",
            "checkout_cart_total_account_balance": "Lunii's gift card balance",
            "gift_cards_visual_amount_proposal_1": "15",
            "gift_cards_visual_amount_proposal_2": "20",
            "gift_cards_visual_amount_proposal_3": "30",
            "gift_cards_visual_amount_proposal_4": "40",
            "fields_giftcardamount_custom_label": "Custom amount (e.g. 55)",
            "fields_giftcardreceivername_label": "First name of the lucky person",
            "fields_giftcardreceiveremail_label": "Email address of the recipient",
            "constraints_too_many_characters": "You have exceeded the number of characters allowed.",
            "gift_cards_receiver_no_receiver": "I will receive the e-gift card at my e-mail address in person",
            "gift_cards_message_title": "Add a short note",
            "gift_cards_message_subtitle": "optional",
            "gift_cards_message_warning": "Please note that once the e-gift card has been ordered, this message cannot be changed.",
            "fields_giftcardmessage_label": "Message of 200 characters maximum",
            "characters": "characters",
            "gift_cards_sender_title": "From:",
            "fields_giftcardsendername_label": "Your name",
            "gift_cards_date_title": "Set a sending date",
            "gift_cards_amount_title": "Enter an amount",
            "gift_cards_visual_amount_infos": "Our audiobooks are priced according to their length and category. An audiobook costs an average of 9,90€/$.",
            "gift_cards_visual_receiver_infos": "The recipient will be e-mailed a printable card, personalized with the chosen theme, which they can gift by hand.",
            "gift_cards_visual_title": "Select a picture",
            "gift_cards_visual_infos": "Several visuals are proposed to adapt the universe to everyone's desires.",
            "gift_cards_summary_title": "Summary",
            "gift_cards_summary_receiver": "A present for",
            "gift_cards_receiver_title": "Recipient",
            "gift_cards_error_giftcardtheme": "You must select a theme",
            "gift_cards_error_giftcarddate": "You must select a date",
            "gift_cards_error_giftcardamount": "You must select an amount",
            "gift_cards_error_giftcardreceivername": "You must fill in the name of the recipient",
            "gift_cards_error_giftcardreceiveremail": "You must fill in the recipient's email address",
            "gift_cards_error_giftcardsendername": "You must fill in your name",
            "gift_cards_date_infos": "The e-gift card will be sent to the recipient on the selected day. Payment for the gift card is taken the moment the order is confirmed.",
            "fields_date_label": "Select a date",
            "date_field_error_giftcards_invaliddate": "The format date is not valid",
            "date_field_error_giftcards_disablepast": "You cannot select a date earlier than today",
            "date_field_error_giftcards_maxdate": "You cannot choose a date longer than 1 month",
            "gift_cards_submit_infos": "You will receive the email within a few minutes",
            "gift_cards_invalid_form": "The form contains errors",
            "gift_cards_gift_in_cart_name": "E-gift card",
            "gift_cards_gift_in_cart_for": "For",
            "gift_cards_gift_in_cart_from": "From",
            "gift_cards_gift_in_cart_message": "Message",
            "gift_cards_gift_in_cart_date": "Sent on",
            "checkout_payment_get_cart": "Cart recovery in progress",
            "my_account_giftcard_balance_title": "Gift card balance",
            "my_account_giftcard_balance_subtitle": "Check balance, add gift card.",
            "gift_card_balance_availability": "Your gift card balance is valid for 1 year from the date of activation of the last gift card added to the account.",
            "error_validating_cart_error_on_validate": "An error has occurred during the checkout process, please contact customer service.",
            "credit_card_form_register": "Save for later",
            "legacy_gift_card_modal_title": "Would you like to transfer the balance of your gift cards?",
            "legacy_gift_card_modal_body": "Lunii has changed its gift card management system to a single balance (combining all your gift card amounts). You had old gift cards registered on our platform: do you wish to transfer them to this virtual balance? If you wish, you can use it to purchase audiobooks.",
            "legacy_gift_card_validate_button": "Transfer balance",
            "gift_card_merge_error_unable_to_merge": "An error occurred during the transfer, please contact customer service.",
            "my_account_fahs_title": "My Fabulous Storytellers",
            "my_fahs_bottom_note": "You can link up to 3 My Fabulous Storytellers with your account.",
            "my_fahs_fah_modal": "My Fabulous Storyteller",
            "char_count": "characters",
            "fields_fahname_label": "Name of My Fabulous Storyteller",
            "fields_fahname_placeholder": "My Fabulous Storyteller",
            "fields_fahserial_label": "Serial No.",
            "fields_fahserial_placeholder": "10 or 14 digit serial number",
            "fah_firmware": "Internal software",
            "my_fahs_fah_modal_cancel": "Cancel",
            "my_fahs_fah_modal_confirm": "Save edits",
            "my_fahs_no_fah": "To pair My Fabulous Storyteller to your Lunii account, dowload the <1>Luniistore</1> app on your computer, start it and plug-in your Storyteller.",
            "date_format": "MM/DD/YYYY",
            "gift_card_balance_expiration_date": "Balance expiry date: {{date}}",
            "error_fah_update_retrieve_fah": "Unable to find this Storyteller: refresh the page and try again.",
            "error_fah_update_name": "Unable to update the name of this Storyteller.",
            "error_fah_update_serial": "Unable to update the serial number of this Storyteller.",
            "constraints_fahserial": "The name is required and must be between 1 and 40 characters long.",
            "constraints_fahname": "The serial number must be between 10 and 14 characters long.",
            "incorrect_cvc": "The visual cryptogram is invalid.",
            "expired_card": "The payment card has expired.",
            "processing_error": "An error occurred during the payment process, please try again. If the error persists, please try again later.",
            "incorrect_number": "The card number is invalid.",
            "error_subscription_server": "An error occurred while processing your purchase. You have not been charged. Please try again later.",
            "error_subscription_not_enough_credits": "Your purchase could not be completed due to a lack of credit on your account.",
            "error_subscription_unlisted": "An error occurred while processing your request.",
            "seo_title_suffix_digital_album": "Audio stories | Lunii",
            "locale_en_nl_label": "Netherlands (English)",
            "country_nl_label": "Netherlands",
            "ecommerce_hardware_included_title": "Included",
            "audio_player_play": "Play",
            "audio_player_pause": "Pause",
            "error_validate_codealbum_unable_to_retrieve_code": "The code is invalid",
            "error_validate_codealbum_max_usage_count_reached": "This offer has expired",
            "error_validate_codealbum_unable_to_retrieve_offer": "Servor eror",
            "error_validate_codealbum_code_not_active": "The code is currently inactive.",
            "error_validate_codealbum_code_not_yet_active": "The code is not valid yet.",
            "error_validate_codealbum_code_expired": "The code has expired.",
            "error_validate_codealbum_no_available_products": "You have all the products available using this code.",
            "error_validate_codealbum_code_already_used": "You have already redeemed this offer.",
            "error_activate_codealbum_unable_to_retrieve_code": "The code is invalid.",
            "error_activate_codealbum_max_usage_count_reached": "This code has already been used.",
            "error_activate_codealbum_unable_to_retrieve_offer": "Unable to find the offer associated with this book code.",
            "error_activate_codealbum_code_not_active": "The code is currently inactive.",
            "error_activate_codealbum_code_not_yet_active": "The code is not yet valid.",
            "error_activate_codealbum_code_expired": "The code has exipired.",
            "error_activate_codealbum_code_already_used": "You have already redeemed this offer.",
            "error_activate_codealbum_unable_to_retrieve_user_delivery_infos": "You have not entered the delivery address in your account.",
            "error_activate_codealbum_unable_to_retrieve_user_billing_infos": "You have not entered the billing address in your account.",
            "error_activate_codealbum_unable_to_retrieve_user": "The code is invalid.",
            "my_code_album_modal_title_success": "This audiobook has been added to your library!",
            "my_code_album_modal_body_success": "Use the Android mobile app or the Luniistore desktop app to transfer it to your My Fabulous Storyteller.",
            "my_code_album_modal_go_to_library": "Go to the library",
            "my_code_album_modal_validated_back": "Back to my account",
            "my_code_album_modal_validate": "Use my code",
            "my_code_album_modal_title_oneinmany": "Select an audiobook from the following list",
            "my_code_album_modal_body_choice_oneinmany": "Once you've used your code, you won't be able to use it on another account or change your selection.",
            "my_code_album_body": "Have you got a code allowing you to unlock access to an audiobook? Enter it here and it will be added directly to your library.",
            "my_code_album_title": "Book codes",
            "my_code_album_modal_title_one": "You will get the following audiobook",
            "my_code_album_modal_body_choice_one": "Once you've used your code, you won't be able to use it on another account.",
            "my_code_album_subtitle": "Enter your code to unlock a free audiobook.",
            "my_code_album_placeholder": "Book code",
            "my_code_album_label": "Book code",
            "locale_en_nl_product_language": "Dutch",
            "merge_account_warning_modal_title": "There is no My Fabulous Storyteller linked to your account.",
            "merge_account_warning_modal_body_text": "Before proceeding with your purchase, make sure you have not already created a Lunii account. Audiobooks purchased on this account cannot be transferred to another account..",
            "merge_account_warning_modal_body_subtext": "You are connected to the account:",
            "merge_account_warning_modal_button_confirm": "Continue to payment",
            "merge_account_warning_modal_infos_text": "Want to give an audiobook as a gift? You can give a <1>Lunii e-gift card</1>!",
            "merge_account_warning_modal_logout_button": "Connect to another account",
            "wishlist_heading_title": "My wishlist",
            "wishlist_empty": "You have not added anything to your wishlist.",
            "wishlist_not_empty": "Find your saved audiobooks here.",
            "wishlist_heading_pretitle": "{{count}} Audiobook",
            "wishlist_heading_pretitle_plural": "{{count}} Audiobooks",
            "my_subscription_scp_link": "Update payment method.",
            "credit_card_default_name": "Bank card",
            "credit_card_used_in_subscription": "Subscription payment method",
            "credit_card_default_card": "Default payment method",
            "forms_addcreditcard_button_open_form_variant": "New payment method",
            "locale_nl_nl_product_language": "Dutch",
            "locale_nl_nl_label": "Netherlands",
            "mycreditcards_scp_link": "Manage my credit cards",
            "checkout_cart_global_message": null,
            "footer_newsletter_title": "Receive our news",
            "footer_newsletter_subtitle": "Special offers, new audiobooks, suggestions...",
            "checkout_cart_warning_hardware": null,
            "my_code_album_modal_body_account": "Connected as <1>{{email}}</1>.",
            "my_library_error_no_product": "<1>Oops, this product page is unavailable.</1> The product sheet for this audiobook is no longer accessible because this audiobook is no longer available for sale. Don't worry, you can always transfer it to your device.",
            "my_library_error_no_product_confirm": "I understand",
            "product_author_male": "Author",
            "product_author_female": "Author",
            "product_teller_male": "Narrator",
            "product_teller_female": "Narrator",
            "product_translator_male": "Translator",
            "product_translator_female": "Translator",
            "product_buy": "Buy",
            "library_subtitle": "To transfer your audio books, work out which app to use according to the model of your product. <devComponent/>",
            "library_subtitle_link": "Which app should I use?",
            "library_empty_studio_title": "Record your own stories to listen to on your Storyteller!",
            "library_empty_studio_description": "Start recording your own stories now in the Studio tab of the Lunii mobile app.",
            "fah_model_version_v1": "LUNII-1",
            "fah_model_version_v2": "LUNII-2",
            "fah_model_version_v3": "LUNII-3",
            "fah_modal_support_link": "Which cable should I use?",
            "library_empty_studio_cta": "Discover the app",
            "my_fahs_no_fahs": "To pair your Storyteller with your account, go to the Lunii desktop app or the Lunii mobile app.",
            "my_fahs_no_fahs_link": "Which app should I use?",
            "my_fahs_bottom_no_empty_bottom_link": "How do I pair My Fabulous Storyteller?",
            "fah_model": "Model",
            "fah_model_desc_v1": "To transfer your audio books to My Fabulous Storyteller:\n• Download the desktop app, log in and connect My Fabulous Storyteller with a suitable USB cable.\n• Or download the mobile app, log in and connect My Fabulous Storyteller to your smartphone with a suitable USB cable (Android only). <devComponent/>",
            "fah_model_desc_v2": "To transfer your audio books to My Fabulous Storyteller:\n• Download the desktop app, log in and connect My Fabulous Storyteller with a suitable USB cable.\n• Or download the mobile app, log in and connect My Fabulous Storyteller to your smartphone with a suitable USB cable (Android only). <devComponent/>",
            "fah_model_desc_v3": "Download the desktop app and log in to transfer your audio books to My Fabulous Storyteller over Wi-Fi.",
            "catalog_menu_spotlight": "Latest news",
            "catalog_menu_products": "Catalogue",
            "device_target_tag_fah": "For My Fabulous Storyteller",
            "device_target_tag_fah_flam": "For My Fabulous Storyteller and FLAM",
            "my_account_devices_title": "My audio devices",
            "my_account_devices_subtitle": "Manage my Story Factory and associated FLAMs, serial numbers, models...",
            "my_subscription_discover_infos_text": null,
            "my_subscription_discover_infos_subtitle": null,
            "my_devices_no_devices": "To pair a My Fabulous Storyteller or FLAM to your account, open the Lunii desktop app or the Lunii mobile app.",
            "my_account_subscription_infos": null,
            "my_devices_bottom_note": "You can link up to 3 Ma Fabrique à Histoires storytellers and 3 FLAM players to your account.",
            "my_devices_bottom_no_empty_bottom_link": "How do I pair an audio device?",
            "fields_devicename_label": "Device name",
            "fields_devicename_placeholder": "My audio device",
            "device_model_desc_v1": "To transfer your audio books to My Fabulous Storyteller:\n• Download the desktop app, log in and connect My Fabulous Storyteller with a suitable USB cable.\n• Or download the mobile app, log in and connect My Fabulous Storyteller to your smartphone with a suitable USB cable (Android only). <devComponent/>",
            "device_model_desc_v2": "To transfer your audio books to My Fabulous Storyteller:\n• Download the desktop app, log in and connect My Fabulous Storyteller with a suitable USB cable.\n• Or download the mobile app, log in and connect My Fabulous Storyteller to your smartphone with a suitable USB cable (Android only). <devComponent/>",
            "device_model_desc_v3": "Download the desktop app and log in to transfer your audio books to My Fabulous Storyteller over Wi-Fi.",
            "device_model_desc_da7": "Download the mobile app and log in to transfer your audio books to FLAM over Wi-FI.",
            "device_firmware": "Built-in software",
            "device_model": "Model",
            "device_model_version_v1": "LUNII-1",
            "device_model_version_v2": "LUNII-2",
            "device_model_version_v3": "LUNII-3",
            "device_model_version_da7": "LUN7P-1",
            "my_device_v1_modal_title": "My Fabulous Storyteller",
            "my_device_v2_modal_title": "My Fabulous Storyteller",
            "my_device_v3_modal_title": "My Fabulous Storyteller",
            "my_device_da7_modal_title": "FLAM",
            "my_devices_no_fah_link": "How do I pair my FLAM player?",
            "my_devices_no_flam_link": "How do I pair My Fabulous Storyteller?",
            "my_device_fah": "My Fabulous Storyteller",
            "my_device_flam": "FLAM",
            "catalog_fah": "Audio books for ages 3-7",
            "catalog_flam": "Audio books for ages 7-11",
            "my_empty_fah_audiobooks_title": "Your library's looking a bit empty...",
            "my_empty_fah_studio_title": "Record your own stories to listen to on your Storyteller!",
            "my_empty_flam_audiobooks_title": "Your library's looking a bit empty...",
            "my_empty_flam_studio_title": "Studio is not available for FLAM",
            "my_empty_fah_audiobooks_description": "This is where you'll find all the audio books purchased for My Fabulous Storyteller.",
            "my_empty_fah_studio_description": "Start recording your own stories now in the Studio tab of the Lunii mobile app.",
            "my_empty_flam_audiobooks_description": "This is where you'll find all the audio books purchased for FLAM.",
            "my_empty_fah_audiobooks_action": "Discover the catalogue for ages 3 to 7",
            "my_empty_fah_studio_action": "Discover the app",
            "device_target_tag_flam": "For FLAM",
            "my_empty_fah_wishlist_title": "Your wish list looks a bit empty... for the moment!",
            "my_empty_flam_wishlist_title": "Your wish list looks a bit empty... for the moment!",
            "my_empty_fah_wishlist_description": null,
            "my_empty_flam_wishlist_description": null,
            "my_empty_fah_wishlist_action": "Discover the catalogue for ages 3 to 7",
            "checkout_confirmation_next_steps_subtitle1": "Find your purchased audio books instantly in your Library",
            "checkout_confirmation_next_steps_text1": "To transfer your stories to your audio device, go to the Lunii desktop or mobile app.",
            "checkout_confirmation_next_steps_subtitle2": "Receive your Lunii audio devices and accessories within 2 to 5 working days.",
            "checkout_confirmation_next_steps_text2": "Keep an eye on your inbox to be informed when your parcel is dispatched.",
            "checkout_confirmation_next_steps_subtitle3": "Discover our catalogue of audio books for 3-7 year olds and 7-11 year olds.",
            "checkout_confirmation_next_steps_text3": "Find hundreds of stories to listen to on My Fabulous Storyteller and FLAM, to develop children's imagination, culture and vocabulary.",
            "merge_account_warning_modal_body_text_fah": "These audio books are for listening to on My Fabulous Storyteller.",
            "merge_account_warning_modal_flam_title": "You do not have a FLAM player associated with your account.",
            "merge_account_warning_modal_body_text_flam": "These audio books can only be listened to on the FLAM player.",
            "merge_account_warning_modal_fah_flam_title": "You do not have a My Fabulous Storyteller or a FLAM player associated with your account.",
            "merge_account_warning_modal_body_text_fah_flam": "These audio books can be listened to on My Fabulous Storyteller or on the FLAM player.",
            "my_empty_flam_wishlist_action": "Discover the catalogue for ages 7 to 11",
            "my_empty_flam_audiobooks_action": "Discover the catalogue for ages 7 to 11",
            "button_label_close": "Close",
            "product_stories_modal_link": "Discover the {{stories}} stories",
            "product_credits_modal_link": "Display credits",
            "product_credits_modal_title": "Credits",
            "error_validating_cart_discount_already_used": "The discount code has already been used on your account.",
            "common_error_message": "An error occurred. Please try again. If the error persists, please contact customer service.",
            "page_404_title": "Oops! This page does not exist...",
            "page_404_text": "The maze is so big, you appear to be lost.. Don't panic, our characters are already coming to your rescue!",
            "page_404_button": "Back to home page",
            "promotion_cap_text": "The maximum discount is {{amount}}",
            "global_error_message": "We are currently experiencing a technical difficulty. We apologise for any inconvenience. If the problem persists, please contact customer service (error: {{error}}, status: {{status}}).",
            "global_support_name": "customer service",
            "gift_card_balance_expired_balance": "Balance expired",
            "gift_card_balance_expired_warning": "Your gift card expired on: <1>{{date}}</1>. You can no longer use your balance. To credit your account again, add a new e-gift card.",
            "capped_discount": "Discount capped at {{amount}}",
            "discount_cumul_text": "The discount currently active on your basket will be replaced by the new discount you wish to apply.",
            "discount_cumul_cta": "Apply my new code",
            "discount_cumul_title": "You cannot combine several discount codes",
            "cart_amount_details": "See details",
            "cart_amount_hide_details": "Hide details",
            "discount_edit": "Edit the discount code",
            "global_discount": "Total discount",
            "error_cart_discounts_code_over_usage_count": "This code has already been used.",
            "amount_percent_cart": "{{amount}} on the basket",
            "amount_percent_product": "{{amount}} on \"{{productName}}\"",
            "stripe_card_error_http_default": "Payment information is currently unavailable, please try again later.",
            "gift_card_balance_error_http_default": "Gift card balance information is currently unavailable, please try again later.",
            "checkout_cart_header_total_without_reducton": "Amount excluding discounts: <1>{{amount}}</1>",
            "checkout_cart_total_reduction": "Total discount: <1>{{amount}}</1>",
            "checkout_cart_header_shipping_amount": "Shipment: {{description}} (excluding preorders) - <1>{{amount}}</1>",
            "checkout_cart_header_total_account_balance": "Gift card balance: <1>{{amount}}</1>",
            "checkout_cart_header_total_amount": "Total amount: {{amount}}",
            "error_cart_discounts_products_not_on_cart": "Your basket does not contain the product(s) covered by this discount code.",
            "error_cart_discounts_mixed_cart": "We cannot apply this promotion if your basket contains both audiobooks and Lunii products.",
            "checkout_cart_removed_items_shipping_not_allowed": "Oops! Shipping isn't available in your region for this product. The items concerned have been automatically removed from your basket:",
            "checkout_cart_removed_items_out_of_stock": "Oops! Some products are currently out of stock. The items concerned have been automatically removed from your basket:",
            "checkout_cart_removed_items_pack_already_in_user_library": "Some audio books are already in your library. The items concerned have been automatically removed from your basket:",
            "checkout_cart_removed_items_invalid_promotion": "Oops, this promotion isn't valid. Please try again later or contact our customer service for help.",
            "checkout_cart_removed_items_invalid_promotion_code": "Oops, this promotion isn't valid. Please try again later or contact our customer service for help.",
            "checkout_cart_removed_items_invalid_product": "Oops, some products can't be found! If the problem persists, please contact our customer service.",
            "checkout_cart_removed_items_exclusive_locale_mismatch": "Some audio books aren't available in your country. The items concerned have been automatically removed from your basket:",
            "checkout_cart_removed_items_promotion_locale_mismatch": "Oops, this promotion isn't valid in your country. Check that you are on the right site to benefit from it.",
            "checkout_cart_removed_items_mixed_array": "Some items in your basket aren't eligible for the current promotion. This promotion has been automatically removed and no longer applies to your basket.",
            "fields_fahnserial_label": "Serial No.",
            "error_validating_cart_amount_too_low": "Oops, the order cannot be finalized at the moment. The amount is below the minimum required, which is 50 cents, please modify your order to finalize it.",
            "device_target_product_taxonomy_tag_fah": "Storyteller only",
            "device_target_product_taxonomy_tag_flam": "FLAM only",
            "device_target_product_taxonomy_tag_fah_flam": "Storyteller and FLAM",
            "device_target_product_taxonomy_header": "To listen on"
        }
    },
    "fr-CA": {
        "translation": {
            "navbar_login_label": "Connexion",
            "module_video_immersive_button_label": "Regarder le film",
            "navbar_search_placeholder": "Rechercher dans le catalogue",
            "cart_empty_text": "Votre panier est vide.",
            "cart_footer_items_gocart_label": "Aller à mon panier",
            "cart_footer_items_mylist_label": "Ma liste",
            "cart_footer_items_myorders_label": "Mes commandes",
            "fields_city_label": "Ville",
            "fields_country_label": "Pays",
            "fields_email_label": "Email",
            "fields_firstname_label": "Prénom",
            "fields_lastname_label": "Nom",
            "fields_message_label": "Message",
            "fields_password_label": "Mot de passe",
            "fields_phone_label": "Téléphone",
            "fields_recipient_label": "Destinataire",
            "fields_subject_label": "Sujet",
            "fields_zipcode_label": "Code postal",
            "fields_address1_label": "Adresse",
            "fields_address2_label": "Complément d'adresse",
            "fields_city_placeholder": "Ex: Montréal",
            "fields_country_placeholder": "Ex: Canada",
            "fields_email_placeholder": "Ex: moi@monemail.com",
            "fields_firstname_placeholder": "Ex: Marie",
            "fields_lastname_placeholder": "Ex: Dupont",
            "fields_message_placeholder": "Votre message ici",
            "fields_password_placeholder": "Votre mot de passe personnel",
            "fields_phone_placeholder": "Ex: 0102030405",
            "fields_recipient_placeholder": "Destinataire",
            "fields_subject_placeholder": "Votre sujet ici",
            "fields_zipcode_placeholder": "Ex: 75010",
            "locale_fr_fr_label": "France (Français)",
            "locale_it_it_label": "Italie (Italien)",
            "label_quantity_shortened": "Qté",
            "locale_fr_fr_product_language": "Français",
            "locale_de_de_product_language": "Allemand",
            "cart_go_checkout": "Aller au panier ({{nbItems}})",
            "cart_other_item": "Et 1 autre article...",
            "cart_other_item_plural": "Et {{count}} autres articles...",
            "constraints_password": "Le mot de passe doit avoir au moins 8 caractères",
            "constraints_email": "L'email doit être au format mon@email.com",
            "catalog_no_result_title": "Aucun résultat",
            "catalog_no_result_content": "Malheureusement, aucun résultat ne correspond à votre recherche.",
            "catalog_results_for": "résultat(s) pour",
            "catalog_menu_title": "Filtrer",
            "product_owned": "Déjà acheté",
            "panels_login_title": "Se connecter",
            "panels_login_description": "Nous sommes heureux de vous revoir !",
            "panels_forgotpassword_title": "Mot de passe oublié ?",
            "panels_forgotpassword_description": "Entrez votre mail pour réinitialiser votre mot de passe.",
            "forms_login_buttons_forgotpassword_label": "Mot de passe oublié ?",
            "forms_login_buttons_submit_label": "Me connecter",
            "forms_login_buttons_cancel_label": "Continuer en tant qu'invité",
            "forms_login_buttons_register_label": "M'inscrire",
            "forms_login_buttons_register_text": "Pas de compte ?",
            "buttons_register_label": "M'inscrire",
            "forms_register_buttons_submit_label": "M'inscrire",
            "forms_register_buttons_alreadyregistered_text": "Déjà inscrit ?",
            "forms_register_buttons_alreadyregistered_label": "Me connecter",
            "panels_register_title": "M’inscrire",
            "panels_register_description": "Bienvenue dans la communauté Lunii !",
            "catalog_reinitialize": "Réinitialiser",
            "catalog_finish": "Terminer",
            "forms_forgotpassword_buttons_submit_label": "Réinitialiser",
            "forms_forgotpassword_buttons_cancel_label": "Revenir à la connexion",
            "catalog_title": "Luniistore",
            "login_panel_title": "Se connecter",
            "login_panel_description": "Nous sommes heureux de vous revoir !",
            "checkout_cart_gift_balance": "Votre solde carte cadeaux",
            "checkout_cart_gift_will_spend": "Vous allez dépenser",
            "checkout_cart_gift_will_stay": "Il vous restera",
            "checkout_cart_header_nb_items": "ARTICLE(S)",
            "checkout_cart_header_no_discount": "Réductions : 0,00 $",
            "checkout_cart_header_with_discount": "Réduction : Vous économisez",
            "checkout_cart_header_ex_vat": "Montant hors taxe :",
            "checkout_cart_header_vat": "TVA :",
            "checkout_cart_header_title": "Votre commande",
            "checkout_cart_title": "Panier",
            "checkout_payment_title": "Livraison & Paiement",
            "checkout_login_title": "Connexion",
            "checkout_login_form_title": "J'ai déjà un compte Lunii",
            "checkout_shipping_and_purchase_title": "Livraison et paiement",
            "checkout_shipping_and_purchase_pay": "Payer ({{price}})",
            "checkout_cart_total_sub_total": "Sous-total",
            "checkout_cart_total_shipping_fee": "Livraison sous {{description}} (hors précommande)",
            "checkout_cart_total_total_price": "Montant total",
            "checkout_cart_total_discount": "Réduction",
            "checkout_shipping_and_purchase_withdrawal": "J’accepte que mon droit de rétractation légal prenne fin dans 14 jours ou dès que je commence le téléchargement du premier titre de cette commande sur Ma Fabrique à Histoires, selon ce qui se produit en premier.",
            "checkout_shipping_and_purchase_cgv": "J’ai lu et j’accepte <1> les conditions générales de vente. </1>",
            "extract_card_info": "Extrait n°",
            "product_add_to_cart": "Ajouter au panier",
            "locale_nl_be_product_language": "Flamand",
            "locale_en_us_product_language": "US (Anglais)",
            "locale_en_gb_product_language": "UK (Anglais)",
            "locale_it_it_product_language": "Italien",
            "locale_es_es_product_language": "Espagnol",
            "fields_promocode_label": "Votre code de réduction",
            "button_label_next": "Suivant",
            "fields_promocode_placeholder": "Votre code de réduction",
            "product_language_title": "Langue",
            "product_duration_title": "Durée",
            "product_age_title": "Âge",
            "product_type_title": "Types de livres audio",
            "product_author": "Auteur",
            "product_teller": "Conteur",
            "locale_ru_ru_product_language": "Russe",
            "product_recommendations_title": "Découvrez aussi...",
            "product_recommendations_subtitle": "Si ce livre audio vous plaît, nous avons sélectionné des histoires similaires qui pourraient vous intéresser.",
            "product_reduction_content": "Économisez",
            "newsletter_button_label_register": "Je m'inscris",
            "promo_code_form_add_promo_code": "Ajouter un code de réduction",
            "fields_yourfirstname_label": "Votre prénom",
            "fields_newsletteroptin_label": "Votre adresse email",
            "credit_card_form_reassurance_title": "Nous utilisons le service de paiement sécurisé Stripe",
            "credit_card_form_reassurance_body": "Lorsque vous communiquez vos informations de paiement, vos données sont protégées par la technologie Secure Socket Layer (SSL), authentifiée par certificat numérique. <br />\n<1>En savoir plus</1>",
            "form_address_use_delivery_address": "Utiliser l’adresse de livraison",
            "newsletter_subtitle": "Je m'inscris à la newsletter pour être au courant des prochaines promotions, des nouveaux livres audio et des évènements de Lunii.",
            "newsletter_privacy_policy_text": "Nous nous engageons à ne jamais communiquer votre email à des tiers.",
            "newsletter_privacy_policy_link_label": "Consultez notre politique de confidentialité",
            "product_price_free": "Gratuit",
            "locale_fr_be_label": "Belgique (Français)",
            "locale_fr_ch_label": "Suisse (Français)",
            "locale_fr_us_label": "USA (Français)",
            "locale_es_us_label": "USA (Espagnol)",
            "locale_fr_mc_label": "Monaco (Français)",
            "locale_fr_lu_label": "Luxembourg (Français)",
            "locale_es_es_label": "Espagne (Espagnol)",
            "locale_de_de_label": "Allemagne (Allemand)",
            "locale_en_gb_label": "UK (Anglais)",
            "locale_en_us_label": "USA (Anglais)",
            "fields_giftcard_label": "Carte cadeau",
            "checkout_shippingAddress_form_title": "Votre adresse de livraison",
            "checkout_paymentMethod_form_title": "Mode de paiement",
            "checkout_billingAddress_form_title": "Votre adresse de facturation",
            "checkout_creditCard_form_title": "Carte bancaire",
            "form_address_edit": "Modifier",
            "form_address_update_address": "Confirmer la sélection",
            "form_address_cancel_edit": "Annuler",
            "productbar_buy_button_label": "Acheter",
            "productbar_textline2": "Livraison en 5 à 7 jours",
            "country_fr_label": "France",
            "forms_forgotpassword_verify_inbox_text": "Si un compte Lunii existe déjà à cette adresse mail, vous allez y recevoir un mail avec des instructions pour réinitialiser votre mot de passe.\n\nPensez à vérifier le courrier indésirable de votre boîte mail !",
            "checkout_confirmation_next_steps": "Voici quelques conseils",
            "checkout_confirmation_shipping_title": "Livraison!",
            "checkout_confirmation_stories_title": "Découvrez notre catalogue d’histoires audio",
            "checkout_confirmation_stories_text": "Retrouvez des centaines d’histoires pour développer l’imaginaire, la culture et le vocabulaire des enfants à écouter dans Ma Fabrique à Histoires.",
            "checkout_confirmation_download_title": "Téléchargez le Luniistore pour transférer vos livres audio dans Ma Fabrique à Histoires",
            "checkout_confirmation_download_text": "Le Luniistore est une application ordinateur qui permet d’ajouter des livres audio dans Ma Fabrique à Histoires ou de les en retirer.",
            "checkout_confirmation_download_btn": "Télécharger le Luniistore",
            "checkout_confirmation_sync_title": "Retrouvez vos livres audio dans votre Bibliothèque",
            "checkout_confirmation_sync_text": "Les livres audio que vous venez d’acheter sont désormais accessibles dans votre Bibliothèque sur le Luniistore.",
            "checkout_confirmation_title": "Merci pour votre achat !",
            "checkout_confirmation_description": "Nous vous souhaitons de beaux moments d’écoute !",
            "checkout_cart_return": "Retour à l'accueil",
            "cart_payment_loading": "Paiement en cours",
            "checkout_confirmation_shipping_text": "Votre colis arivera chez vous dans 5 à 7 jours.",
            "forms_register_newsletter_label": "Je m’inscris à la newsletter",
            "forms_register_cgu_label": "J’ai pris connaissance et accepte les",
            "my_account_title": "Mon compte",
            "my_account_user_information_title": "Mes informations",
            "my_account_checkout_and_purchase_title": "Paiement et factures",
            "my_account_user_information_subtitle": "Prénom, nom, e-mail, mot de passe.",
            "my_account_checkout_and_purchase_subtitle": "Moyens de paiement, adresse de facturation, historique de vos achats, adresse de livraison.",
            "my_account_connected_as": "Connecté en tant que",
            "my_account_logout": "Se déconnecter",
            "my_invoice_address_title": "Facturation",
            "my_delivery_address_title": "Adresse de livraison",
            "my_purchase_history_title": "Historique d’achats",
            "form_user_information_address_title": "Adresse e-mail",
            "form_user_information_address_note": "Contactez le support clientèle pour modifier l’adresse e-mail de votre compte Lunii.",
            "form_user_information_password_edit": "Modifier votre mot de passe",
            "checkout_shippingMethods_form_title": "Mode d'expédition",
            "forms_register_locale_selection_text": "Sélectionnez vos options de langue et de pays",
            "errors_login_bad_credentials": "Merci de vérifier votre email et votre mot de passe, vos identifiants sont incorrects.",
            "errors_unauthorized": "Vous n'êtes pas autorisé à vous connecter actuellement avec ce compte, merci de réessayer et de contacter le service client si le problème persiste.",
            "errors_server_error": "Nous rencontrons actuellement un problème technique et vous demandons de bien vouloir réessayer ultérieurement. Si le problème persiste, merci de contacter le service client.",
            "form_user_information_update": "Enregistrer",
            "form_user_information_cancel_edit": "Annuler",
            "form_user_information_edit": "Modifier",
            "catalog_menu_types": "Types de livres audio",
            "catalog_menu_themes": "Thèmes",
            "catalog_menu_ages": "Âge",
            "catalog_menu_langs": "Langues étrangères",
            "fields_cardowner_label": "Titulaire de la carte",
            "fields_cardowner_placeholder": "Titulaire de la carte",
            "cartmodal_success_message": "Hop, c’est ajouté !",
            "cartmodal_go_cart_button_label": "Aller au panier",
            "cartmodal_buy_button_label": "Ajouter au panier",
            "cartmodal_continue_button_label": "Poursuivre ma navigation",
            "cartmodal_cancel_button_label": "Annuler",
            "cartmodal_linkedProducts_title": "Découvrez aussi...",
            "cartmodal_title": "Ajouter ce produit",
            "product_pages_number_title": "Pages",
            "product_format_title": "Format",
            "product_illustrator": "Illustrateur",
            "checkout_confirmation_catalog_btn": "Découvrir le catalogue",
            "checkout_confirmation_free_album_title": "Récupérez le livre audio Douce nuit offert",
            "checkout_confirmation_free_album_text": "Nous vous offrons 6 histoires douces et poétiques avec des exercices de relaxation à télécharger sur le Luniistore.",
            "cart_empty_subtitle": "Quelle tristesse !",
            "panels_forgotpassword_success_title": "Vérifiez votre boîte mail.",
            "forms_register_country_selection_text": "Sélectionnez vos options de langue et de pays",
            "forms_register_data_label": "Je consens au traitement de mes données personnelles",
            "forms_register_verify_inbox_text": "Nous vous avons envoyé un mail de validation à l’adresse que vous avez renseigné. Vous devez d’abord valider la création de votre compte avant de poursuivre.",
            "forms_register_alert_inbox_text": "Si vous n’avez rien reçu, sachez que le mail peut mettre quelques minutes à arriver. Pensez à vérifier votre dossier spam.\nSi vous n’avez rien reçu après plusieurs minutes, essayez de vous inscrire en faisant attention à l’orthographe de votre mail.",
            "panels_register_success_title": "Vérifiez votre boîte mail.",
            "form_register_cgu_link": "conditions de service",
            "errors_register_error_email": "Une erreur est survenue pendant la création du compte. Si vous avez déjà créé un compte avec cette adresse, pensez à l’activer en cliquant sur le lien dans l’email d'activation.",
            "errors_forgot_password_error_email": "Une erreur est survenue pendant la réinitialisation du mot de passe. Si vous avez déjà créé un compte avec cette adresse, pensez à l’activer en cliquant sur le lien dans l’email d'activation.",
            "form_address_search_address": "Valider mon adresse",
            "form_address_search_address_select": "Séléctionnez votre adresse dans la liste ci-dessous",
            "form_address_search_address_not_found": "Mon adresse ne figure pas dans la liste",
            "form_address_search_address_force": "Je certifie que je peux recevoir un colis à cette adresse",
            "country_de_label": "Allemagne",
            "country_be_label": "Belgique",
            "country_lu_label": "Luxembourg",
            "country_mc_label": "Monaco",
            "country_ch_label": "Suisse",
            "country_gb_label": "Royaume-Uni",
            "country_it_label": "Italie",
            "country_ca_label": "Canada",
            "country_us_label": "États-Unis",
            "locale_es_mx_product_language": "Espagnol (LATAM)",
            "locale_en_ca_label": "Canada (Anglais)",
            "locale_fr_ca_label": "Canada (Français)",
            "form_address_search_address_force_billing": "Je certifie que mon adresse est exacte",
            "form_address_search_address_extra_button": "Utiliser l'adresse de livraison",
            "button_label_validate": "Valider",
            "error_cart_discounts_common_error": "Code invalide",
            "button_label_cancel": "Annuler",
            "checkout_error_no_shipping_methods_available": "Aucune méthode de livraison n'a été trouvée. Veuillez contacter le service client.",
            "newsletter_subscribe_success": "Votre inscription à la newsletter a bien été prise en compte.",
            "newsletter_unsubscribe_success": "Vous êtes maintenant désinscrit de la newsletter.",
            "checkout_cart_excl_taxes": "hors taxes",
            "checkout_cart_total_discount_old_price": "Montant hors réduction",
            "error_cart_discounts_code_already_used": "Vous utilisez déjà un code",
            "checkout_cart_error_cart_infos_have_changed": "Les informations de votre panier ont changé, veuillez vérifier les nouvelles informations avant de retenter votre paiement.",
            "my_purchase_history_no_purchase": "Vous n'avez pas encore effectué d'achat.",
            "error_creating_payment_intent": "Une erreur est apparue avec votre carte. Le paiement n'a pas abouti.",
            "error_updating_cart_already_validated": "Votre panier est déjà validé.",
            "payment_intent_authentication_failure": "Votre méthode de paiement n'a pu être authentifiée. Le paiement n'a pas abouti.",
            "payment_validation_payment_method": "Vous devez remplir les informations de paiement.",
            "payment_validation_terms": "Vous devez accepter les conditions générales de vente et les conditions de rétractation.",
            "product_discover": "Découvrir",
            "product_out_of_stock": "Cet article n'est plus disponible",
            "register_special_info": null,
            "forms_register_data_infos": "Les données recueillies sont destinées aux services de Lunii et à ses prestataires pour le traitement de votre commande et pour nos relations commerciales. Conformément à la loi \"Informatique et Libertés n°78-17 du 6 Janvier 1978\" relative à l'informatique, aux fichiers et aux libertés, et au règlement européen 2016/679 du 27 avril 2016 relatif au données personnelles, vous disposez d'un droit d'accès, de modifications, de rectification, et de suppression des données vous concernant. Lunii s'engage à la confidentialité, et à la protection de vos données.",
            "checkout_paymentMethod_stripe": "Carte bancaire",
            "checkout_paymentMethod_paypal": "PayPal",
            "cartmodal_product_infos_fah_v1": null,
            "field_state_label": "État",
            "field_state_placeholder": "État",
            "error_validate_codealbum_unable_to_retrieve_code": "Le code est invalide",
            "error_validate_codealbum_max_usage_count_reached": "Cette offre est épuisée",
            "error_validate_codealbum_unable_to_retrieve_offer": "Erreur serveur",
            "error_validate_codealbum_code_not_active": "Le code actuellement inactif.",
            "error_validate_codealbum_code_not_yet_active": "Le code n'est pas encore valide.",
            "error_validate_codealbum_code_expired": "Le code est expiré.",
            "error_validate_codealbum_no_available_products": "Vous possédez tous les produits que peut offrir ce code.",
            "error_validate_codealbum_code_already_used": "Vous avez déjà utilisé cette offre.",
            "error_activate_codealbum_unable_to_retrieve_code": "Le code est invalide.",
            "error_activate_codealbum_max_usage_count_reached": "Ce code a déjà été utilisé.",
            "error_activate_codealbum_unable_to_retrieve_offer": "Impossible de retrouver l'offre associée à ce code livre.",
            "error_activate_codealbum_code_not_active": "Le code actuellement inactif.",
            "error_activate_codealbum_code_not_yet_active": "Le code n'est pas encore valide.",
            "error_activate_codealbum_code_expired": "Le code est expiré.",
            "error_activate_codealbum_code_already_used": "Vous avez déjà utilisé cette offre.",
            "error_activate_codealbum_unable_to_retrieve_user_delivery_infos": "Vous n'avez pas saisi d'adresse de livraison dans votre compte.",
            "error_activate_codealbum_unable_to_retrieve_user_billing_infos": "Vous n'avez pas saisi d'adresse de facturation dans votre compte.",
            "error_activate_codealbum_unable_to_retrieve_user": "Le code est invalide.",
            "my_code_album_modal_title_success": "Ce livre audio a bien été ajouté à votre bibliothèque !",
            "my_code_album_modal_body_success": "Rendez-vous sur l'Application mobile pour Android ou sur le Luniistore pour ordinateur pour le transférer sur votre Fabrique à Histoires.",
            "my_code_album_modal_go_to_library": "Voir le Luniistore",
            "my_code_album_modal_validated_back": "Retour au compte",
            "my_code_album_modal_validate": "Utiliser mon code",
            "my_code_album_modal_title_oneinmany": "Choisissez un livre audio parmi la liste suivante",
            "my_code_album_modal_body_choice_oneinmany": "Une fois votre code utilisé, vous ne pourrez plus l'utiliser sur un autre compte ou modifier votre choix.",
            "my_code_album_body": "Vous avez obtenu un code vous permettant de déverrouiller l'accès à un livre audio ? Renseignez-le ici pour qu'il soit ajouté directement à votre bibliothèque.",
            "my_code_album_title": "Code livre",
            "my_code_album_modal_title_one": "Vous allez obtenir le livre audio suivant",
            "my_code_album_modal_body_choice_one": "Une fois votre code utilisé, vous ne pourrez plus l'utiliser sur un autre compte.",
            "my_code_album_subtitle": "Entrez votre code pour déverrouiller gratuitement un livre audio.",
            "my_code_album_placeholder": "Code livre",
            "my_code_album_label": "Code livre",
            "locale_en_nl_product_language": "Néerlandais",
            "wishlist_heading_title": "Ma liste de souhaits",
            "wishlist_empty": "Vous n'avez rien ajouté à votre liste de souhaits.",
            "wishlist_not_empty": "Retrouvez ici vos livres audio mis de côté.",
            "wishlist_heading_pretitle": "{{count}} Livre audio",
            "wishlist_heading_pretitle_plural": "{{count}} Livres audio",
            "subscription_title_select_step": "Sélectionnez votre abonnement Lunii",
            "subscription_product_monthly_subscription_title": "Mensuel",
            "subscription_product_monthly_subscription_pretitle": "6,90 € / mois",
            "subscription_product_monthly_subscription_body": "• 1 livre audio au choix chaque mois\n• 82,80 € / an (6,90 € tous les mois)\n• Sans engagement, résiliez à tout moment\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_annual_subscription_title": "Annuel (paiement en une fois)",
            "subscription_product_annual_subscription_pretitle": "69 € / an",
            "subscription_product_annual_subscription_body": "• 1 livre audio au choix chaque mois\n• 69 € / an (soit 5,75 € / mois)\n• Engagement d’un an renouvelable\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_monthly_subscription_pretitle_free_month": "Mensuel",
            "subscription_product_monthly_subscription_title_free_month": "1 mois offert",
            "subscription_product_monthly_subscription_subtitle_free_month": "puis 6,90 € / mois",
            "subscription_product_monthly_subscription_body_free_month": "• 1 livre audio au choix chaque mois\n• 82,80 € / an (6,90 € tous les mois)\n• Sans engagement, résiliez à tout moment\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_product_annual_subscription_pretitle_free_month": "Annuel",
            "subscription_product_annual_subscription_title_free_month": "69 € / an",
            "subscription_product_annual_subscription_subtitle_free_month": "soit 5,75 € / mois",
            "subscription_product_annual_subscription_body_free_month": "• 1 livre audio au choix chaque mois\n• 69 € / an (soit 5,75 € / mois)\n• Engagement d’un an renouvelable\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_title_billing_step": "Renseignez votre mode de paiement",
            "subscription_recap_title_monthly_subscription_free_month": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription_free_month": "1 mois offert puis 6,90 € / mois",
            "subscription_recap_startdate_caption_monthly_subscription_free_month": "Début du mois offert :",
            "subscription_recap_enddate_caption_monthly_subscription_free_month": "Début de la facturation :",
            "subscription_recap_action": "Modifier mon abonnement",
            "subscription_recap_title_monthly_subscription": "Votre abonnement mensuel",
            "subscription_recap_subtitle_monthly_subscription": "6,90 € / mois",
            "subscription_recap_startdate_caption_monthly_subscription": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_monthly_subscription": "Début de la facturation :",
            "subscription_recap_title_annual_subscription": "Votre abonnement annuel",
            "subscription_recap_subtitle_annual_subscription": "69 € / an",
            "subscription_recap_startdate_caption_annual_subscription": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_annual_subscription": "Début de la facturation :",
            "today": "aujourd'hui",
            "subscription_confirm_title_free_month": "Vous êtes maintenant abonné et pouvez profiter de votre premier mois offert !",
            "subscription_confirm_title": "Vous êtes maintenant abonné !",
            "subscription_confirm_subtitle_free_month": "Merci d’avoir souscrit à l’abonnement Lunii. Vous recevrez votre prochain crédit le {{date}}.",
            "subscription_confirm_subtitle": "Merci d’avoir souscrit à l’abonnement Lunii. Vous recevrez votre prochain crédit le {{date}}.",
            "subscription_confirm_body_p3": "<0>Gérez votre abonnement depuis </0><1>votre compte</1><0>.</0>\nAnnuler mon abonnement, modifier votre mode de paiement ou simplement suivre l’état de votre abonnement.",
            "subscription_already_owned_modal_title": "Il semble qu’un abonnement soit déjà actif sur votre compte",
            "subscription_already_owned_modal_body": "Pour gérer votre abonnement, rendez-vous sur votre compte Lunii.",
            "my_subscription_status_trialing": "Mois Offert",
            "my_subscription_free_month_warning": "Vous profitez actuellement de votre mois offert, qui prendra fin le {{date}}.",
            "my_subscription_sub_type_monthly_subscription": "Abonnement mensuel",
            "my_subscription_sub_type_annual_subscription": "Abonnement annuel",
            "my_subscription_sub_type_yearly_subscription_price": "69 € / an",
            "my_subscription_sub_type_monthly_subscription_price": "6,90 € / mois",
            "subscription_warning_message_free_month_already_used": "Vous avez déjà bénéficié de votre mois offert. Choisissez une offre d'abonnement pour profiter chaque mois d’un nouvel album audio.",
            "subscription_select": "Suivant",
            "my_subscription_sub_trial": "Début de la facturation",
            "credit_card_used_in_subscription": "Utilisée pour l'abonnement",
            "credit_card_default_card": "Utilisée par défaut",
            "forms_addcreditcard_button_open_form_variant": "Nouveau mode de paiement",
            "locale_nl_nl_product_language": "Néerlandais",
            "locale_nl_nl_label": "Pays-Bas",
            "subscriptiongift_select_explain": "<1>Comment souhaitez-vous offrir votre cadeau ?</1><2>Pour activer son abonnement cadeau, le destinataire recevra un code d’une durée de validité d’un an à utiliser sur notre site.</2>",
            "subscriptiongift_select_choice_choice_by_hand": "Offrir mon cadeau en main propre",
            "subscriptiongift_select_choice_choice_by_email": "Envoyer mon cadeau par e-mail",
            "subscriptiongift_select_choice_view_by_hand": "<ol><li>Recevez par e-mail le code d’activation et votre activité manuelle à imprimer.</li><li>À vous de jouer ! Fabriquez votre étoile en 3D en suivant les instructions(atelier de 20 min).</li><li>Offrez l’abonnement Lunii en main propre!</li></ol>",
            "subscriptiongift_select_choice_view_by_email": "<ol><li>À vous de jouer ! Personnalisez votre message surprise !</li><li>Nous envoyons votre cadeau directement par e-mail à son destinataire !</li><li>Par sécurité, recevez également le code d’activation sur votre e-mail Lunii.</li></ol>",
            "fields_subscriptiongift_reciever_name_label": "Prénom du destinataire",
            "fields_subscriptiongift_reciever_name_placeHolder": "Lilou",
            "fields_subscriptiongift_reciever_email_label": "Adresse e-mail du destinataire",
            "fields_subscriptiongift_reciever_email_placeHolder": "papa.de.lilou@mail.fr",
            "fields_subscriptiongift_message_label": "Message de 200 caractères maximum",
            "fields_subscriptiongift_message_placeHolder": "Votre message ici",
            "fields_subscriptiongift_sender_name_label": "Votre nom",
            "fields_subscriptiongift_sender_name_placeHolder": "Mamoune",
            "subscriptiongift_form_fieldset_title_reciever": "Destinataire",
            "subscriptiongift_form_fieldset_title_message": "Ajoutez un petit mot <1>(facultatif)</1>",
            "subscriptiongift_form_fieldset_title_sender": "De la part de",
            "subscriptiongift_form_fieldset_title_sendingdate": "Programmez une date d'envoi <br/><1>Le code d'activation sera envoyé à son destinataire à la date choisie ci-dessous.</1>",
            "subscriptiongift_navigate_next": "Étape suivante",
            "subscriptiongift_navigate_previous": "Précédent",
            "subscriptiongift_navigate_pay": "Payer",
            "go_back": "Retour",
            "subscripitongift_recap_by_email": "Envoi du cadeau au destinataire par e-mail",
            "subscripitongift_recap_by_hand": "Remise du cadeau en main propre",
            "subscriptiongift_metadatalist_senderName": "De la part de",
            "subscriptiongift_metadatalist_receiverEmail": "Adresse mail",
            "subscriptiongift_metadatalist_receiverName": "Destinataire",
            "subscriptiongift_metadatalist_message": "Votre petit mot",
            "subscriptiongift_metadatalist_sendingDate": "Date d'envoi",
            "singleflapaccordion_less": "Voir moins",
            "singleflapaccordion_more": "Voir plus",
            "subscriptiongift_checkbox_terms": "J’ai lu et accepté <1>les conditions générales de vente</1>.",
            "subscriptiongift_title_step_1": "Offrir un abonnement",
            "subscriptiongift_title_step_2": "Renseignez votre mode de paiement",
            "subscriptiongift_title_step_3": "Merci pour votre achat ! </br> <1>Nous Souhaitons à vos proches de beaux moments d'écoute !</1>",
            "subscriptiongift_confirmation_content_by_hand": "<title>Vous y êtes presque</title><list><el>Recevez par e-mail le code d’activation et votre activité manuelle à imprimer</br><subel>Vérifiez votre boîte mail ! Vous allez recevoir un e-mail contenant le code d’activation et le patron de l’étoile à confectionner en elD !</subel></el><el>À vous de jouer ! Fabriquez votre étoile en elD en suivant les instructions</br><subel>Lancez-vous dans un atelier créatif d’une vingtaine de minutes pour créer le plus beau des cadeaux !</subel></el><el>Offrez l’abonnement Lunii !</br><subel>Inscrivez le code au dos de votre étoile et offrez votre cadeau en main propre. Ce code devra être activé depuis l’espace compte Lunii.</subel></el></list>",
            "subscriptiongift_confirmation_content_by_email": "<title>Et maintenant ?</title><list><el>Nous envoyons votre cadeau directement par e-mail</br><subel>Le destinataire de votre cadeau recevra son code d’activation d’abonnement par e-mail, à la date que vous avez ultérieurement choisie. Ce code doit être activé depuis son compte Lunii.</subel></el><el>Par sécurité, vous recevrez également le code sur votre e-mail Lunii</br><subel>En cas de perte du code, pas de panique ! Le code d’activation vous est également envoyé sur votre e-mail Lunii.</subel></el></list>",
            "step": "Étape",
            "checkout_cart_warning_hardware": null,
            "checkout_cart_global_message": null,
            "my_subscription_sub_type_monthly_pricing": "{{price}} / mois",
            "my_subscription_sub_type_annual_pricing": "{{price}} / an",
            "subscription_product_annual_subscription_2023_pretitle_free_month": "Annuel",
            "subscription_product_annual_subscription_2023_title_free_month": "{{price}} / an",
            "subscription_product_annual_subscription_2023_subtitle_free_month": "soit {{monthlyPrice}} / mois",
            "subscription_product_annual_subscription_2023_pretitle": "{{price}} / an",
            "subscription_product_annual_subscription_2023_title": "Annuel (paiement en une fois)",
            "subscription_product_annual_subscription_2023_body": "• 1 livre audio au choix chaque mois\\n• {{price}} / an (soit {{monthlyPrice}} / mois)\\n• Engagement d’un an renouvelable\\n• Transférez vos histoires sur 3 Fabriques à Histoires",
            "subscription_recap_title_annual_subscription_2023": "Votre abonnement annuel",
            "subscription_recap_subtitle_annual_subscription_2023": "{{price}} / an",
            "subscription_recap_startdate_caption_annual_subscription_2023": "Début de l'abonnement :",
            "subscription_recap_enddate_caption_annual_subscription_2023": "Début de la facturation :",
            "my_code_album_modal_body_account": "Connecté en tant que <1>{{email}}</1>.",
            "my_library_error_no_product": "<1>Oups, cette fiche produit est indisponible</1> La fiche produit de ce livre audio n’est plus accessible car ce livre audio n’est plus disponible à la vente.",
            "my_library_error_no_product_confirm": "J'ai compris"
        }
    }
}
